import React from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

//import images
import wechat from "../../assets/images/companies/wechat.svg"
import sass from "../../assets/images/companies/sass.svg"
import adobe from "../../assets/images/companies/adobe.svg"
import airbnb from "../../assets/images/companies/airbnb.svg"
import flutter from "../../assets/images/companies/flutter.svg"
import mailchimp from "../../assets/images/companies/mailchimp.svg"
import spotify from "../../assets/images/companies/spotify.svg"
import reddit from "../../assets/images/companies/reddit.svg"
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";
import empty from "../../assets/images/empty.png";
import {
    Col,
    Row,
  DropdownItem,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { Link } from "react-router-dom"

const arrayData = [
  {
    id: 1,
    title: "(10) Leave Requests to Approve",
    icon: "mdi-table-account",
    background: "rgba(52,188,64,.1)",
    color:'#34bc40'
  },
  {
    id: 2,
    title: "(6) Timesheets to Approve",
    icon: "mdi-calendar",
    background: "rgba(255,166,47,.1)",
    color:'#ffa62f'
  },
]

const AddedJobs = () => {
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h4 className="card-title mb-4"><i className="mdi mdi-menu"></i> My Actions</h4>
          <SimpleBar>
            <div className="vstack gap-4">
              {arrayData.map((data, key) => (
                <div key={key} className="d-flex">
                  <div className="avatar-xs mx-auto mb-3">
                    <span
                      className=
                        "avatar-title rounded-circle font-size-20" style={{backgroundColor: data.background }} >
                      <i
                        className={"mdi " + data.icon }
                        style={{ color:data.color }}
                      ></i>
                    </span>
                  </div>
                  <div className="ms-2 flex-grow-1">
                    <h6 className="mt-2 font-size-15">
                      <a href="#" className="text-body">
                        {data.title}
                      </a>
                    </h6>
               
                  </div>
                </div>
              ))}
            </div>
          </SimpleBar>
        </div>
      </div>
    </React.Fragment>
  )
}


const AddedQuick = () => {
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h4 className="card-title mb-4"><i className="mdi mdi-flash"></i> Quick Startup</h4>
         
             
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="/assignLeave">
                        <img src={github} alt="Github" />
                        <span>Assign Leave</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="/leaveList">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Leave List</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="/addemployee">
                        <img src={dribbble} alt="dribbble" />
                        <span>Add Employee</span>
                      </Link>
                    </Col>
                  </Row>
                  

                 
                </div>
   
        </div>
      </div>
    </React.Fragment>
  )
}
const EmployeeOnLeave = () => {
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h4 className="card-title mb-4"><i className="mdi mdi-exit-run"></i> Employees on Leave Today</h4>
         
             
                <div className="px-lg-2">
                 <SimpleBar>
                 <img className ="img-thumbnail" src={empty}/>
                 <p style={{ textAlign:'center',paddingTop:'10px' }}>No Data Found</p>
                 </SimpleBar>
                </div>
   
        </div>
      </div>
    </React.Fragment>
  )
}
export default AddedJobs
export { AddedQuick,EmployeeOnLeave }
