import React,{useEffect,useCallback} from "react"

import { Row, Col, Card, CardBody, CardTitle,Modal} from "reactstrap"
import { Link } from "react-router-dom"
import PieChart from "../AllCharts/chartjs/piechart"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"
import BarChart from "pages/AllCharts/chartjs/barchart"
import { Pie } from "react-chartjs-2"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import TableContainer from "components/Common/TableContainer"
import { AddedQuick } from "pages/DashboardJob/AddedJobs"

const WelcomeComp = () => {
  
  const [count, setCount]= React.useState(1);
  const [present, setPresent]= React.useState('');
  const [absent, setAbsent]= React.useState('');
  const [total, setTotal]= React.useState('');
  const [modal_xlarge, setmodal_xlarge]= React.useState(false);
  const [modal_ylarge, setmodal_ylarge]= React.useState(false);
  const [Presentdata, setPresentData]= React.useState([]);
  const [Absentdata, setAbsentData]= React.useState([]);
  const columns = React.useMemo(
    () => [
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name'
        },
        {
            Header: 'Role',
            accessor: 'role'
        },
        {
            Header: 'Created date',
            accessor: 'created_at'
        },
        {
            Header: 'Actions',
            accessor: 'id',
            disableFilters: true,
            Cell: (cellProps) => {
                return (
                    <ul className="list-unstyled hstack gap-1 mb-0">
                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                            <Link to="/job-details" className="btn btn-sm btn-soft-primary">
                                <i className="mdi mdi-eye-outline" id="viewtooltip"></i></Link>
                        </li>
                        

                        <li>
                            <Link
                                to={`/edit-employee/${cellProps.row.original.id}`}
                                className="btn btn-sm btn-soft-info"
                              
                            >
                                <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                
                            </Link>
                        </li>

                        <li>
                            <Link
                                to="#"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                    const jobData = cellProps.row.original;
                                    onClickDelete(jobData);
                                }}
                            >
                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                
                            </Link>
                        </li>
                    </ul>
                );
            }
            
        },
    ],
    []
);
useEffect(()=>{
    fetchAttendance();
},[count])

const fetchAttendance = useCallback(()=>{
  var formdata = new FormData();
  formdata.append("method", "fetch-attendance-for-graph");

  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  fetch("https://api.frontforcecrm.com/attendance.php", requestOptions)
    .then(response => response.json())
    .then(result => {
      setAbsent(result.absent)
      setPresent(result.present)
      setTotal(result.total)
      setPresentData(result.present_data)
      setAbsentData(result.absent_data)

    })
    .catch(error => console.log('error', error));

})
  const refreshTheAttendance = ()=>{
    var formdata = new FormData();
  formdata.append("method", "fetch-attendance-from-machine");

  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  fetch("https://api.frontforcecrm.com/attendance.php", requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result){
        fetchAttendance();
        window.location.reload();
      }

    })
    .catch(error => console.log('error', error));

  }
  var pieChartColors =  getChartColorsArray('["--bs-success", "#a31b1bf0"]'); 
  const data = {
    labels: ["Present", "Absent"],
    datasets: [
      {
        data: [present, absent],
        backgroundColor: pieChartColors,
        hoverBackgroundColor: pieChartColors,
        hoverBorderColor: "#fff",
      },
    ],
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }
  function tog_ylarge() {
    setmodal_ylarge(!modal_ylarge);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  return (
    <React.Fragment>
      {/* <Card className="overflow-hidden">
        <CardBody className="pt-1">
          <Row>
            <Col sm="12">
              <Row>
                <Col md="3">
              <div className="avatar-md">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
                </Col>
                <Col md="9">
              <div className="pt-3">
                <Row>
                  <Col xs="12">
                   <h4>Punch In</h4>
                   <p>{new Date().toLocaleString()}</p>
                  </Col>
                </Row>
                
              </div>
            </Col>
             </Row>
            </Col>

          
          </Row>
          <Row>
            <Col md="12">
            <div className="mt-2">
                  <button
                   onClick={()=>{
                    if(items == 'Punch In'){
                      setItems('Punch Out')
                    }else{
                      setItems('Punch In')
                    }
                   }}
                    className="btn btn-primary  btn-sm"
                  >
                    {items}<i className="mdi mdi-arrow-right ms-1"></i>
                  </button>
                </div>
            </Col>
          </Row>
        </CardBody>
      </Card> */}
             <Row>
              <Col lg={7}>
              <Card>
                <CardBody>
                 <CardTitle className="mb-4">
                 <Row>
                 <Col md={6}>
                  Attendence
                 </Col>
                  {/* <Col md={6} style={{textAlign:'end'}}>
                    <i onClick={()=>refreshTheAttendance()} style={{fontSize:18,cursor:'pointer'}} className="mdi mdi-refresh"></i>
                  </Col> */}
                 </Row>
                  </CardTitle>
            
                 <Row>

                  <Col md={4}>
                  <h4>Total {'\n'} </h4>
                  <h6>{total}</h6>
                  </Col>
                  <Col md={4}>
                  <h4>Present {'\n'} </h4>
                  <h6>{present}</h6>
                  </Col>
                  <Col md={4}>
                  <h4>Absent {'\n'} </h4>
                  <h6>{absent}</h6>
                  </Col>
                 </Row>
                  <Pie width={751} height={260} data={data} onElementsClick={(e)=>{
                    if(e[0]._view.label == 'Present'){
                        setmodal_xlarge(true)
                    }else if(e[0]._view.label == 'Absent'){
                      setmodal_ylarge(true)
                    }
                  }} />
                  
                
                </CardBody>
              </Card>
              </Col>
             <Col lg={4}>
             <AddedQuick/>
            </Col>
                     <Modal
                      keyboard={false}
                      backdrop="static"
                      size="xl"
                      isOpen={modal_xlarge}
                      toggle={() => {
                        tog_xlarge();
                      }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          Present Employee
                        </h5>
                        <button
                          onClick={() => {
                            setmodal_xlarge(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <Row>

                      <Col md={12}>
                      <TableContainer
                                columns={columns}
                                data={Presentdata}
                                isGlobalFilter={true}
                                isAddOptions={true}
                                customPageSize={10}
                                className="custom-header-css"
                            />
                      </Col>
                      </Row>
                      </div>
                    </Modal>
                     <Modal
                      keyboard={false}
                      backdrop="static"
                      size="xl"
                      isOpen={modal_ylarge}
                      toggle={() => {
                        tog_ylarge();
                      }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          Absent Employee
                        </h5>
                        <button
                          onClick={() => {
                            setmodal_ylarge(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <Row>

                      <Col md={12}>
                      <TableContainer
                                columns={columns}
                                data={Absentdata}
                                isGlobalFilter={true}
                                isAddOptions={true}
                                customPageSize={10}
                                className="custom-header-css"
                            />
                      </Col>
                      </Row>
                      </div>
                    </Modal>
             </Row>
              
            
    </React.Fragment>
  )
}

export default WelcomeComp

