import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  TabContent,
  TabPane,
  Form,
  NavLink,
} from "reactstrap"
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"

import { map } from "lodash"

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../assets/images/users/avatar-4.jpg"
import avatar6 from "../../assets/images/users/avatar-6.jpg"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import { Countries, Currencies, Nationality } from "common/data/nationalities"

const optionGroup2 = [
  {
    label: "Marital Status",
    options: [
      { label: "Single", value: "Single" },
      { label: "Married", value: "Married" },
      { label: "Other", value: "Other" },
    ],
  },
]
const optionGroup = [
  {
    label: "Blood Type",
    options: [
      { label: "A+", value: "A+" },
      { label: "A-", value: "A-" },
      { label: "B+", value: "B+" },
      { label: "B-", value: "B-" },
      { label: "AB+", value: "AB+" },
      { label: "AB-", value: "AB-" },
      { label: "O+", value: "O+" },
      { label: "O-", value: "O-" },
    ],
  },
]
const SettingHRM = props => {
  document.title = "Setting | HR Dashboard"
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [selectedGroup, setselectedGroup] = useState(null)
  const [selectedGroupOther, setselectedGroupOther] = useState(null)
  const [selectedBlood, setselectedBlood] = useState(null)
  const [selectedCountry, setselectedCountry] = useState(null)
  const [selectedCurrency, setselectedCurrency] = useState(null)
  const [rows1, setrows1] = useState([{ id: 1 }])
  const [toggleSwitchSize, settoggleSwitchSize] = useState(false)
  const [toggleBankDetail, settoggleBankDetail] = useState(false)
  const [assignedSalary, setassignedSalary] = useState(false)
  const [assignedSalaryAttachment, setassignedSalaryAttachment] =useState(false)
  const [jobAttachment, setjobAttachment] =useState(false)
  const [qualificationAttachment, setqualificationAttachment] =useState(false)
  const [skill, setSkill] =useState(false)
  const [languages, setLanguages] =useState(false)
  const [License, setLicense] =useState(false)
  const [workExperience, setWorkExperience] = useState(false)
  const [education, setEducation] = useState(false)
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }
  function handleSelectGroup2(selectedGroupOther) {
    setselectedGroupOther(selectedGroupOther)
  }
  function handleSelectBlood(selectedBlood) {
    setselectedBlood(selectedBlood)
  }
  function handleSelectCountry(selectCountry) {
    setselectedCountry(selectCountry)
  }
  function handleSelectCurrency(selectCurrency) {
    setselectedCurrency(selectCurrency)
  }

  function handleAddRowNested() {
    const modifiedRows = [...rows1]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows1(modifiedRows)
  }

  function handleRemoveRow(id) {
    if (id !== 1) {
      var modifiedRows = [...rows1]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setrows1(modifiedRows)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem="My Info" />

          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              <Card className="email-leftbar">
                <Row>
                  <Col md="12" style={{ textAlign: "center" }}>
                    <h3>Admin</h3>
                    <div className="">
                      <center>
                        <img
                          src={avatar1}
                          alt=""
                          width={"100"}
                          className="img-thumbnail rounded-circle"
                        />
                      </center>
                    </div>
                  </Col>
                </Row>
                <div className="mail-list mt-4">
                  <Nav pills className="flex-column">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Personal Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleVertical("2")
                        }}
                      >
                        Contact Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleVertical("3")
                        }}
                      >
                        Job Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: verticalActiveTab === "4",
                        })}
                        onClick={() => {
                          toggleVertical("4")
                        }}
                      >
                        Salary
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: verticalActiveTab === "5",
                        })}
                        onClick={() => {
                          toggleVertical("5")
                        }}
                      >
                        Qualification
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Card>

              <div className="email-rightbar mb-3">
                <Card>
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    <TabPane tabId="1">
                      <div style={{ padding: "15px" }}>
                        <Row>
                          <Col md={12}>
                            <h4>Personal Details</h4>
                            <hr></hr>
                          </Col>
                          <Col md={5}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                First Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Enter Your First Name"
                              />
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Last Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-lastname-Input"
                                placeholder="Enter Your Last Name"
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Nickname
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-nickname-Input"
                                placeholder="Nickname"
                              />
                            </div>
                          </Col>
                        </Row>
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                        <Row>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Employee Id
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-nickname-Input"
                                placeholder="Employee ID"
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Other Id
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-nickname-Input"
                                placeholder="Employee ID"
                              />
                            </div>
                          </Col>
                        </Row>
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                        <Row>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Driver's License Number{" "}
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-nickname-Input"
                                placeholder="Employee ID"
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                License Expiry Date
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                id="formrow-nickname-Input"
                                placeholder="Employee ID"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                SSN Number
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-nickname-Input"
                                placeholder="SSN Number"
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                SIN Number
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-nickname-Input"
                                placeholder="SIN Number"
                              />
                            </div>
                          </Col>
                        </Row>
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                        <Row>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Nationality
                              </Label>
                              <Select
                                value={selectedBlood}
                                onChange={() => {
                                  handleSelectGroup()
                                }}
                                options={Nationality}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Marital Status
                              </Label>
                              <Select
                                value={selectedGroupOther}
                                onChange={() => {
                                  handleSelectGroup2()
                                }}
                                options={optionGroup2}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Date of Birth
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                id="formrow-nickname-Input"
                                placeholder="Employee ID"
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Blood Type
                              </Label>
                              <Select
                                value={selectedGroup}
                                onChange={() => {
                                  handleSelectGroup()
                                }}
                                options={optionGroup}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                        </Row>
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                        <Row>
                          <Col md={4}>
                            <div className="mb-4">
                              <h5 className="font-size-14 mb-4">Gender</h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  value="Male"
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios1"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exampleRadios"
                                  id="exampleRadios2"
                                  value="Female"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios2"
                                >
                                  Female
                                </label>
                              </div>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-4">
                              <h5 className="font-size-14 mb-4">Smoker?</h5>
                              <div className="form-check mb-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exampleRadiosOther"
                                  id="exampleRadios4"
                                  value="Yes"
                                  defaultChecked
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios4"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exampleRadiosOther"
                                  id="exampleRadios3"
                                  value="No"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleRadios3"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div style={{ padding: "15px" }}>
                        <Row>
                          <Col md={12}>
                            <h4>Contact Details</h4>
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                          </Col>
                          <Col md={12}>
                            <h5>Address</h5>
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Street 1
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Street 1"
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Street 2
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Street 2"
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                City
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="City"
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Province/State
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Province/State"
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Zip/Postal Code
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Zip/Postal Code"
                              />
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Country
                              </Label>
                              <Select
                                value={selectedCountry}
                                onChange={() => {
                                  handleSelectCountry()
                                }}
                                options={Countries}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <h5>Telephone</h5>
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Home
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder=""
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Mobile
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder=""
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Work
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder=""
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <h5>Email</h5>
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Work Email
                              </Label>
                              <Input
                                type="email"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder=""
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Other Email
                              </Label>
                              <Input
                                type="email"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder=""
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <h5>Emergency Contact</h5>
                            <Button
                              onClick={() => {
                                handleAddRowNested()
                              }}
                              color="success"
                              className="mt-1"
                            >
                              Add Number
                            </Button>
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                          </Col>
                          <Col md={12}>
                            <Label>Phone Number & Relation</Label>
                            <table style={{ width: "100%" }}>
                              <tbody>
                                {(rows1 || []).map((formRow, key) => (
                                  <tr key={key}>
                                    <td>
                                      <Row className="mb-2">
                                        <Col md="4">
                                          <Input
                                            type="text"
                                            className="inner form-control"
                                            placeholder="Enter your phone no..."
                                          />
                                        </Col>
                                        <Col md="4">
                                          <Input
                                            type="text"
                                            className="inner form-control"
                                            placeholder="Relation"
                                          />
                                        </Col>
                                        <Col md="2">
                                          <Button
                                            color="primary"
                                            className="btn-block inner"
                                            id="unknown-btn"
                                            style={{ width: "100%" }}
                                            onClick={e => {
                                              handleRemoveRow(formRow.id)
                                            }}
                                          >
                                            {" "}
                                            Delete{" "}
                                          </Button>
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div style={{ padding: "15px" }}>
                        <Row>
                          <Col md={12}>
                            <h5>Job Details</h5>
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Joining Date
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder=""
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Job Title
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder=""
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Job Specification
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder=""
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Job Category
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder=""
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Sub Unit
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder=""
                              />
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Location
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder=""
                              />
                            </div>
                          </Col>

                          <Col md={4}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Employment Status
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder=""
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="form-check form-switch mb-3">
                              <label
                                className="form-check-label"
                                htmlFor="customSwitchsizesm"
                              >
                                Include Employment Contract Details
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizesm"
                                // onClick={() => {
                                //   this.setState({
                                //     toggleSwitchSize: !this.state
                                //       .toggleSwitchSize,
                                //   })
                                // }}
                                onClick={e => {
                                  settoggleSwitchSize(!toggleSwitchSize)
                                }}
                              />
                            </div>
                          </Col>
                          {toggleSwitchSize == true ? (
                            <Col md={12}>
                              <Row>
                                <Col md={4}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Contract Start Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder=""
                                    />
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Contract End Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder=""
                                    />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Attachments?
                                    </Label>
                                    <Input
                                      type="file"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder=""
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          ) : (
                            <Col></Col>
                          )}
                        </Row>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>
                        </div>
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col md={4}>
                                <h6 className="mt-2">
                                  Employee Termination / Activiation
                                </h6>
                              </Col>
                              <Col md={4}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                >
                                  Terminate Employment
                                </button>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12}>
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                            <Row>
                              <Col md={2}>
                                <h6 className="mt-2">Attachments</h6>
                              </Col>
                              <Col md={3}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                  onClick={()=>{
                                    setjobAttachment(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                              </Col>
                            </Row>
                          </Col>
                          {
                            jobAttachment == true ? 
                            <Col md={12}>
                              <Row>
                                <Col md={12}>
                                <h5>Add Attachment</h5>
                                    <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                    ></hr>
                                </Col>
                                <Col md={5}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Attachments?
                                    </Label>
                                    <Input
                                      type="file"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder=""
                                    />
                                  </div>
                                </Col>
                                <Col md={8}>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Comment
                                    </Label>
                                    <textarea className="form-control"></textarea>
                                  </div>
                                </Col>
                                <Col md={12} style={{ textAlign: "end" }}>
                              <button
                                type="submit"
                                className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                onClick={() => {
                                  setjobAttachment(false)
                                }}
                              >
                                Cancel
                              </button>
                              &nbsp; &nbsp;
                              <button
                                type="submit"
                                className="btn-rounded btn btn-success"
                              >
                                Save Changes
                              </button>
                            </Col>
                              </Row>
                              
                            </Col>:null
                          }
                        </Row>
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                      </div>
                    </TabPane>

                    <TabPane tabId="4">
                      <div style={{ padding: "15px" }}>
                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col md={3}>
                                <h6 className="mt-2">
                                  Assigned Salary Components
                                </h6>
                              </Col>
                              <Col md={3}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setassignedSalary(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>

                        {assignedSalary == true ? (
                          <Row>
                            <Col md={12}>
                              <h5>Add Salary Component</h5>
                              <hr
                                style={{ borderTop: "1px solid #3c3c3c24" }}
                              ></hr>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Salary Component
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-nickname-Input"
                                  placeholder="Salary Component"
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Pay Grade
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-nickname-Input"
                                  placeholder="Pay Grade"
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Pay Frequency
                                </Label>
                                <select className="form-control">
                                  <option value="Monthly">Monthly</option>
                                  <option value="Weekly">Weekly</option>
                                  <option value="Daily">Daily</option>
                                  <option value="Hourly">Hourly</option>
                                </select>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Select Currency
                                </Label>
                                <Select
                                  value={selectedCurrency}
                                  onChange={() => {
                                    handleSelectCurrency()
                                  }}
                                  options={Currencies}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Amount
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-nickname-Input"
                                  placeholder="Amount"
                                />
                              </div>
                            </Col>
                            <Col md={8}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Description
                                </Label>
                                <textarea className="form-control"></textarea>
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="form-check form-switch mb-3">
                                <label
                                  className="form-check-label"
                                  htmlFor="customSwitchsizesm1"
                                >
                                  Include Direct Deposit Details
                                </label>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizesm1"
                                  // onClick={() => {
                                  //   this.setState({
                                  //     toggleSwitchSize: !this.state
                                  //       .toggleSwitchSize,
                                  //   })
                                  // }}
                                  onClick={e => {
                                    settoggleBankDetail(!toggleBankDetail)
                                  }}
                                />
                              </div>
                            </Col>
                            {toggleBankDetail == true ? (
                              <Col md={12}>
                                <Row>
                                  <Col md={5}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Account Number
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Account Number"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={5}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Account Type
                                      </Label>
                                      <select className="form-control">
                                        <option value="null">
                                          Select Account Type
                                        </option>
                                        <option value="Saving">Saving</option>
                                        <option value="Current">Current</option>
                                        <option value="Other">Other</option>
                                      </select>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Routing Number
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Routing Number"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Amount
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Amount"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            ) : (
                              <Col></Col>
                            )}
                            <Col md={12} style={{ textAlign: "end" }}>
                              <button
                                type="submit"
                                className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                onClick={() => {
                                  setassignedSalary(false)
                                }}
                              >
                                Cancel
                              </button>
                              &nbsp; &nbsp;
                              <button
                                type="submit"
                                className="btn-rounded btn btn-success"
                              >
                                Save Changes
                              </button>
                            </Col>
                          </Row>
                        ) : null}
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col md={2}>
                                <h6 className="mt-2">Attachments</h6>
                              </Col>
                              <Col md={3}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setassignedSalaryAttachment(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>

                        {assignedSalaryAttachment == true ? (
                          <Row>
                            <Col md={12}>
                              <h5>Add Attachments</h5>
                              <hr
                                style={{ borderTop: "1px solid #3c3c3c24" }}
                              ></hr>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-salary-attachment">
                                  Select File
                                </Label>
                                <Input
                                  type="file"
                                  className="form-control"
                                  id="formrow-salary-attachment"
                                />
                              </div>
                            </Col>
                            <Col md={8}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-salary-attachment-description">
                                  Comments
                                </Label>
                                <textarea
                                  className="form-control"
                                  id="formrow-salary-attachment-description"
                                ></textarea>
                              </div>
                            </Col>
                            <Col md={12} style={{ textAlign: "end" }}>
                              <button
                                type="submit"
                                className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                onClick={() => {
                                  setassignedSalaryAttachment(false)
                                }}
                              >
                                Cancel
                              </button>
                              &nbsp; &nbsp;
                              <button
                                type="submit"
                                className="btn-rounded btn btn-success"
                              >
                                Save Changes
                              </button>
                            </Col>
                          </Row>
                        ) : null}
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <div style={{ padding: "15px" }}>
                        <Row>
                          <Col md={12}>
                            <h5>Qualification</h5>
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                          </Col>

                          <Col md={12}>
                            <Row>
                              <Col md={3}>
                                <h6 className="mt-2">Work Experience</h6>
                              </Col>
                              <Col md={3}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setWorkExperience(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                              </Col>
                            </Row>
                            {workExperience == true ? (
                              <Row>
                                <Col md={12}>
                                  <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                  ></hr>
                                  <h5>Add Work Experience</h5>
                                  <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                  ></hr>
                                </Col>
                                <Col md={12}>
                                  <Row>
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Company Name
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Company name"
                                        />
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Job Title
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Job title"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          From
                                        </Label>
                                        <Input
                                          type="date"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Company name"
                                        />
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          To
                                        </Label>
                                        <Input
                                          type="date"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Job title"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={8}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Comment
                                        </Label>
                                        <textarea className="form-control"></textarea>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={12} style={{ textAlign: "end" }}>
                                      <button
                                        type="submit"
                                        className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                        onClick={() => {
                                          setWorkExperience(false)
                                        }}
                                      >
                                        Cancel
                                      </button>
                                      &nbsp; &nbsp;
                                      <button
                                        type="submit"
                                        className="btn-rounded btn btn-success"
                                      >
                                        Save Changes
                                      </button>
                                    </Col>
                                  </Row>
                                  
                             
                                </Col>
                              </Row>
                            ) : null}
                            <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                  ></hr>
                             <Row>

                            <Col md={3}>
                                <h6 className="mt-2">Education</h6>
                            </Col>
                            <Col md={3}>
                                <button
                                type="submit"
                                className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                onClick={() => {
                                    setEducation(true)
                                }}
                                >
                                <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                            </Col>
                            </Row>
                             {education == true ?
                              <Row>
                              <Col md={12}>
                                    <hr
                                      style={{ borderTop: "1px solid #3c3c3c24" }}
                                    ></hr>
                                    <h5>Add Education</h5>
                                    <hr
                                      style={{ borderTop: "1px solid #3c3c3c24" }}
                                    ></hr>
                                  </Col>
                                  <Col md={12}>
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Level
                                          </Label>
                                          <select className="form-control">
                                              <option value="null">--Select--</option>
                                              <option value="Bachelor's Degree">Bachelor's Degree</option>
                                              <option value="College Undergraduate">College Undergraduate</option>
                                              <option value="High School Diploma">High School Diploma</option>
                                              <option value="Master's Degree">Master's Degree</option>
                                          </select>
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                             Institute
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Institute"
                                          />
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                          Major/Specialization
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Major/Specialization"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                             Start Date
                                          </Label>
                                          <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Institute"
                                          />
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                          End Date
                                          </Label>
                                          <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Major/Specialization"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12} style={{ textAlign: "end" }}>
                                        <button
                                          type="submit"
                                          className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                          onClick={() => {
                                            setEducation(false)
                                          }}
                                        >
                                          Cancel
                                        </button>
                                        &nbsp; &nbsp;
                                        <button
                                          type="submit"
                                          className="btn-rounded btn btn-success"
                                        >
                                          Save Changes
                                        </button>
                                      </Col>
                                      </Row>
                                    </Col>
                              </Row>:null }
                              <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                  ></hr>
                             <Row>

                            <Col md={3}>
                                <h6 className="mt-2">Skill</h6>
                            </Col>
                            <Col md={3}>
                                <button
                                type="submit"
                                className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                onClick={() => {
                                    setSkill(true)
                                }}
                                >
                                <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                            </Col>
                            </Row>
                               {
                            skill == true ?  <Row>
                            <Col md={12}>
                               <hr
                                 style={{ borderTop: "1px solid #3c3c3c24" }}
                               ></hr>
                               <h5>Add Skill</h5>
                               <hr
                                 style={{ borderTop: "1px solid #3c3c3c24" }}
                               ></hr>
                             </Col>   
                             <Col md={4}>
                             <div className="mb-3">
                                     <Label htmlFor="formrow-firstname-Input">
                                        Skill Title
                                     </Label>
                                     <Input
                                       type="text"
                                       className="form-control"
                                       id="formrow-firstname-Input"
                                       placeholder="Skill title"
                                     />
                                   </div>
                             </Col>
                             <Col md={4}>
                             <div className="mb-3">
                                     <Label htmlFor="formrow-firstname-Input">
                                        Years of Experience
                                     </Label>
                                     <Input
                                       type="text"
                                       className="form-control"
                                       id="formrow-firstname-Input"
                                       placeholder="Year of experience"
                                     />
                                   </div>
                             </Col>
                             <Col md={8}>
                             <div className="mb-3">
                                     <Label htmlFor="formrow-firstname-Input">
                                        Comments
                                     </Label>
                                    <textarea className="form-control"></textarea>
                                   </div>
                             </Col>
                             <Col md={12} style={{ textAlign: "end" }}>
                                   <button
                                     type="submit"
                                     className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                     onClick={() => {
                                       setSkill(false)
                                     }}
                                   >
                                     Cancel
                                   </button>
                                   &nbsp; &nbsp;
                                   <button
                                     type="submit"
                                     className="btn-rounded btn btn-success"
                                   >
                                     Save Changes
                                   </button>
                                 </Col>

                                </Row>:null
                                    }
                                    <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                  ></hr>
                             <Row>

                            <Col md={3}>
                                <h6 className="mt-2">Languages</h6>
                            </Col>
                            <Col md={3}>
                                <button
                                type="submit"
                                className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                onClick={() => {
                                    setLanguages(true)
                                }}
                                >
                                <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                            </Col>
                            </Row>
                             {
                                languages == true ? 
                                <Row>
                                <Col md={12}>
                                   <hr
                                     style={{ borderTop: "1px solid #3c3c3c24" }}
                                   ></hr>
                                   <h5>Add Language</h5>
                                   <hr
                                     style={{ borderTop: "1px solid #3c3c3c24" }}
                                   ></hr>
                                 </Col>   
                                 <Col md={4}>
                                 <div className="mb-3">
                                         <Label htmlFor="formrow-firstname-Input">
                                                Language
                                         </Label>
                                         <Input
                                           type="text"
                                           className="form-control"
                                           id="formrow-firstname-Input"
                                           placeholder="Language"
                                         />
                                       </div>
                                 </Col>
                                 <Col md={4}>
                                 <div className="mb-3">
                                         <Label htmlFor="formrow-firstname-Input">
                                             Fluency
                                         </Label>
                                        <select className="form-control">
                                            <option>--Select--</option>
                                            <option value="Writing">Writing</option>
                                            <option value="Reading">Reading</option>
                                            <option value="Speaking">Speaking</option>
                                        </select>
                                       </div>
                                 </Col>
                                 <Col md={4}>
                                 <div className="mb-3">
                                         <Label htmlFor="formrow-firstname-Input">
                                            Competency
                                         </Label>
                                        <select className="form-control">
                                            <option>--Select--</option>
                                            <option value="Writing">Poor</option>
                                            <option value="Reading">Basic</option>
                                            <option value="Speaking">Good</option>
                                            <option value="Speaking">Mother Tongue</option>
                                        </select>
                                       </div>
                                 </Col>
                                 <Col md={8}>
                                 <div className="mb-3">
                                         <Label htmlFor="formrow-firstname-Input">
                                            Comments
                                         </Label>
                                        <textarea className="form-control"></textarea>
                                       </div>
                                 </Col>
                                 <Col md={12} style={{ textAlign: "end" }}>
                                       <button
                                         type="submit"
                                         className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                         onClick={() => {
                                           setLanguages(false)
                                         }}
                                       >
                                         Cancel
                                       </button>
                                       &nbsp; &nbsp;
                                       <button
                                         type="submit"
                                         className="btn-rounded btn btn-success"
                                       >
                                         Save Changes
                                       </button>
                                     </Col>
    
                                    </Row>: null
                             }
                                   <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                  ></hr>
                             <Row>

                            <Col md={3}>
                                <h6 className="mt-2">License</h6>
                            </Col>
                            <Col md={3}>
                                <button
                                type="submit"
                                className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                onClick={() => {
                                    setLicense(true)
                                }}
                                >
                                <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                            </Col>
                            </Row>
                            {
                                License == true ? 
                                <Row>
                                <Col md={12}>
                                   <hr
                                     style={{ borderTop: "1px solid #3c3c3c24" }}
                                   ></hr>
                                   <h5>Add License</h5>
                                   <hr
                                     style={{ borderTop: "1px solid #3c3c3c24" }}
                                   ></hr>
                                 </Col>   
                                 <Col md={5}>
                                 <div className="mb-3">
                                         <Label htmlFor="formrow-firstname-Input">
                                                Type of License
                                         </Label>
                                         <Input
                                           type="text"
                                           className="form-control"
                                           id="formrow-firstname-Input"
                                           placeholder="Enter Type here"
                                         />
                                       </div>
                                 </Col>
                                 <Col md={5}>
                                 <div className="mb-3">
                                         <Label htmlFor="formrow-firstname-Input">
                                             License Number
                                         </Label>
                                         <Input
                                           type="text"
                                           className="form-control"
                                           id="formrow-firstname-Input"
                                           placeholder="License Number"
                                         />
                                       </div>
                                   </Col>

                                   <Col md={4}>
                                 <div className="mb-3">
                                         <Label htmlFor="formrow-firstname-Input">
                                            Issued Date
                                         </Label>
                                         <Input
                                           type="date"
                                           className="form-control"
                                           id="formrow-firstname-Input"
                                           placeholder=""
                                         />
                                       </div>
                                 </Col>
                                   <Col md={4}>
                                 <div className="mb-3">
                                         <Label htmlFor="formrow-firstname-Input">
                                            Expiry Date
                                         </Label>
                                         <Input
                                           type="date"
                                           className="form-control"
                                           id="formrow-firstname-Input"
                                           placeholder=""
                                         />
                                       </div>
                                 </Col>
                                    <Col md={12} style={{ textAlign: "end" }}>
                                       <button
                                         type="submit"
                                         className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                         onClick={() => {
                                           setLicense(false)
                                         }}
                                       >
                                         Cancel
                                       </button>
                                       &nbsp; &nbsp;
                                       <button
                                         type="submit"
                                         className="btn-rounded btn btn-success"
                                       >
                                         Save Changes
                                       </button>
                                     </Col>
    
                                    </Row>: null
                             }
                                   <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                  ></hr>
                             <Row>

                            <Col md={3}>
                                <h6 className="mt-2">Attachments</h6>
                            </Col>
                            <Col md={3}>
                                <button
                                type="submit"
                                className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                onClick={() => {
                                    setqualificationAttachment(true)
                                }}
                                >
                                <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                            </Col>
                            </Row>
                            {
                                qualificationAttachment == true ? 
                                    <Row>
                                <Col md={12}>
                                   <hr
                                     style={{ borderTop: "1px solid #3c3c3c24" }}
                                   ></hr>
                                   <h5>Add Attachment</h5>
                                   <hr
                                     style={{ borderTop: "1px solid #3c3c3c24" }}
                                   ></hr>
                                 </Col>   
                                 <Col md={5}>
                                     <div className="mb-3">
                                         <Label htmlFor="formrow-firstname-Input">
                                                Select File
                                         </Label>
                                         <Input
                                           type="file"
                                           className="form-control"
                                           id="formrow-firstname-Input"
                                           
                                         />
                                       </div>
                                 </Col>
                                 <Col md={8}>
                                 <div className="mb-3">
                                         <Label htmlFor="formrow-firstname-Input">
                                            Comments
                                         </Label>
                                         <textarea className="form-control"></textarea>
                                       </div>
                                 </Col>
                                    <Col md={12} style={{ textAlign: "end" }}>
                                       <button
                                         type="submit"
                                         className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                         onClick={() => {
                                           setqualificationAttachment(false)
                                         }}
                                       >
                                         Cancel
                                       </button>
                                       &nbsp; &nbsp;
                                       <button
                                         type="submit"
                                         className="btn-rounded btn btn-success"
                                       >
                                         Save Changes
                                       </button>
                                     </Col>
    
                                    </Row>: null
                             }
                            
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(SettingHRM)
