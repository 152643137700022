import React,{useState,useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

//import images
import adobephotoshop from "../../assets/images/companies/adobe-photoshop.svg";

const Overview = () => {
    const {id} = useParams();
    const [count,setCount]= useState(1);
    const [jobData,setJobData]= useState([]);
    const [first_name,setFirstName]= useState('');
    useEffect(()=>{
        getPayRoll();
    },[count]);
    const getPayRoll = () =>{
        var formdata = new FormData();
formdata.append("method", "get-payroll");
formdata.append("post_id", id);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("https://api.frontforcecrm.com/pay-roll.php", requestOptions)
  .then(response => response.json())
  .then((result) => {
    if(result){
        console.log(result)
        result.map((e)=>{
            setFirstName(e.first_name)
            setJobData(e.job_info)
        })
    }
  })
  .catch(error => console.log('error', error));
    }
    return (
        <React.Fragment>
            <Col xl={4}>
                <Card>
                    <CardBody>
                        <h5 className="fw-semibold">Overview</h5>

                        <div className="table-responsive">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th scope="col">Job Title</th>
                                        <td scope="col">{jobData.map((e)=>e.job_title)}</td>
                                    </tr>
                                   
                                
                                    <tr>
                                        <th scope="row">Job Type</th>
                                        <td><span className="badge badge-soft-success">{jobData.map((e)=>e.job_category)}</span></td>
                                    </tr>
                                  
                                    <tr>
                                        <th scope="row">Hire Date</th>
                                        <td>{jobData.map((e)=>e.joing_date)}</td>
                                    </tr>
                                
                                </tbody>
                            </table>
                        </div>
                        <div className="hstack gap-2">
                            <Link to={`../edit-employee/${id}`} className="btn btn-primary w-100">Emp Info</Link>
                            <button className="btn btn-info w-100">Print</button>
                        </div>
                    </CardBody>
                </Card>

               
            </Col>
        </React.Fragment>
    );
}

export default Overview;