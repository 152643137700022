import React, { useState } from "react";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  Modal,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb

import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "components/Common/TableContainer";
import { Link } from "react-router-dom";
import toastr from "toastr";
import 'toastr/build/toastr.min.css'

const EmployeeLayout = props => {

  //meta title
  document.title = "Employee List | HR Dashboard";
  const [data,setData] =useState([]);
  const [modal_backdrop,setmodal_backdrop] = useState(false);
  const [userID,setUserID] = useState('');
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const  asyncData =() =>{
    var formdata = new FormData();
    formdata.append("method", "employee-from-machine-counter");

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://api.frontforcecrm.com/employee-from-machine.php", requestOptions)
      .then(response => response.text())
      .then((result) => {
        // setData(JSON.parse(result))
        if(result == "success"){
            toastr.success('Success','Your machine employee are successfully sync')
        }else{
          toastr.error('Error','Your machine employee are not sync properly.')
        }
      })
      .catch(error => console.log('error', error));
  }
  const  asyncToken =() =>{
    var formdata = new FormData();
    formdata.append("method", "token-checker");

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://api.frontforcecrm.com/machine-config.php", requestOptions)
      .then(response => response.text())
      .then((result) => {
        // setData(JSON.parse(result))
        if(result == "success"){
            toastr.success('Success','Your token is successfully refresh')
        }else{
          toastr.error('Error','Your token is not refresh')
        }
      })
      .catch(error => console.log('error', error));
  }
  const columns = React.useMemo(
    () => [
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name'
        },
        {
            Header: 'Role',
            accessor: 'role'
        },
        {
            Header: 'Created date',
            accessor: 'created_at'
        },
        {
            Header: 'Actions',
            accessor: 'id',
            disableFilters: true,
            Cell: (cellProps) => {
                return (
                    <ul className="list-unstyled hstack gap-1 mb-0">


                        <li>
                            <Link
                                to={`/edit-employee/${cellProps.row.original.id}`}
                                className="btn btn-sm btn-soft-info"

                            >
                                <i className="mdi mdi-pencil-outline" id="edittooltip" >Edit</i>

                            </Link>
                        </li>

                        <li>
                            <span
                                onClick={()=>DeleteSingleEmployee(cellProps.row.original.id)}
                                className="btn btn-sm btn-soft-danger"

                            >
                                <i className="mdi mdi-delete-outline" id="deletetooltip" >Delete</i>

                            </span>
                        </li>
                    </ul>
                );
            }

        },
    ],
    []
);
async function  fetchFirstFunction (){
  try {
    let val = await JSON.parse(localStorage.getItem('authUser'))
    val.map((e)=>{
      setUserID(e.user_id);
      fetch_users(e.user_id);
    })
  } catch (error) {
    console.log(error)
  }
}

React.useEffect(() => {
  fetchFirstFunction()
}, []);
const DeleteSingleEmployee = (passid) => {
  var formdata = new FormData();
  formdata.append("method", "delete-employee-by-id");
  formdata.append("delete_id", passid);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  fetch("https://api.frontforcecrm.com/leave.php", requestOptions)
    .then((response) => response.text())
    .then((result) => {
      if (result == "success") {
        toastr.success(`Successfully delete ${userID}`);
        fetch_users(userID);
      } else {
        toastr.error("Please do it again");
      }
    })
    .catch((error) => console.log("error", error));
};
const fetch_users = (id)=>{
  var formdata = new FormData();
formdata.append("method", "fetch_employees_for_desktop");
// formdata.append("page_no", 1);
formdata.append("emp_id", id);
var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("https://api.frontforcecrm.com/login.php", requestOptions)
  .then(response => response.text())
  .then((result) => {
    setData(JSON.parse(result))

  })
  .catch(error => console.log('error', error));
}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="" breadcrumbItem="Employee List" />
          <Row>
            {/* <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Employee Information</CardTitle>




                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Employee Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Employee Name"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Employee Id</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Employee ID"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Employee Status</Label>
                          <select className="form-control">
                            <option value="null">--Select--</option>
                            <option value="Full-Time Contract">Full-Time Contract</option>
                            <option value="Full-Time Permanent">Full-Time Permanent</option>
                            <option value="Full-Time Probation">Full-Time Probation</option>
                            <option value="Part-Time Contract">Part-Time Contract</option>
                            <option value="Part-Time Internship">Part-Time Internship</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Supervisor Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Supervisor Name"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Job Title</Label>
                          <select className="form-control">
                            <option value="null">--Select--</option>
                            <option value="Account Assistant">Account Assistant</option>
                            <option value="Cheif Executive Officer">Cheif Executive Officer</option>
                            <option value="Cheif Technical Officer">Cheif Technical Officer</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Sub Unit</Label>
                          <select className="form-control">
                            <option value="null">--Select--</option>
                            <option value="Engineering">Engineering</option>
                            <option value="Developement">Developement</option>
                            <option value="Quality Insurance">Quality Insurance</option>
                          </select>
                        </div>
                      </Col>
                    </Row>

                    <div style={{ textAlign:'end' }}>
                      <button type="submit" className="btn btn-info w-md">
                        Reset
                      </button>
                      &nbsp;
                      &nbsp;
                      <button type="submit" className="btn btn-success w-md">
                        Search
                      </button>
                    </div>

                </CardBody>
              </Card>
            </Col> */}
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                  <Row>
                    <Col sm={4}>

                    Employee Information
                    </Col>
                    <Col className="offset-sm-3" sm="4">
          <Row>
            <Col sm="12" >
            <div className="text-sm-end">
            <Link to='/addemployee'>
                      <button  type="submit" className="btn btn-info w-md">
                        Add New
                      </button>
                      </Link>
            </div>
            </Col>
            
          </Row>


          </Col>
                  </Row>

                    </CardTitle>

                    <Row>

                      <Col md={12}>
                      <TableContainer
                                columns={columns}
                                data={data}
                                isGlobalFilter={true}
                                isAddOptions={true}
                                customPageSize={10}
                                className="custom-header-css"
                            />
                      </Col>
                    </Row>



                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  );
};


export default EmployeeLayout;
