import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  TabContent,
  TabPane,
  Form,
  NavLink,
  Table,
} from "reactstrap"
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"

import { map } from "lodash"

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../assets/images/users/avatar-4.jpg"
import avatar6 from "../../assets/images/users/avatar-6.jpg"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import { Countries, Currencies, Nationality } from "common/data/nationalities"
import toastr from "toastr"
import 'toastr/build/toastr.min.css';
import { Thead } from "react-super-responsive-table"


const HrmReport = props => {
  document.title = "Reports | HR Dashboard"
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [rows1, setrows1] = useState([{ id: 1 }])
  const [modal_backdrop,setmodal_backdrop] = React.useState(false);
  const [showMonths,setShowMonths] = React.useState(false);
  const [showDatetoDate,setShowDatetoDate] = React.useState(false);
  const [selectedGroupOther, setselectedGroupOther] = useState([])
  const [selectedGroupOther2, setselectedGroupOther2] = useState([])
  const [options2,setoptionGroup2] = React.useState([]);
  const [optionGroup, setoptionGroup] = useState([])
  const [firstTimeData, setFirstTimeData] = useState([])
  const [data,setData] = React.useState('');
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }
  function handleSelectGroup2(sselectedGroupOther) {
    setselectedGroupOther(sselectedGroupOther.value)
    fetchAgentByID(sselectedGroupOther.value)
    }
  function handleSelectGroup(electedGroupOther) {
       setselectedGroupOther2(electedGroupOther.value)
    }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

 const changeReportType = (val) =>{
        if(val == 'monthly'){
                setShowMonths(true);
                setShowDatetoDate(false)
        }else {
                  setShowMonths(false);
                setShowDatetoDate(true)
        }
 }
 useEffect(()=>{
    console.log(localStorage.getItem('authUser'))
    let val = JSON.parse(localStorage.getItem('authUser'))
    val.map((e)=>{
      fetchLocation(e.role)
      fetchEmployees(e.user_id,e.leave_right)
    })
    fetchFirstTime()
 },[])
const fetchLocation = (role)=>{
  
    var formdata = new FormData();
    formdata.append("method", "fetch_company");
    formdata.append('role',role);
  
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
  
    fetch("https://api.frontforcecrm.com/company.php", requestOptions)
      .then(response =>response.json())
      .then((result) => {
        result.map((e)=>{
          let array = {
            label:e.location ,
            value:e.location
          }
          options2.push(array)
        })

      })
      .catch(error => console.log('error', error));
  
}
const fetchFirstTime = ()=>{
  
    var formdata = new FormData();
    formdata.append("method", "fetch_attendance_by_location");
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    fetch("https://api.frontforcecrm.com/report.php", requestOptions)
      .then(response =>response.json())
      .then((result) => {
        console.log(result)
       setFirstTimeData(result)

      })
      .catch(error => console.log('error', error));
  
}
const fetchEmployees = (role,right)=>{
  
    var formdata = new FormData();
    formdata.append("method", "fetch-user-for-select");
    formdata.append('emp_id',role);
    formdata.append('right',right);
  
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
  
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response =>response.json())
      .then((result) => {
        setoptionGroup(result)

      })
      .catch(error => console.log('error', error));
  
}
const fetchAgentByID = (title)=>{
  
    var formdata = new FormData();
    formdata.append("method", "fetch_company_employee_by_id");
    formdata.append('title',title);
  
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
  
    fetch("https://api.frontforcecrm.com/report.php", requestOptions)
      .then(response =>response.json())
      .then((result) => {
        let arrays  = []
        result.map((e)=>{
          let array = {
            label:e.id+'-'+ e.first_name + ' ' + e.last_name,
            value:e.id,
          }
          arrays.push(array)
          
        })
        setoptionGroup(arrays)

      })
      .catch(error => console.log('error', error));
  
}
  return (
    <React.Fragment>
      <div className="page-content">
      
        <Container fluid>
          
          <Breadcrumbs breadcrumbItem="Report" />

          <Row>
            <Col xs="12">
            <div className="mb-3">
                <Card>
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    <TabPane tabId="1">
                      <div style={{ padding: "15px" }}>
                      <Row>
                          <Col md={6}>
                            <h5>Attendance Report</h5>
                         
                          </Col>
                          <Col md={6} style={{ textAlign:'end' }}>
                          
                            
                            
                          </Col>
                          <br/>
                          <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                            <Form className="row" onSubmit={(e)=>{
                              e.preventDefault();
                              let select_employee = [];
                              if(e.target.elements.select_emp_id.length == undefined){
                                select_employee.push(e.target.elements.select_emp_id.value)
                              }
                              for(var i = 0; i < e.target.elements.select_emp_id.length; i++){
                                 select_employee.push(e.target.elements.select_emp_id[i].value)
                              }
                              console.log(e.target.elements.select_location.value) 
                              let empArray = e.target.elements.select_emp_id.value.split('-');
                              let emp_id = empArray[0];
                              
                              console.log(e.target.elements.first_date.value) 
                              console.log(e.target.elements.last_date.value) 
                              var formdata = new FormData();
                              formdata.append("method", "fetch-attendance");
                              formdata.append('other_method','daysbetween');
                              formdata.append('id',select_employee.toString());
                              formdata.append('start_date',e.target.elements.first_date.value);
                              formdata.append('end_date',e.target.elements.last_date.value);
                            
                              var requestOptions = {
                                method: 'POST',
                                body: formdata,
                                redirect: 'follow'
                              };
                             
                              fetch("https://api.frontforcecrm.com/report.php", requestOptions)
                                .then(response =>response.text())
                                .then((result) => {
                                  // setoptionGroup(result)
                                  console.log(result);
                                  document.getElementById('show_data').innerHTML = result;
                          
                                })
                                .catch(error => console.log('error', error));
                            }}>
                            <Col md={6}>
                            <label>Locations</label>
                            <Select
                               id="location-Input"
                                name="select_location"
                                onChange={handleSelectGroup2}
                                options={options2}
                                value={options2.find(
                                  option => option.value === selectedGroupOther
                                )}
                                // isMulti
                                required
                                className="select2-selection"
                              />
                            </Col>
                            <Col md={6}>
                              <label>Employee ID/Name</label>
                              <Select
                                 id="employee-Input"
                                name="select_emp_id"
                                onChange={handleSelectGroup}
                                options={optionGroup}
                                value={optionGroup.find(
                                  option => option.value === selectedGroupOther2
                                )}
                                required
                                isMulti
                                className="select2-selection"
                              />
                            </Col>
                            <Col md={6} style={{marginTop:10}}>
                              <label>Start Date</label>
                              <input type="date" required name="first_date" id="first_date"  className="form-control"/>
                            </Col>
                            <Col md={6} style={{marginTop:10}}>
                              <label>Last Date</label>
                              <input type="date" required name="last_date" id="last_date"  className="form-control"/>
                            </Col>
                            <Col md={6} style={{marginTop:10}}>
                              <button  className="btn btn-success">Search Now</button>
                              </Col>
                              </Form>
                            <Col md={12} id="show_data" style={{marginTop:20}}>
                                 <div className="table-responsive">

                                <table className="table mb-0 table">
                                  <thead>
                                    <tr>

                                    <th>
                                      Location
                                    </th>
                                    <th>
                                      Total Employee
                                    </th>
                                    <th>
                                      Present Employee
                                    </th>
                                    <th>
                                      Absent Employee
                                    </th>
                                    <th>
                                     Actions
                                    </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      firstTimeData.map((e,i)=>{
                                        return(
                                            <tr key={i}>
                                              <td>{e.location}</td>
                                              <td>{e.total}</td>
                                              <td>{e.present}</td>
                                              <td>{e.absent}</td>
                                              <td><Link title="View Info" className="btn btn-info" to={`/view-report/${e.id}`}><i className="mdi mdi-eye"></i></Link></td>
                                            </tr>
                                        );
                                      })
                                    }
                                  </tbody>
                                </table>
                                 </div>
                            </Col>
                            
                        
                      </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div style={{ padding: "15px" }}>
                      <Row>
                          <Col md={12}>
                            <h5>Salary Report</h5>
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                          </Col>
                      </Row>
                      </div>
                    </TabPane>
           
                    
                  </TabContent>
                </Card>
              </div>

             
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(HrmReport)
