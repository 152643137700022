import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import WelcomeComp from "./WelcomeComp"
import AddedJobs from '../DashboardJob/AddedJobs';
import {AddedQuick} from '../DashboardJob/AddedJobs';
import {EmployeeOnLeave} from '../DashboardJob/AddedJobs';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

const Dashboard = props => {
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
    { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    {
      title: "Average Price",
      iconClass: "bx-purchase-tag-alt",
      description: "$16.2",
    },
  ]



  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

 




  //meta title
  document.title = "HR Dashboard"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            
            <Col xl="12">
              <WelcomeComp/>
            </Col>
            
            {/* <Col xl="4">
            <AddedQuick/>
            </Col>
            <Col xl="4">
            <EmployeeOnLeave/>
            </Col> */}
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
