import React, { useState, useEffect } from "react"

import { Link, useNavigate } from "react-router-dom"
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap"

//Import Breadcrumb
import Select from "react-select"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import toastr from "toastr"
import 'toastr/build/toastr.min.css'
const AssignLeave = props => {
  //meta title
  
  document.title = "Assign Leave | HR Dashboard"
  const navigate = useNavigate()
  const [selectedGroupOther, setselectedGroupOther] = useState([])
  const [optionGroup, setoptionGroup] = useState([])
    
  const [employee, setEmployee] = useState([])
  const [count, setCount] = useState(1)
  function handleSelectGroup2(sselectedGroupOther) {
      setselectedGroupOther(sselectedGroupOther.value)
    
  }

  useEffect(() => {
      let auth = JSON.parse(localStorage.getItem('authUser'))
      auth.map((e)=>{
        fetchUser(e.user_id,e.leave_right)
      })
    // fetchUser()
  }, [count])

  const fetchUser = (id,right) => {
    let form = new FormData()
    form.append("method", "fetch-user-for-select")
    form.append("emp_id", id)
    form.append("right", right)
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setoptionGroup(result)
        // setQualificationData(result)
      })
      .catch(error => console.log("error", error))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="" breadcrumbItem="Assign Leave" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Assign Leave
                    <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                  </CardTitle>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      let select_employee = [];
                      for(var i = 0; i < e.target.elements.select_value.length; i++){
                         select_employee.push(e.target.elements.select_value[i].value)
                      }
                      if(select_employee.length === 0){
                        toastr.error('Error','Please employees ID');
                        return false;
                      }
                      else if(e.target.elements.leave_type.value === '' || e.target.elements.leave_type.value === 'null'){
                        toastr.error('Error','Please select leave type ');
                            return false;
                      }
                      else if(e.target.elements.from_date.value === ''){
                         toastr.error('Error','Please select the From date');
                            return false;
                      }
                      else if(e.target.elements.to_date.value === ''){
                         toastr.error('Error','Please select the To date');
                            return false;
                      }
                      else if(e.target.elements.comments.value === ''){
                         toastr.error('Error','Add some comments related to leave');
                            return false;
                      }else{
                        let form = new FormData()
                              form.append("method", "assign-leave");
                              form.append('emp_id',select_employee);

                              form.append('leave_type',e.target.elements.leave_type.value);
                              form.append('from_date',e.target.elements.from_date.value);
                              form.append('to_date',e.target.elements.to_date.value);
                              form.append('comments',e.target.elements.comments.value);
                              var requestOptions = {
                                method: "POST", 
                                body: form,
                                redirect: "follow",
                              }
                              fetch("https://api.frontforcecrm.com/leave.php", requestOptions)
                                .then(response => response.text())
                                .then(result => {
                                  if(result == 'success'){
                                    toastr.success('Success','Leave is successfully assigned to the selected employee');
                                    e.target.reset();
                                    setselectedGroupOther([])
                                  }else{
                                    toastr.error('Error','Not assigned yet, Please try again');
                                  }
                                })
                                .catch(error => console.log("error", error))
                      }
                        
                    //   }
                    //  if(e.target.elements.leave_type.value === '' || e.target.elements.leave_type.value == "null"){
                    //     toastr.error('Error','Please select leave type ');
                    //     return false;
                    //   }
                    } 
                  }
                  >
                    <Row>
                      <Col md={7}>
                        <Row>
                          <Col md={10}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-emp-id-Input">
                                Employee ID
                              </Label>

                              <Select
                               id="formrow-emp-id-Input"
                                name="select_value"
                                onChange={handleSelectGroup2}
                                options={optionGroup}
                                value={optionGroup.find(
                                  option => option.value === selectedGroupOther
                                )}
                                isMulti
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col md={10}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-leave-type-Input">
                                Leave Type
                              </Label>
                              <select id="formrow-leave-type-Input" name="leave_type" className="form-control">
                                <option value="null">--Select--</option>
                                <option value="Matternity">Matternity</option>
                                <option value="Personal">Personal</option>
                                <option value="Casual Leave">Casual Leave</option>
                                <option value="Medical Leave">Medical Leave</option>
                                <option value="Sick Leave">Sick Leave</option>
                                <option value="Half Leave">Half Leave</option>
                                <option value="Emergency Leave">Emergency Leave</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={5}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-from-date-Input">
                                From Date
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                id="formrow-from-date-Input"
                                name="from_date"
                              />
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-to-date-Input">
                                To Date
                              </Label>
                              <Input
                                type="date"
                                className="form-control"
                                id="formrow-to-date-Input"
                                name="to_date"
                              />
                            </div>
                          </Col>
                          <Col md={10}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-comment-Input">
                                Comments
                              </Label>
                              <textarea name="comments" id="formrow-comment-Input" className="form-control"></textarea>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                    <div style={{ textAlign: "end" }}>
                      <span
                        onClick={() => navigate(-1)}
                        
                        className="btn btn-info w-md"
                      >
                        Cancel
                      </span>
                      &nbsp; &nbsp;
                      <button type="submit" className="btn btn-success w-md">
                        Assign Leave
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  )
}

export default AssignLeave
