import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  TabContent,
  TabPane,
  Form,
  NavLink,
} from "reactstrap"
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import { Countries, Currencies, Nationality } from "common/data/nationalities"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
const optionGroup2 = [
  { label: "Single", value: "Single" },
  { label: "Married", value: "Married" },
  { label: "Other", value: "Other" },
]
const optionGroup = [
  { label: "A+", value: "A+" },
  { label: "A-", value: "A-" },
  { label: "B+", value: "B+" },
  { label: "B-", value: "B-" },
  { label: "AB+", value: "AB+" },
  { label: "AB-", value: "AB-" },
  { label: "O+", value: "O+" },
  { label: "O-", value: "O-" },
]
const EditEmployee = props => {
  document.title = "Edit Employee | HR Dashboard"
  const { id } = useParams()
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [selectedGroup, setselectedGroup] = useState("")
  const [selectedGroupOther, setselectedGroupOther] = useState(null)
  const [selectedBlood, setselectedBlood] = useState(null)
  const [selectedCountry, setselectedCountry] = useState(null)
  const [selectedCurrency, setselectedCurrency] = useState(null)
  const [rows1, setrows1] = useState([{ id: 1 }])
  const [toggleSwitchSize, settoggleSwitchSize] = useState(false)
  const [toggleBankDetail, settoggleBankDetail] = useState(false)
  const [edittoggleBankDetail, setedittoggleBankDetail] = useState(false)
  const [assignedSalary, setassignedSalary] = useState(false)
  const [editassignedSalary, setEditassignedSalary] = useState(false)
  const [assignedSalaryAttachment, setassignedSalaryAttachment] =
    useState(false)
  const [jobAttachment, setjobAttachment] = useState(false)
  const [qualificationAttachment, setqualificationAttachment] = useState(false)
  const [skill, setSkill] = useState(false)
  const [languages, setLanguages] = useState(false)
  const [License, setLicense] = useState(false)
  const [workExperience, setWorkExperience] = useState(false)
  const [editworkExperience, setEditWorkExperience] = useState(false)
  const [education, setEducation] = useState(false)
  const [profileImage, setprofileImage] = useState("")
  const [f_name, setFirstName] = useState("")
  const [emp_id, setemployeeID] = useState("")
  const [l_name, setLastName] = useState("")
  const [nickname, setNickname] = useState("")
  const [l_no, setLicenseNo] = useState("")
  const [l_expire, setLicenseExpire] = useState("")
  const [ssn_no, setSNNno] = useState("")
  const [sin_no, setSINNo] = useState("")
  const [dob, setDateofBirth] = useState("")
  const [gender, setGender] = useState("")
  const [smoker, setSmoker] = useState("")
  const [street_1, setStreetNo1] = useState("")
  const [street_2, setStreetNo2] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [zip_code, setZipCode] = useState("")
  const [home_tel, setHomeTel] = useState("")
  const [mobile_tel, setMobileTel] = useState("")
  const [work_tel, setWorkTel] = useState("")
  const [email, setEmail] = useState("")
  const [otherEmail, setOtherEmail] = useState("")
  const [joining_date, setJoiningDate] = useState("")
  const [job_title, setJobTitle] = useState("")
  const [job_specification, setJobSpecification] = useState("")
  const [job_category, setJobCategory] = useState("")
  const [job_subUnit, setJobSubUnit] = useState("")
  const [job_location, setJobLocation] = useState("")
  const [employementStatus, setEmployeementStatus] = useState("")
  const [contractStartDate, setContractStartDate] = useState("")
  const [contractStartEnd, setContractStartEnd] = useState("")
  const [contractAttachments, setContractAttachments] = useState("")
  const [SalaryComponents, setSalaryComponent] = useState([])

  const [terminate_file, setTerminateFile] = useState("")
  const [terminate_comments, setTerminateComments] = useState("")
  const [salarycomponent, setSalaryComponents] = useState("")
  const [pay_grade, setPayGrade] = useState("")
  const [pay_frequency, setPayFrequency] = useState("")
  const [pay_currency, setPayCurrency] = useState("")
  const [pay_amount, setPayAmount] = useState("")
  const [pay_description, setPayDescription] = useState("")
  const [pay_deposit_status, setPayDepositStatus] = useState("")
  const [account_number, setAccountNumber] = useState("")
  const [account_type, setAccountType] = useState("")
  const [routing_number, setRoutingNumber] = useState("")
  const [amount_1, setOtherAmount] = useState("")
  const [EditComponentID, setEditComponentID] = useState("")
  const [salaryAttachmentsData, setSalaryAttachmentsData] = useState([])
  const [QualificationData, setQualificationData] = useState([])
  const [companyName, setCompanyName] = useState("")
  const [jobETitle, setJobETitle] = useState("")
  const [toDate, setToDate] = useState("")
  const [fromDate, setFromDate] = useState("")
  const [experienceComment, setExperienceComment] = useState("")
  const [editIDExperience, seteditIDExperience] = useState("")
  const [editEducationData, seteditEducationData] = useState([])
  const [editEducationView, seteditEducationView] = useState(false)
  const [educationLevel, setEducationLevel] = useState("")
  const [educationInstitute, setEducationInstitute] = useState("")
  const [educationMajor, setEducationMajor] = useState("")
  const [educationstartdate, setStartdate] = useState("")
  const [educationlastdate, setEducationlastDate] = useState("")
  const [educationeditid, setEducationeditID] = useState("")
  const [skilldata, setSkillData] = useState([])
  const [editSkillID, setEditSkillID] = useState("")
  const [editSkillTitle, setEditSkillTitle] = useState("")
  const [editSkillYears, setEditSkillYears] = useState("")
  const [editSkillComments, setEditSkillComments] = useState("")
  const [editSkillView, setEditSkillView] = useState(false)
  const [languageData, setLanguageData] = useState([])
  const [languageEditTitle, setlanguageEditTitle] = useState("")
  const [languageEditFluency, setlanguageEditFluency] = useState("")
  const [languageEditCompetency, setlanguageEditCompetency] = useState("")
  const [languageEditComments, setlanguageEditComments] = useState("")
  const [languageEditID, setlanguageEditID] = useState("")
  const [languageEditView, setlanguageEditView] = useState(false)
  const [licenseData, setlicneseData] = useState([])
  const [licenseEditID, setlicneseEditID] = useState('')
  const [licenseEditTitle, setlicneseEditTitle] = useState('')
  const [licenseEditNo, setlicneseEditNo] = useState('')
  const [licenseEditIssueDate, setlicneseEditIssueDate] = useState('')
  const [licenseEditExpireDate, setlicneseExpireDate] = useState('')
  const [licenseEditView, setlicneseView] = useState('')
  const [qualificationAttachmentData, setqualificationAttachmentData] = useState([])
  React.useEffect(() => {
    fetch_users()
    FetchSalaryComponent()
    FetchAttachmentsComponent()
    FetchQualificationComponent()
    FetchEducationComponent()
    FetchSkillComponent()
    FetchLanguageComponent();
    FetchLicenseComponent();
    FetchQualificationAttachment();
  }, [])
  const fetch_users = () => {
    var formdata = new FormData()
    formdata.append("method", "fetch_employees_by_id")
    formdata.append("post_id", id)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        result.map(e => {
          setemployeeID(e.id)
          setprofileImage(e.profile_image)
          setFirstName(e.first_name)
          setLastName(e.lastname)
          {
            e.personal_info.length  > 1 ? 
            e.personal_info.map(e => {
              setFirstName(e.first_name)
              setLastName(e.lastname)
              setNickname(e.nickname)
              setLicenseNo(e.driver_licence_no)
              setLicenseExpire(e.licence_expire_date)
              setSNNno(e.ssn_no)
              setSINNo(e.sid_no)
              setDateofBirth(e.date_of_birth)
              setGender(e.gender)
              setSmoker(e.smoker)
              setselectedGroup(e.nationality)
              setselectedGroupOther(e.martial_status)
              setselectedBlood(e.blood_type)
  
              setDateofBirth(e.date_of_birth)
              setGender(e.gender)
              setSmoker(e.smoker)
            }):null
          }
          {
            e.contact_info.length > 1  ? 
            e.contact_info.map(f => {
              setStreetNo1(f.street_1)
              setStreetNo2(f.street_2)
              setCity(f.city)
              setState(f.state)
              setZipCode(f.zip_code)
              setHomeTel(f.home_ph_no)
              setMobileTel(f.mobile_ph_no)
              setWorkTel(f.work_ph_no)
              setEmail(f.email_address)
              setOtherEmail(f.other_email)
              setselectedCountry(f.country)
            }):null
          }

          


          {
            e.job_info.length > 1 ?
            e.job_info.map(g => {
              console.log(g)
            setJoiningDate(g.joing_date)
            setJobTitle(g.job_title)
            setJobSpecification(g.job_specification)
            setJobLocation(g.location)
            setJobSubUnit(g.sub_unit)
            setEmployeementStatus(g.employement_status)
            setJobCategory(g.job_category)
            setContractStartDate(g.contract_start_date)
            setContractStartEnd(g.contract_end_date)
            setContractAttachments(g.contract_attachments)
            if (g.contract_status == "true") {
              settoggleSwitchSize(true)
            } else {
              settoggleSwitchSize(true)
            }
            if (g.terminate_status == "true") {
              setjobAttachment(true)
            } else {
              setjobAttachment(false)
            }
            setTerminateComments(g.terminate_message)
            setTerminateFile(g.terminate_attachment)
          }):null
          }
        })
      })
      .catch(error => console.log("error", error))
  }
  const FetchQualificationComponent = () => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("method", "fetch-work-expirence")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setQualificationData(result)
      })
      .catch(error => console.log("error", error))
  }
  const FetchQualificationAttachment = () => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("method", "fetch-qualification-attachments")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setqualificationAttachmentData(result)
      })
      .catch(error => console.log("error", error))
  }
  

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup.value)
  }
  function handleSelectGroup2(selectedGroupOther) {
    setselectedGroupOther(selectedGroupOther.value)
  }
  function handleSelectBlood(selectedBlood) {
    setselectedBlood(selectedBlood.value)
  }
  function handleSelectCountry(selectCountry) {
    setselectedCountry(selectCountry.value)
  }
  function handleSelectCurrency(selectCurrency) {
    setselectedCurrency(selectCurrency.value)
  }
  function handleSelectCurrencyEdit(selectCurrency) {
    setPayCurrency(selectCurrency.value)
  }
  function onChangeRadio1(val) {
    setGender(val.target.value)
  }

  function handleAddRowNested() {
    const modifiedRows = [...rows1]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows1(modifiedRows)
  }

  function handleRemoveRow(id) {
    if (id !== 1) {
      var modifiedRows = [...rows1]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setrows1(modifiedRows)
    }
  }

  const SubmitContactInfo = () => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("street_1", street_1)
    form.append("steet_2", street_1)
    form.append("city", city)
    form.append("state", state)
    form.append("zip_code", zip_code)
    form.append("country", selectedCountry)
    form.append("home_tel", home_tel)
    form.append("mobile_tel", mobile_tel)
    form.append("office_tel", work_tel)
    form.append("email", email)
    form.append("otheremail", otherEmail)
    form.append("method", "update-contact-info-by-id")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }

    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result)
        if (result == "success") {
          toastr.success("Success", "Contact Information is successfully saved")
        } else {
          toastr.error(
            "Success",
            "Error while contact information saved Try again."
          )
        }
      })
      .catch(error => console.log("error", error))
  }
  const SubmitJobDetails = image => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("joining_date", joining_date)
    form.append("job_title", job_title)
    form.append("job_specification", job_specification)
    form.append("job_category", job_category)
    form.append("job_unit", job_subUnit)
    form.append("job_location", job_location)
    form.append("employement_status", employementStatus)
    form.append("contract_start_date", contractStartDate)
    form.append("contract_end_date", contractStartEnd)
    form.append("contract_attachment", image)
    form.append("contract_status", toggleSwitchSize)
    form.append("method", "update_job_info_by")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }

    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result)
        if (result == "success") {
          toastr.success("Success", "Contact Information is successfully saved")
          setassignedSalary(false)
        } else {
          toastr.error(
            "Success",
            "Error while contact information saved Try again."
          )
        }
      })
      .catch(error => console.log("error", error))
  }
  const FetchSalaryComponent = () => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("method", "fetch-salary-components-by-emp-id")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setSalaryComponent(result)
      })
      .catch(error => console.log("error", error))
  }
  const FetchLanguageComponent = () => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("method", "fetch-language")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setLanguageData(result)
      })
      .catch(error => console.log("error", error))
  }
  const FetchSkillComponent = () => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("method", "fetch-skill")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setSkillData(result)
      })
      .catch(error => console.log("error", error))
  }
  const FetchAttachmentsComponent = () => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("method", "fetch-salary-attachments")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setSalaryAttachmentsData(result)
      })
      .catch(error => console.log("error", error))
  }
  const FetchLicenseComponent = () => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("method", "fetch-license")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setlicneseData(result)
      })
      .catch(error => console.log("error", error))
  }
  const FetchEducationComponent = () => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("method", "fetch-education")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        seteditEducationData(result)
      })
      .catch(error => console.log("error", error))
  }
  const FetchSalaryComponentSingle = sid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", sid)
    form.append("method", "fetch-salary-single-by-emp-id")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result.salary_component)
        setEditassignedSalary(true)
        setPayGrade(result.pay_grade)
        setAccountNumber(result.bank_account)
        setAccountType(result.bank_account_type)
        setRoutingNumber(result.bank_routing_no)

        setPayCurrency(result.currency_type)
        setEditComponentID(result.id)
        setPayFrequency(result.pay_frequency)

        setPayAmount(result.salary_amount)
        setOtherAmount(result.amount)
        console.log(result.salary_bank_status)
        if (result.salary_bank_status == "true") {
          setedittoggleBankDetail(true)
        }
        if (result.salary_bank_status == "false") {
          setedittoggleBankDetail(false)
        }

        console.log(edittoggleBankDetail)
        setSalaryComponents(result.salary_component)
        setPayDescription(result.salary_description)
      })
      .catch(error => console.log("error", error))
  }

  const DeleteSalaryComponent = delid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", delid)
    form.append("method", "delete-salary-single-by-emp-id")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result == "success") {
          toastr.success(
            "Success",
            "You are successfully delete the salary component"
          )
          FetchSalaryComponent()
        } else {
          toastr.error("Error", "Error in deleting the salary component")
        }
      })
      .catch(error => console.log("error", error))
  }
  const DeleteAttachmentComponent = delid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", delid)
    form.append("method", "delete-attachment-single-by-emp-id")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result == "success") {
          toastr.success(
            "Success",
            "You are successfully delete the salary component"
          )
          FetchAttachmentsComponent()
        } else {
          toastr.error("Error", "Error in deleting the salary component")
        }
      })
      .catch(error => console.log("error", error))
  }
  const DeleteQualificationAttachmentComponent = delid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", delid)
    form.append("method", "delete-qualification-attachments")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result == "success") {
          toastr.success(
            "Success",
            "You are successfully delete the salary component"
          )
          FetchQualificationAttachment()
        } else {
          toastr.error("Error", "Error in deleting the salary component")
        }
      })
      .catch(error => console.log("error", error))
  }
  const DeleteQualificationComponent = delid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", delid)
    form.append("method", "delete-qualification-single-by-emp-id")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result == "success") {
          toastr.success(
            "Success",
            "You are successfully delete the salary component"
          )
          FetchQualificationComponent()
        } else {
          toastr.error("Error", "Error in deleting the salary component")
        }
      })
      .catch(error => console.log("error", error))
  }
  const DeleteEducationComponent = delid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", delid)
    form.append("method", "delete-education")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result == "success") {
          toastr.success(
            "Success",
            "You are successfully delete the salary component"
          )
          FetchEducationComponent()
        } else {
          toastr.error("Error", "Error in deleting the salary component")
        }
      })
      .catch(error => console.log("error", error))
  }
  const DeleteLanguageComponent = delid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", delid)
    form.append("method", "delete-language")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result == "success") {
          toastr.success(
            "Success",
            "You are successfully delete the salary component"
          )
          FetchLanguageComponent()
        } else {
          toastr.error("Error", "Error in deleting the salary component")
        }
      })
      .catch(error => console.log("error", error))
  }
  const DeleteSkillComponent = delid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", delid)
    form.append("method", "delete-skill")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result == "success") {
          toastr.success(
            "Success",
            "You are successfully delete the salary component"
          )
          FetchSkillComponent()
        } else {
          toastr.error("Error", "Error in deleting the salary component")
        }
      })
      .catch(error => console.log("error", error))
  }
  const DeleteLicenseComponent = delid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", delid)
    form.append("method", "delete-license")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result == "success") {
          toastr.success(
            "Success",
            "You are successfully delete the salary component"
          )
          FetchLicenseComponent()
        } else {
          toastr.error("Error", "Error in deleting the salary component")
        }
      })
      .catch(error => console.log("error", error))
  }
  const updateSalaryComponent = update_id => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", update_id)
    form.append("method", "update-salary-single-by-emp-id")
    form.append("salary_component", salarycomponent)
    form.append("payGrade", pay_grade)
    form.append("payFrequency", pay_frequency)
    form.append("payCurrency", pay_currency)
    form.append("payAmount", pay_amount)
    form.append("payDescription", pay_description)
    form.append("depositStatus", edittoggleBankDetail)
    form.append("account_number", account_number)
    form.append("account_type", account_type)
    form.append("routing_no", routing_number)
    form.append("amount_for", amount_1)
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result == "success") {
          toastr.success(
            "Success",
            "You are successfully update the salary component"
          )
          FetchSalaryComponent()
          setEditassignedSalary(false)
        } else {
          toastr.error("Error", "Error in updating the salary component")
        }
      })
      .catch(error => console.log("error", error))
  }
  const updateQualification = update_id => {
    let form = new FormData()
    form.append("company_name", companyName)
    form.append("job_title", jobETitle)
    form.append("from_date", fromDate)
    form.append("to_date", toDate)
    form.append("comments", experienceComment)
    form.append("emp_id", id)
    form.append("update_id", update_id)
    form.append("method", "update-work-expirence")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result == "success") {
          toastr.success(
            "Success",
            "You are successfully add the qualification"
          )
          FetchQualificationComponent()
          setEditWorkExperience(false)
        } else {
          toastr.error("Error", "Error in adding the qualification")
        }
      })
      .catch(error => console.log("error", error))
  }
  const FetchQualificaitonComponentSingle = sid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", sid)
    form.append("method", "fetch-work-experience-single")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setEditWorkExperience(true)
        setCompanyName(result.company_name)
        setJobETitle(result.job_title)
        setFromDate(result.from_date)
        setToDate(result.to_date)
        setExperienceComment(result.comment)
        seteditIDExperience(result.id)
      })
      .catch(error => console.log("error", error))
  }
  const FetchEducationComponentSingle = sid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", sid)
    form.append("method", "fetch-work-education-single")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        seteditEducationView(true)
        setEducationLevel(result.level)
        setEducationInstitute(result.institute)
        setEducationMajor(result.major_specialization)
        setStartdate(result.education_start_date)
        setEducationlastDate(result.education_end_date)
        setEducationeditID(result.id)
      })
      .catch(error => console.log("error", error))
  }
  const FetchLanguageComponentSingle = sid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", sid)
    form.append("method", "fetch-language-single")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setlanguageEditView(true)
        setlanguageEditID(result.id)
        setlanguageEditTitle(result.title)
        setlanguageEditFluency(result.fluency)
        setlanguageEditCompetency(result.competency)
        setlanguageEditComments(result.comments)
      })
      .catch(error => console.log("error", error))
  }
  const FetchSKillComponentSingle = sid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", sid)
    form.append("method", "fetch-skill-single")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setEditSkillView(true)
        setEditSkillTitle(result.title)
        setEditSkillYears(result.years_no)
        setEditSkillComments(result.comment)

        setEditSkillID(result.id)
      })
      .catch(error => console.log("error", error))
  }
  const FetchLicenseComponentSingle = sid => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("component_id", sid)
    form.append("method", "fetch-license-single")
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setlicneseView(true)
        setlicneseEditID(result.id)
        setlicneseEditTitle(result.licence_type)
        setlicneseEditNo(result.licence_no)
        setlicneseEditIssueDate(result.issue_date)
        setlicneseExpireDate(result.expire_date)
        
      })
      .catch(error => console.log("error", error))
  }
  const UpdateEducation = update_id => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("level", educationLevel)
    form.append("institute", educationInstitute)
    form.append("major_specialization", educationMajor)
    form.append("education_start_date", educationstartdate)
    form.append("education_end_date", educationlastdate)
    form.append("method", "update-education")
    form.append("update_id", update_id)
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result === "success") {
          toastr.success("Success", "You are successfully add the education")
          FetchEducationComponent()
          seteditEducationView(false)
        } else {
          toastr.error("Error", "Error while adding the education. Try Again")
        }
      })
      .catch(error => console.log("error", error))
  }
  const UpdateSkill = update_id => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("skill_title", editSkillTitle)
    form.append("experience", editSkillYears)
    form.append("comments", editSkillComments)
    form.append("method", "update-skill")
    form.append("update_id", update_id)
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result === "success") {
          toastr.success("Success", "You are successfully add the education")
          FetchSkillComponent()
          setEditSkillView(false)
        } else {
          toastr.error("Error", "Error while adding the education. Try Again")
        }
      })
      .catch(error => console.log("error", error))
  }
  const UpdateLanguage = update_id => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append("language", languageEditTitle)
    form.append("fluency", languageEditFluency)
    form.append("competency", languageEditCompetency)
    form.append("comments", languageEditComments)
    form.append("method", "update-language")
    form.append("update_id", update_id)
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result === "success") {
          toastr.success("Success", "You are successfully add the education")
          FetchLanguageComponent()
          setlanguageEditView(false)
        } else {
          toastr.error("Error", "Error while adding the education. Try Again")
        }
      })
      .catch(error => console.log("error", error))
  }
  const UpdateLicense = update_id => {
    let form = new FormData()
    form.append("emp_id", id)
    form.append('type_of',licenseEditTitle)
    form.append('license_no',licenseEditNo)
    form.append('issue_date',licenseEditIssueDate)
    form.append('expire_date',licenseEditExpireDate)
    form.append("method", "update-license")
    form.append("update_id", update_id)
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        if (result === "success") {
          toastr.success("Success", "You are successfully add the license")
          FetchLicenseComponent()
          setlicneseView(false)
        } else {
          toastr.error("Error", "Error while adding the license. Try Again")
        }
      })
      .catch(error => console.log("error", error))
  }

  function formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes"
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024))
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"][d]
    }`
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem="Edit Info" />

          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              <Card className="email-leftbar">
                <Row>
                  <Col md="12" style={{ textAlign: "center" }}>
                    <h3>Admin</h3>
                    <div className="">
                      <center>
                        <img
                          src={profileImage ? profileImage : avatar1}
                          alt=""
                          width={"100"}
                          className="img-thumbnail rounded-circle"
                        />
                      </center>
                    </div>
                  </Col>
                </Row>
                <div className="mail-list mt-4">
                  <Nav pills className="flex-column">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Personal Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleVertical("2")
                        }}
                      >
                        Contact Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleVertical("3")
                        }}
                      >
                        Job Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: verticalActiveTab === "4",
                        })}
                        onClick={() => {
                          toggleVertical("4")
                        }}
                      >
                        Salary
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: verticalActiveTab === "5",
                        })}
                        onClick={() => {
                          toggleVertical("5")
                        }}
                      >
                        Qualification
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Card>

              <div className="email-rightbar mb-3">
                <Card>
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    <TabPane tabId="1">
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          if (f_name == "") {
                            toastr.error("Error", "First Name is required")
                            document
                              .getElementById("formrow-firstname-Input")
                              .focus()
                            return false
                          } else if (l_name == "") {
                            toastr.error("Error", "Last Name is required")
                            document
                              .getElementById("formrow-lastname-Input")
                              .focus()
                            return false
                          } else if (e.target.elements.nickname.value === "") {
                            toastr.error("Error", "Nickname is required")
                            document
                              .getElementById("formrow-nickname-Input")
                              .focus()
                            return false
                          } else if (
                            e.target.elements.license_no.value === ""
                          ) {
                            toastr.error("Error", "License Number is required")
                            document
                              .getElementById("formrow-license-Input")
                              .focus()
                            return false
                          } else if (
                            e.target.elements.license_expire_date.value === ""
                          ) {
                            toastr.error(
                              "Error",
                              "License Exp Date is required"
                            )
                            document
                              .getElementById("formrow-l-expire-Input")
                              .focus()
                            return false
                          } else if (e.target.elements.ssn_no.value === "") {
                            toastr.error("Error", "SSN Number is required")
                            document
                              .getElementById("formrow-snn-no-Input")
                              .focus()
                            return false
                          } else if (e.target.elements.sin_no.value === "") {
                            toastr.error("Error", "SIN Number is required")
                            document
                              .getElementById("formrow-sin-no-Input")
                              .focus()
                            return false
                          } else if (e.target.elements.sin_no.value === "") {
                            toastr.error("Error", "SIN Number is required")
                            document
                              .getElementById("formrow-sin-no-Input")
                              .focus()
                            return false
                          } else if (
                            e.target.elements.date_of_birth.value === ""
                          ) {
                            toastr.error("Error", "Date of Birth is required")
                            document.getElementById("formrow-dob-Input").focus()
                            return false
                          } else if (
                            e.target.elements.exampleRadios.value === ""
                          ) {
                            toastr.error("Error", "Select the gender")
                            return false
                          } else {
                            let form = new FormData()
                            form.append("f_name", f_name)
                            form.append("l_name", l_name)
                            form.append(
                              "nickname",
                              e.target.elements.nickname.value
                            )
                            form.append(
                              "license_no",
                              e.target.elements.license_no.value
                            )
                            form.append(
                              "license_expire",
                              e.target.elements.license_expire_date.value
                            )
                            form.append(
                              "snn_no",
                              e.target.elements.ssn_no.value
                            )
                            form.append(
                              "sin_no",
                              e.target.elements.sin_no.value
                            )
                            form.append("nationality", selectedGroup)
                            form.append("m_status", selectedGroupOther)
                            form.append(
                              "dob",
                              e.target.elements.date_of_birth.value
                            )
                            form.append("blood_type", selectedBlood)
                            form.append(
                              "gender",
                              e.target.elements.exampleRadios.value
                            )
                            form.append(
                              "smoker",
                              e.target.elements.exampleRadiosOther.value
                            )
                            form.append("method", "update_personal_info_by")
                            form.append("emp_id", id)
                            var requestOptions = {
                              method: "POST",
                              body: form,
                              redirect: "follow",
                            }

                            fetch(
                              "https://api.frontforcecrm.com/login.php",
                              requestOptions
                            )
                              .then(response => response.text())
                              .then(result => {
                                console.log(result)
                                if (result == "success") {
                                  toastr.success(
                                    "Success",
                                    "Personal Information is successfully saved."
                                  )
                                } else {
                                  toastr.error("Error", `${result}`)
                                }
                                //   arr.map((e)=>{
                                //     setprofileImage(e.profile_image);
                                //   setFirstName(e.first_name);
                                //   setLastName(e.last_name);
                                //   setemployeeID(e.id);
                                // })
                              })
                              .catch(error => console.log("error", error))
                          }

                          //Nationality
                          // selectedBlood
                          //MartialStatus
                          //selectedGroupOther
                          //BloodType
                          // selectedGroup
                        }}
                      >
                        <div style={{ padding: "15px" }}>
                          <Row>
                            <Col md={12}>
                              <h4>Personal Details</h4>
                              <hr></hr>
                            </Col>
                            <Col md={5}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  First Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Enter Your First Name"
                                  value={f_name}
                                  onChange={e => setFirstName(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={5}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-lastname-Input">
                                  Last Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-lastname-Input"
                                  placeholder="Enter Your Last Name"
                                  value={l_name}
                                  onChange={e => setLastName(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-nickname-Input">
                                  Nickname
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-nickname-Input"
                                  placeholder="Nickname"
                                  name="nickname"
                                  value={nickname}
                                  onChange={e => setNickname(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                          <Row>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Employee Id
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-nickname-Input"
                                  placeholder="Employee ID"
                                  disabled
                                  value={emp_id}
                                  name="employee_id"
                                />
                              </div>
                            </Col>
                          </Row>
                          <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                          <Row>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-license-Input">
                                  Driver's License Number{" "}
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-license-Input"
                                  placeholder="License Number"
                                  name="license_no"
                                  value={l_no}
                                  onChange={e => setLicenseNo(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-l-expire-Input">
                                  License Expiry Date
                                </Label>
                                <Input
                                  type="date"
                                  className="form-control"
                                  id="formrow-l-expire-Input"
                                  name="license_expire_date"
                                  value={l_expire}
                                  onChange={e =>
                                    setLicenseExpire(e.target.value)
                                  }
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-snn-no-Input">
                                  SSN Number
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-ssn-no-Input"
                                  placeholder="SSN Number"
                                  name="ssn_no"
                                  value={ssn_no}
                                  onChange={e => setSNNno(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-sin-no-Input">
                                  SIN Number
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-sin-no-Input"
                                  placeholder="SIN Number"
                                  name="sin_no"
                                  value={sin_no}
                                  onChange={e => setSINNo(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                          <Row>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Nationality
                                </Label>
                                <Select
                                  onChange={handleSelectGroup}
                                  options={Nationality}
                                  className="select2-selection"
                                  value={Nationality.find(
                                    option => option.value === selectedGroup
                                  )}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Marital Status
                                </Label>
                                <Select
                                  onChange={handleSelectGroup2}
                                  options={optionGroup2}
                                  value={optionGroup2.find(
                                    option =>
                                      option.value === selectedGroupOther
                                  )}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-dob-Input">
                                  Date of Birth
                                </Label>
                                <Input
                                  type="date"
                                  className="form-control"
                                  id="formrow-dob-Input"
                                  name="date_of_birth"
                                  value={dob}
                                  onChange={e => setDateofBirth(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Blood Type
                                </Label>
                                <Select
                                  value={optionGroup.find(
                                    option => option.value === selectedBlood
                                  )}
                                  onChange={handleSelectBlood}
                                  options={optionGroup}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                          </Row>
                          <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                          <Row>
                            <Col md={4}>
                              <div className="mb-4">
                                <h5 className="font-size-14 mb-4">Gender</h5>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="Male"
                                    onChange={onChangeRadio1}
                                    // checked={gender  == 'Male' ? true : false}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios1"
                                  >
                                    Male
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios2"
                                    value="Female"
                                    onChange={onChangeRadio1}
                                    // checked={gender  == 'Female' ? true : false}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios2"
                                  >
                                    Female
                                  </label>
                                </div>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-4">
                                <h5 className="font-size-14 mb-4">Smoker?</h5>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadiosOther"
                                    id="exampleRadios4"
                                    value="Yes"
                                    // checked={smoker  == 'Yes' ? true : false}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios4"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadiosOther"
                                    id="exampleRadios3"
                                    value="No"
                                    // checked={smoker  == 'No' ? true : false}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios3"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Form>
                    </TabPane>
                    <TabPane tabId="2">
                      <div style={{ padding: "15px" }}>
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            SubmitContactInfo()
                          }}
                        >
                          <Row>
                            <Col md={12}>
                              <h4>Contact Details</h4>
                              <hr
                                style={{ borderTop: "1px solid #3c3c3c24" }}
                              ></hr>
                            </Col>
                            <Col md={12}>
                              <h5>Address</h5>
                              <hr
                                style={{ borderTop: "1px solid #3c3c3c24" }}
                              ></hr>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Street 1
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Street 1"
                                  value={street_1}
                                  onChange={e => setStreetNo1(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Street 2
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Street 2"
                                  value={street_2}
                                  onChange={e => setStreetNo2(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  City
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="City"
                                  value={city}
                                  onChange={e => setCity(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Province/State
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Province/State"
                                  value={state}
                                  onChange={e => setState(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Zip/Postal Code
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder="Zip/Postal Code"
                                  value={zip_code}
                                  onChange={e => setZipCode(e.target.value)}
                                />
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Country
                                </Label>
                                <Select
                                  value={Countries.find(
                                    options => options.value === selectedCountry
                                  )}
                                  onChange={handleSelectCountry}
                                  options={Countries}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col md={12}>
                              <h5>Telephone</h5>
                              <hr
                                style={{ borderTop: "1px solid #3c3c3c24" }}
                              ></hr>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Home
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder=""
                                  value={home_tel}
                                  onChange={e => setHomeTel(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Mobile
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder=""
                                  value={mobile_tel}
                                  onChange={e => setMobileTel(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Work
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder=""
                                  value={work_tel}
                                  onChange={e => setWorkTel(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={12}>
                              <h5>Email</h5>
                              <hr
                                style={{ borderTop: "1px solid #3c3c3c24" }}
                              ></hr>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Work Email
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder=""
                                  value={email}
                                  onChange={e => setEmail(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Other Email
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder=""
                                  value={otherEmail}
                                  onChange={e => setOtherEmail(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Submit
                            </button>
                          </div>
                        </Form>
                        <Row>
                          <Col md={12} style={{ paddingTop: 30 }}>
                            <h5>Emergency Contact</h5>
                            <Button
                              onClick={() => {
                                handleAddRowNested()
                              }}
                              color="success"
                              className="mt-1"
                            >
                              Add Number
                            </Button>
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                          </Col>
                          <Col md={12}>
                            <Label>Phone Number & Relation</Label>
                            <table style={{ width: "100%" }}>
                              <tbody>
                                {(rows1 || []).map((formRow, key) => (
                                  <tr key={key}>
                                    <td>
                                      <Row className="mb-2">
                                        <Col md="4">
                                          <Input
                                            type="text"
                                            className="inner form-control"
                                            placeholder="Enter your phone no..."
                                          />
                                        </Col>
                                        <Col md="4">
                                          <Input
                                            type="text"
                                            className="inner form-control"
                                            placeholder="Relation"
                                          />
                                        </Col>
                                        <Col md="2">
                                          <Button
                                            color="primary"
                                            className="btn-block inner"
                                            id="unknown-btn"
                                            style={{ width: "100%" }}
                                            onClick={e => {
                                              handleRemoveRow(formRow.id)
                                            }}
                                          >
                                            {" "}
                                            Delete{" "}
                                          </Button>
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div style={{ padding: "15px" }}>
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            const element = document.getElementById(
                              "contract_attachment"
                            )
                            if (element) {
                              if (
                                e.target.elements.contract_attachment.value !=
                                ""
                              ) {
                                SubmitJobDetails(
                                  e.target.elements.contract_attachment.files[0]
                                )
                              } else {
                                SubmitJobDetails("")
                              }
                            } else {
                              SubmitJobDetails("")
                            }
                          }}
                        >
                          <Row>
                            <Col md={12}>
                              <h5>Job Details</h5>
                              <hr
                                style={{ borderTop: "1px solid #3c3c3c24" }}
                              ></hr>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Joining Date
                                </Label>
                                <Input
                                  type="date"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder=""
                                  value={joining_date}
                                  onChange={e => setJoiningDate(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Job Title
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder=""
                                  value={job_title}
                                  onChange={e => setJobTitle(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Job Specification
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder=""
                                  value={job_specification}
                                  onChange={e =>
                                    setJobSpecification(e.target.value)
                                  }
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Job Category
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder=""
                                  value={job_category}
                                  onChange={e => setJobCategory(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Sub Unit
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder=""
                                  value={job_subUnit}
                                  onChange={e => setJobSubUnit(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Location
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder=""
                                  value={job_location}
                                  onChange={e => setJobLocation(e.target.value)}
                                />
                              </div>
                            </Col>

                            <Col md={4}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-firstname-Input">
                                  Employment Status
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="formrow-firstname-Input"
                                  placeholder=""
                                  value={employementStatus}
                                  onChange={e =>
                                    setEmployeementStatus(e.target.value)
                                  }
                                />
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="form-check form-switch mb-3">
                                <label
                                  className="form-check-label"
                                  htmlFor="customSwitchsizesm"
                                >
                                  Include Employment Contract Details
                                </label>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customSwitchsizesm"
                                  onChange={e => {
                                    settoggleSwitchSize(!toggleSwitchSize)
                                  }}
                                />
                              </div>
                            </Col>
                            {toggleSwitchSize == true ? (
                              <Col md={12}>
                                <Row>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Contract Start Date
                                      </Label>
                                      <Input
                                        type="date"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder=""
                                        value={contractStartDate}
                                        onChange={e =>
                                          setContractStartDate(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Contract End Date
                                      </Label>
                                      <Input
                                        type="date"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder=""
                                        value={contractStartEnd}
                                        onChange={e =>
                                          setContractStartEnd(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="contract_attachment">
                                        Attachments?
                                      </Label>
                                      <br></br>
                                      {contractAttachments ? (
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={contractAttachments}
                                        >
                                          File Link
                                        </a>
                                      ) : null}
                                      <Input
                                        type="file"
                                        className="form-control"
                                        id="contract_attachment"
                                        placeholder=""
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            ) : (
                              <Col></Col>
                            )}
                          </Row>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Submit
                            </button>
                          </div>
                        </Form>
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                        <Row>
                          <Col md={12}>
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                            <Row>
                              <Col md={4}>
                                <h5 className="mt-2">Terminate Employee</h5>
                              </Col>
                              <Col md={6}>
                                {jobAttachment == true ? (
                                  <button
                                    type="submit"
                                    className="btn-rounded btn-soft-success waves-effect waves-light btn btn-secondary"
                                    onClick={() => {
                                      alert("")
                                    }}
                                  >
                                    <i className="mdi mdi-plus"></i>
                                    &nbsp;Employee Activiation
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                    onClick={() => {
                                      setjobAttachment(true)
                                    }}
                                  >
                                    <i className="mdi mdi-plus"></i>
                                    &nbsp;Employee Termination
                                  </button>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          {jobAttachment == true ? (
                            <Col md={12}>
                              <Form
                                onSubmit={e => {
                                  e.preventDefault()
                                  if (
                                    e.target.elements.terminate_file.value == ""
                                  ) {
                                    toastr.error("Please attach a file")
                                    return false
                                  } else if (
                                    e.target.elements.terminate_comments
                                      .value == ""
                                  ) {
                                    toastr.error("Add some comments ")
                                    return false
                                  } else {
                                    let form = new FormData()
                                    form.append(
                                      "attachment_terminate",
                                      e.target.elements.terminate_file.files[0]
                                    )
                                    form.append(
                                      "comment_terminate",
                                      terminate_comments
                                    )
                                    form.append("emp_id", id)
                                    form.append(
                                      "terminate_status",
                                      jobAttachment
                                    )
                                    form.append("method", "terminate_employee")
                                    var requestOptions = {
                                      method: "POST",
                                      body: form,
                                      redirect: "follow",
                                    }

                                    fetch(
                                      "https://api.frontforcecrm.com/login.php",
                                      requestOptions
                                    )
                                      .then(response => response.text())
                                      .then(result => {
                                        console.log(result)
                                        if (result == "success") {
                                          toastr.success(
                                            "Success",
                                            "Employee is is successfully terminated"
                                          )
                                        } else {
                                          toastr.error(
                                            "Success",
                                            "Error while employee is not terminated. Try Again!"
                                          )
                                        }
                                      })
                                      .catch(error =>
                                        console.log("error", error)
                                      )
                                  }
                                }}
                              >
                                <Row>
                                  <Col md={12}>
                                    <h5>Add Attachment</h5>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                  </Col>
                                  <Col md={5}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Attachments?
                                      </Label>
                                      <br></br>
                                      {terminate_file ? (
                                        <a
                                          rel="noreferrer"
                                          target="_blank"
                                          href={terminate_file}
                                        >
                                          File Attach
                                        </a>
                                      ) : null}
                                      <Input
                                        type="file"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder=""
                                        name="terminate_file"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={8}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Comment
                                      </Label>
                                      <textarea
                                        name="terminate_comments"
                                        value={terminate_comments}
                                        onChange={e =>
                                          setTerminateComments(e.target.value)
                                        }
                                        className="form-control"
                                      ></textarea>
                                    </div>
                                  </Col>
                                  <Col md={12} style={{ textAlign: "end" }}>
                                    <button
                                      type="submit"
                                      className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                      onClick={() => {
                                        setjobAttachment(false)
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    &nbsp; &nbsp;
                                    <button
                                      type="submit"
                                      className="btn-rounded btn btn-success"
                                    >
                                      Save Changes
                                    </button>
                                  </Col>
                                </Row>
                              </Form>
                            </Col>
                          ) : null}
                        </Row>
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                      </div>
                    </TabPane>

                    <TabPane tabId="4">
                      <div style={{ padding: "15px" }}>
                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col md={3}>
                                <h6 className="mt-2">
                                  Assigned Salary Components
                                </h6>
                              </Col>
                              <Col md={3}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setassignedSalary(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                        {SalaryComponents.length !== 0 ? (
                          <Row>
                            <Col md={12}>
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th scope="col">Salary Component</th>
                                    <th scope="col">Pay Grade</th>
                                    <th scope="col">Pay Frequency</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {SalaryComponents.map((e, index) => {
                                    return (
                                      <tr key={index}>
                                        <th scope="">{e.salary_component}</th>
                                        <td>{e.pay_grade}</td>
                                        <td>{e.pay_frequency}</td>
                                        <td>
                                          {e.salary_amount}
                                        </td>
                                        <td>
                                          <i
                                            onClick={() =>
                                              FetchSalaryComponentSingle(e.id)
                                            }
                                            className="bx bx-edit"
                                          ></i>{" "}
                                          &nbsp;{" "}
                                          <i
                                            onClick={() =>
                                              DeleteSalaryComponent(e.id)
                                            }
                                            className="bx bx-trash"
                                          ></i>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        ) : null}
                        {assignedSalary == true ? (
                          <Form
                            onSubmit={e => {
                              e.preventDefault()
                              if (
                                e.target.elements.salary_component.value == ""
                              ) {
                                toastr.error(
                                  "Error",
                                  "Salary component required"
                                )
                                return false
                              } else if (
                                e.target.elements.pay_grade.value == ""
                              ) {
                                toastr.error("Error", "Pay Grade required")
                                return false
                              } else if (
                                e.target.elements.pay_frequency.value == ""
                              ) {
                                toastr.error("Error", "Pay Frequency required")
                                return false
                              } else if (selectedCurrency == "") {
                                toastr.error(
                                  "Error",
                                  "Select Currency required"
                                )
                                return false
                              } else if (e.target.elements.amount.value == "") {
                                toastr.error("Error", "Amount is required")
                                return false
                              } else if (
                                e.target.elements.description.value == ""
                              ) {
                                toastr.error("Error", "Description is required")
                                return false
                              } else if (toggleBankDetail == true) {
                                if (
                                  e.target.elements.account_number.value == ""
                                ) {
                                  toastr.error(
                                    "Error",
                                    "Account Number is required"
                                  )
                                  return false
                                } else if (
                                  e.target.elements.account_type.value == ""
                                ) {
                                  toastr.error(
                                    "Error",
                                    "Account Type is required"
                                  )
                                  return false
                                } else if (
                                  e.target.elements.routing_number.value == ""
                                ) {
                                  toastr.error(
                                    "Error",
                                    "Routing number is required"
                                  )
                                  return false
                                } else if (
                                  e.target.elements.amount_1.value == ""
                                ) {
                                  toastr.error("Error", "Amount is required")
                                  return false
                                } else {
                                  let form = new FormData()

                                  form.append("emp_id", id)
                                  form.append("method", "add-salary-component")
                                  form.append(
                                    "salary_component",
                                    e.target.elements.salary_component.value
                                  )
                                  form.append(
                                    "pay_grade",
                                    e.target.elements.pay_grade.value
                                  )
                                  form.append(
                                    "pay_frequency",
                                    e.target.elements.pay_frequency.value
                                  )
                                  form.append("currency_name", selectedCurrency)
                                  form.append(
                                    "amount_1",
                                    e.target.elements.amount.value
                                  )
                                  form.append(
                                    "description",
                                    e.target.elements.description.value
                                  )
                                  form.append(
                                    "direct_deposit_status",
                                    toggleBankDetail
                                  )
                                  if (toggleBankDetail == true) {
                                    form.append(
                                      "account_number",
                                      e.target.elements.account_number.value
                                    )
                                    form.append(
                                      "account_type",
                                      e.target.elements.account_type.value
                                    )
                                    form.append(
                                      "routing_number",
                                      e.target.elements.routing_number.value
                                    )
                                    form.append(
                                      "amount_2",
                                      e.target.elements.amount_1.value
                                    )
                                  } else {
                                    form.append("account_number", null)
                                    form.append("account_type", null)

                                    form.append("routing_number", null)
                                    form.append("amount_2", null)
                                  }
                                  alert("asdas")
                                  var requestOptions = {
                                    method: "POST",
                                    body: form,
                                    redirect: "follow",
                                  }

                                  fetch(
                                    "https://api.frontforcecrm.com/login.php",
                                    requestOptions
                                  )
                                    .then(response => response.text())
                                    .then(result => {
                                      console.log(result)
                                      if (result == "success") {
                                        toastr.success(
                                          "Success",
                                          "Contact Information is successfully saved"
                                        )
                                        FetchSalaryComponent()
                                        setassignedSalary(false)
                                        e.target.elements.salary_component.value =
                                          ""
                                        e.target.elements.pay_grade.value = ""
                                        e.target.elements.pay_frequency.value =
                                          ""
                                        e.target.elements.amount.value = ""
                                        e.target.elements.amount_1.value = ""
                                        e.target.elements.routing_number.value =
                                          ""
                                        e.target.elements.account_number.value =
                                          ""
                                        e.target.elements.account_type.value =
                                          ""
                                        setselectedCurrency("")
                                        settoggleBankDetail(false)
                                      } else {
                                        toastr.error(
                                          "Success",
                                          "Error while contact information saved Try again."
                                        )
                                      }
                                    })
                                    .catch(error => console.log("error", error))
                                }
                              } else {
                                let form = new FormData()

                                form.append("emp_id", id)
                                form.append("method", "add-salary-component")
                                form.append(
                                  "salary_component",
                                  e.target.elements.salary_component.value
                                )
                                form.append(
                                  "pay_grade",
                                  e.target.elements.pay_grade.value
                                )
                                form.append(
                                  "pay_frequency",
                                  e.target.elements.pay_frequency.value
                                )
                                form.append("currency_name", selectedCurrency)
                                form.append(
                                  "amount_1",
                                  e.target.elements.amount.value
                                )
                                form.append(
                                  "description",
                                  e.target.elements.description.value
                                )
                                form.append(
                                  "direct_deposit_status",
                                  toggleBankDetail
                                )
                                if (toggleBankDetail == true) {
                                  form.append(
                                    "account_number",
                                    e.target.elements.account_number.value
                                  )
                                  form.append(
                                    "account_type",
                                    e.target.elements.account_type.value
                                  )
                                  form.append(
                                    "routing_number",
                                    e.target.elements.routing_number.value
                                  )
                                  form.append(
                                    "amount_2",
                                    e.target.elements.amount_1.value
                                  )
                                } else {
                                  form.append("account_number", null)
                                  form.append("account_type", null)

                                  form.append("routing_number", null)
                                  form.append("amount_2", null)
                                }
                                alert("asdas")
                                var requestOptions = {
                                  method: "POST",
                                  body: form,
                                  redirect: "follow",
                                }

                                fetch(
                                  "https://api.frontforcecrm.com/login.php",
                                  requestOptions
                                )
                                  .then(response => response.text())
                                  .then(result => {
                                    console.log(result)
                                    if (result == "success") {
                                      toastr.success(
                                        "Success",
                                        "Contact Information is successfully saved"
                                      )
                                      FetchSalaryComponent()
                                      setassignedSalary(false)
                                      e.target.elements.salary_component.value =
                                        ""
                                      e.target.elements.pay_grade.value = ""
                                      e.target.elements.pay_frequency.value = ""
                                      e.target.elements.amount.value = ""
                                      e.target.elements.amount_1.value = ""
                                      e.target.elements.routing_number.value =
                                        ""
                                      e.target.elements.account_number.value =
                                        ""
                                      e.target.elements.account_type.value = ""
                                      setselectedCurrency("")
                                      settoggleBankDetail(false)
                                    } else {
                                      toastr.error(
                                        "Success",
                                        "Error while contact information saved Try again."
                                      )
                                    }
                                  })
                                  .catch(error => console.log("error", error))
                              }
                            }}
                          >
                            <Row>
                              <Col md={12}>
                                <h5>Add Salary Component</h5>
                                <hr
                                  style={{ borderTop: "1px solid #3c3c3c24" }}
                                ></hr>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Salary Component
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-nickname-Input"
                                    placeholder="Salary Component"
                                    name="salary_component"
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Pay Grade
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-nickname-Input"
                                    placeholder="Pay Grade"
                                    name="pay_grade"
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Pay Frequency
                                  </Label>
                                  <select
                                    className="form-control"
                                    name="pay_frequency"
                                  >
                                    <option value="Monthly">Monthly</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Hourly">Hourly</option>
                                  </select>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Select Currency
                                  </Label>
                                  <Select
                                    value={Currencies.find(
                                      options =>
                                        options.value == selectedCurrency
                                    )}
                                    onChange={handleSelectCurrency}
                                    options={Currencies}
                                    className="select2-selection"
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Amount
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-nickname-Input"
                                    placeholder="Amount"
                                    name="amount"
                                  />
                                </div>
                              </Col>
                              <Col md={8}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Description
                                  </Label>
                                  <textarea
                                    name="description"
                                    className="form-control"
                                  ></textarea>
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="form-check form-switch mb-3">
                                  <label
                                    className="form-check-label"
                                    htmlFor="customSwitchsizesm1"
                                  >
                                    Include Direct Deposit Details
                                  </label>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizesm1"
                                    onClick={e => {
                                      settoggleBankDetail(!toggleBankDetail)
                                    }}
                                  />
                                </div>
                              </Col>
                              {toggleBankDetail == true ? (
                                <Col md={12}>
                                  <Row>
                                    <Col md={5}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Account Number
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Account Number"
                                          name="account_number"
                                        />
                                      </div>
                                    </Col>
                                    <Col md={5}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Account Type
                                        </Label>
                                        <select
                                          className="form-control"
                                          name="account_type"
                                        >
                                          <option value="null">
                                            Select Account Type
                                          </option>
                                          <option value="Saving">Saving</option>
                                          <option value="Current">
                                            Current
                                          </option>
                                          <option value="Other">Other</option>
                                        </select>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Routing Number
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Routing Number"
                                          name="routing_number"
                                        />
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Amount
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Amount"
                                          name="amount_1"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              ) : (
                                <Col></Col>
                              )}
                              <Col md={12} style={{ textAlign: "end" }}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setassignedSalary(false)
                                  }}
                                >
                                  Cancel
                                </button>
                                &nbsp; &nbsp;
                                <button
                                  type="submit"
                                  className="btn-rounded btn btn-success"
                                >
                                  Save Changes
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        ) : null}
                        {editassignedSalary == true ? (
                          <Form
                            onSubmit={e => {
                              e.preventDefault()
                              updateSalaryComponent(
                                e.target.elements.edit_id.value
                              )
                            }}
                          >
                            <Row>
                              <Col md={12}>
                                <h5>Edit Salary Component</h5>
                                <hr
                                  style={{ borderTop: "1px solid #3c3c3c24" }}
                                ></hr>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Salary Component
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-nickname-Input"
                                    placeholder="Salary Component"
                                    value={salarycomponent}
                                    onChange={e =>
                                      setSalaryComponents(e.target.value)
                                    }
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Pay Grade
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-nickname-Input"
                                    placeholder="Pay Grade"
                                    name="pay_grade"
                                    value={pay_grade}
                                    onChange={e => setPayGrade(e.target.value)}
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Pay Frequency
                                  </Label>
                                  <select
                                    className="form-control"
                                    name="pay_frequency"
                                    value={pay_frequency}
                                    onChange={e =>
                                      setPayFrequency(e.target.value)
                                    }
                                  >
                                    <option value="Monthly">Monthly</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Hourly">Hourly</option>
                                  </select>
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Select Currency
                                  </Label>
                                  <Select
                                    value={Currencies.find(
                                      options => options.value == pay_currency
                                    )}
                                    onChange={handleSelectCurrencyEdit}
                                    options={Currencies}
                                    className="select2-selection"
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Amount
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-nickname-Input"
                                    placeholder="Amount"
                                    name="amount"
                                    value={pay_amount}
                                    onChange={e => setPayAmount(e.target.value)}
                                  />
                                </div>
                              </Col>
                              <Col md={8}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Description
                                  </Label>
                                  <textarea
                                    name="description"
                                    value={pay_description}
                                    onChange={e =>
                                      setPayDescription(e.target.value)
                                    }
                                    className="form-control"
                                  ></textarea>
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="form-check form-switch mb-3">
                                  <label
                                    className="form-check-label"
                                    htmlFor="customSwitchsizesm1"
                                  >
                                    Include Direct Deposit Details
                                  </label>
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="customSwitchsizesm1"
                                    onClick={e => {
                                      setedittoggleBankDetail(
                                        !edittoggleBankDetail
                                      )
                                    }}
                                  />
                                </div>
                              </Col>
                              {edittoggleBankDetail == true ? (
                                <Col md={12}>
                                  <Row>
                                    <Col md={5}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Account Number
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Account Number"
                                          name="account_number"
                                          value={account_number}
                                          onChange={e =>
                                            setAccountNumber(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col md={5}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Account Type
                                        </Label>
                                        <select
                                          className="form-control"
                                          name="account_type"
                                          value={account_type}
                                          onChange={e =>
                                            setAccountType(e.target.value)
                                          }
                                        >
                                          <option value="null">
                                            Select Account Type
                                          </option>
                                          <option value="Saving">Saving</option>
                                          <option value="Current">
                                            Current
                                          </option>
                                          <option value="Other">Other</option>
                                        </select>
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Routing Number
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Routing Number"
                                          name="routing_number"
                                          value={routing_number}
                                          onChange={e =>
                                            setRoutingNumber(e.target.value)
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Amount
                                        </Label>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Amount"
                                          name="amount_1"
                                          value={amount_1}
                                          onChange={e =>
                                            setOtherAmount(e.target.value)
                                          }
                                        />
                                        <Input
                                          type="hidden"
                                          className="form-control"
                                          id="formrow-firstname-Input"
                                          placeholder="Amount"
                                          name="edit_id"
                                          value={EditComponentID}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              ) : (
                                <Col></Col>
                              )}
                              <Col md={12} style={{ textAlign: "end" }}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setEditassignedSalary(false)
                                  }}
                                >
                                  Cancel
                                </button>
                                &nbsp; &nbsp;
                                <button
                                  type="submit"
                                  className="btn-rounded btn btn-success"
                                >
                                  Save Changes
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        ) : null}
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                        <Row>
                          <Col md={12}>
                            <Row>
                              <Col md={2}>
                                <h6 className="mt-2">Attachments</h6>
                              </Col>
                              <Col md={3}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setassignedSalaryAttachment(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                        {salaryAttachmentsData.length !== 0 ? (
                          <Row>
                            <Col md={12}>
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Size</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {salaryAttachmentsData.map((e, index) => {
                                    return (
                                      <tr key={index}>
                                        <th scope="">{e.name}</th>
                                        <td>{e.size}</td>
                                        <td>{e.type}</td>
                                        <td>
                                          <a
                                            href={e.url}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            <i className="bx bxs-download"></i>
                                          </a>{" "}
                                          &nbsp;{" "}
                                          <i
                                            onClick={() =>
                                              DeleteAttachmentComponent(e.id)
                                            }
                                            className="bx bx-trash"
                                          ></i>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        ) : null}
                        {assignedSalaryAttachment == true ? (
                          <Form
                            onSubmit={e => {
                              e.preventDefault()

                              if (
                                e.target.elements.comments_attachments.value ===
                                ""
                              ) {
                                toastr.error("Error", "Comments are required")
                                return false
                              } else if (
                                e.target.elements.file_attachment.value === ""
                              ) {
                                toastr.error("Error", "File is requried")
                                return false
                              } else {
                                let name =
                                  e.target.elements.file_attachment.files[0]
                                    .name
                                let type =
                                  e.target.elements.file_attachment.files[0]
                                    .type
                                let size = formatBytes(
                                  e.target.elements.file_attachment.files[0]
                                    .size
                                )
                                let form = new FormData()
                                form.append("emp_id", id)
                                form.append("method", "add-salary-attachment")
                                form.append(
                                  "attachments",
                                  e.target.elements.file_attachment.files[0]
                                )
                                form.append(
                                  "comments",
                                  e.target.elements.comments_attachments.value
                                )
                                form.append("type", type)
                                form.append("size", size)
                                form.append("name", name)
                                var requestOptions = {
                                  method: "POST",
                                  body: form,
                                  redirect: "follow",
                                }
                                fetch(
                                  "https://api.frontforcecrm.com/login.php",
                                  requestOptions
                                )
                                  .then(response => response.text())
                                  .then(result => {
                                    if (result == "success") {
                                      toastr.success(
                                        "Success",
                                        "You are successfully update the salary component"
                                      )
                                      FetchAttachmentsComponent()
                                    } else {
                                      toastr.error(
                                        "Error",
                                        "Error in updating the salary component"
                                      )
                                    }
                                  })
                                  .catch(error => console.log("error", error))
                              }
                            }}
                          >
                            <Row>
                              <Col md={12}>
                                <h5>Add Attachments</h5>
                                <hr
                                  style={{ borderTop: "1px solid #3c3c3c24" }}
                                ></hr>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-salary-attachment">
                                    Select File
                                  </Label>
                                  <Input
                                    type="file"
                                    className="form-control"
                                    id="formrow-salary-attachment"
                                    name="file_attachment"
                                  />
                                </div>
                              </Col>
                              <Col md={8}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-salary-attachment-description">
                                    Comments
                                  </Label>
                                  <textarea
                                    name="comments_attachments"
                                    className="form-control"
                                    id="formrow-salary-attachment-description"
                                  ></textarea>
                                </div>
                              </Col>
                              <Col md={12} style={{ textAlign: "end" }}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setassignedSalaryAttachment(false)
                                  }}
                                >
                                  Cancel
                                </button>
                                &nbsp; &nbsp;
                                <button
                                  type="submit"
                                  className="btn-rounded btn btn-success"
                                >
                                  Save Changes
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        ) : null}
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <div style={{ padding: "15px" }}>
                        <Row>
                          <Col md={12}>
                            <h5>Qualification</h5>
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                          </Col>

                          <Col md={12}>
                            <Row>
                              <Col md={3}>
                                <h6 className="mt-2">Work Experience</h6>
                              </Col>
                              <Col md={3}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setWorkExperience(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                              </Col>
                            </Row>
                            {QualificationData.length !== 0 ? (
                              <Row>
                                <Col md={12}>
                                  <table className="table table-hover">
                                    <thead>
                                      <tr>
                                        <th scope="col">Job title</th>
                                        <th scope="col">Company Name</th>

                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {QualificationData.map((e, index) => {
                                        return (
                                          <tr key={index}>
                                            <th scope="">{e.company_name}</th>
                                            <td>{e.job_title}</td>

                                            <td>
                                              <i
                                                onClick={() =>
                                                  FetchQualificaitonComponentSingle(
                                                    e.id
                                                  )
                                                }
                                                className="bx bxs-edit"
                                              ></i>{" "}
                                              &nbsp;{" "}
                                              <i
                                                onClick={() =>
                                                  DeleteQualificationComponent(
                                                    e.id
                                                  )
                                                }
                                                className="bx bx-trash"
                                              ></i>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </Col>
                              </Row>
                            ) : null}
                            {workExperience == true ? (
                              <Form
                                onSubmit={e => {
                                  e.preventDefault()
                                  if (
                                    e.target.elements.company_name.value == ""
                                  ) {
                                    toastr.error(
                                      "Error",
                                      "Company Name is required"
                                    )
                                    return false
                                  } else if (
                                    e.target.elements.job_title.value == ""
                                  ) {
                                    toastr.error(
                                      "Error",
                                      "Job title is required"
                                    )
                                    return false
                                  } else if (
                                    e.target.elements.from_date.value == ""
                                  ) {
                                    toastr.error(
                                      "Error",
                                      "From date is required"
                                    )
                                    return false
                                  } else if (
                                    e.target.elements.to_date.value == ""
                                  ) {
                                    toastr.error("Error", "To date is required")
                                    return false
                                  } else if (
                                    e.target.elements.comments.value == ""
                                  ) {
                                    toastr.error(
                                      "Error",
                                      "Comments is required"
                                    )
                                    return false
                                  } else {
                                    let form = new FormData()
                                    form.append(
                                      "company_name",
                                      e.target.elements.company_name.value
                                    )
                                    form.append(
                                      "job_title",
                                      e.target.elements.job_title.value
                                    )
                                    form.append(
                                      "from_date",
                                      e.target.elements.from_date.value
                                    )
                                    form.append(
                                      "to_date",
                                      e.target.elements.to_date.value
                                    )
                                    form.append(
                                      "comments",
                                      e.target.elements.comments.value
                                    )
                                    form.append("emp_id", id)
                                    form.append("method", "add-work-expirence")
                                    var requestOptions = {
                                      method: "POST",
                                      body: form,
                                      redirect: "follow",
                                    }
                                    fetch(
                                      "https://api.frontforcecrm.com/login.php",
                                      requestOptions
                                    )
                                      .then(response => response.text())
                                      .then(result => {
                                        if (result == "success") {
                                          toastr.success(
                                            "Success",
                                            "You are successfully add the qualification"
                                          )
                                          FetchQualificationComponent()
                                          setWorkExperience(false)
                                        } else {
                                          toastr.error(
                                            "Error",
                                            "Error in adding the qualification"
                                          )
                                        }
                                      })
                                      .catch(error =>
                                        console.log("error", error)
                                      )
                                  }
                                }}
                              >
                                <Row>
                                  <Col md={12}>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                    <h5>Add Work Experience</h5>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                  </Col>
                                  <Col md={12}>
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Company Name
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Company name"
                                            name="company_name"
                                          />
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Job Title
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Job title"
                                            name="job_title"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            From
                                          </Label>
                                          <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            name="from_date"
                                          />
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            To
                                          </Label>
                                          <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            name="to_date"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={8}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Comment
                                          </Label>
                                          <textarea
                                            name="comments"
                                            className="form-control"
                                          ></textarea>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12} style={{ textAlign: "end" }}>
                                        <button
                                          type="submit"
                                          className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                          onClick={() => {
                                            setWorkExperience(false)
                                          }}
                                        >
                                          Cancel
                                        </button>
                                        &nbsp; &nbsp;
                                        <button
                                          type="submit"
                                          className="btn-rounded btn btn-success"
                                        >
                                          Save Changes
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Form>
                            ) : null}
                            {editworkExperience == true ? (
                              <Form
                                onSubmit={e => {
                                  e.preventDefault()
                                  updateQualification(
                                    e.target.elements.edit_for_id.value
                                  )
                                }}
                              >
                                <Row>
                                  <Col md={12}>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                    <h5>Add Work Experience</h5>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                  </Col>
                                  <Col md={12}>
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Company Name
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Company name"
                                            name="company_name"
                                            value={companyName}
                                            onChange={e =>
                                              setCompanyName(e.target.value)
                                            }
                                          />
                                          <Input
                                            type="hidden"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            value={editIDExperience}
                                            name="edit_for_id"
                                          />
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Job Title
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Job title"
                                            name="job_title"
                                            value={jobETitle}
                                            onChange={e =>
                                              setJobETitle(e.target.value)
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            From
                                          </Label>
                                          <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            name="from_date"
                                            value={fromDate}
                                            onChange={e =>
                                              setFromDate(e.target.value)
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            To
                                          </Label>
                                          <Input
                                            type="date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            name="to_date"
                                            value={toDate}
                                            onChange={e =>
                                              setToDate(e.target.value)
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={8}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Comment
                                          </Label>
                                          <textarea
                                            value={experienceComment}
                                            onChange={e =>
                                              setExperienceComment(
                                                e.target.value
                                              )
                                            }
                                            name="comments"
                                            className="form-control"
                                          ></textarea>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12} style={{ textAlign: "end" }}>
                                        <button
                                          type="submit"
                                          className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                          onClick={() => {
                                            setEditWorkExperience(false)
                                          }}
                                        >
                                          Cancel
                                        </button>
                                        &nbsp; &nbsp;
                                        <button
                                          type="submit"
                                          className="btn-rounded btn btn-success"
                                        >
                                          Save Changes
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Form>
                            ) : null}
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                            <Row>
                              <Col md={3}>
                                <h6 className="mt-2">Education</h6>
                              </Col>
                              <Col md={3}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setEducation(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                              </Col>
                            </Row>
                            {editEducationData.length !== 0 ? (
                              <Row>
                                <Col md={12}>
                                  <table className="table table-hover">
                                    <thead>
                                      <tr>
                                        <th scope="col">Level</th>
                                        <th scope="col">Institute</th>
                                        <th scope="col">
                                          Major/Specialization
                                        </th>
                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {editEducationData.map((e, index) => {
                                        return (
                                          <tr key={index}>
                                            <th scope="">{e.level}</th>
                                            <td>{e.institute}</td>
                                            <td>{e.major_specialization}</td>
                                            <td>
                                              <i
                                                onClick={() =>
                                                  FetchEducationComponentSingle(
                                                    e.id
                                                  )
                                                }
                                                className="bx bxs-edit"
                                              ></i>{" "}
                                              &nbsp;{" "}
                                              <i
                                                onClick={() =>
                                                  DeleteEducationComponent(e.id)
                                                }
                                                className="bx bx-trash"
                                              ></i>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </Col>
                              </Row>
                            ) : null}
                            {education == true ? (
                              <Form
                                onSubmit={e => {
                                  e.preventDefault()
                                  if (e.target.elements.level.value == "") {
                                    toastr.error(
                                      "Error",
                                      "Select Education Level"
                                    )
                                    return false
                                  } else if (
                                    e.target.elements.institute.value == ""
                                  ) {
                                    toastr.error(
                                      "Error",
                                      "Education Institute is required"
                                    )
                                    return false
                                  } else if (
                                    e.target.elements.major.value == ""
                                  ) {
                                    toastr.error(
                                      "Error",
                                      "Major/Specialization is required"
                                    )
                                    return false
                                  } else if (
                                    e.target.elements.start_date.value == ""
                                  ) {
                                    toastr.error(
                                      "Error",
                                      "Select the Start date"
                                    )
                                    return false
                                  } else if (
                                    e.target.elements.end_date.value == ""
                                  ) {
                                    toastr.error("Error", "Select the End date")
                                    return false
                                  } else {
                                    let form = new FormData()
                                    form.append("emp_id", id)
                                    form.append(
                                      "level",
                                      e.target.elements.level.value
                                    )
                                    form.append(
                                      "institute",
                                      e.target.elements.institute.value
                                    )
                                    form.append(
                                      "major_specialization",
                                      e.target.elements.major.value
                                    )
                                    form.append(
                                      "education_start_date",
                                      e.target.elements.start_date.value
                                    )
                                    form.append(
                                      "education_end_date",
                                      e.target.elements.end_date.value
                                    )
                                    form.append("method", "add-education")
                                    var requestOptions = {
                                      method: "POST",
                                      body: form,
                                      redirect: "follow",
                                    }
                                    fetch(
                                      "https://api.frontforcecrm.com/login.php",
                                      requestOptions
                                    )
                                      .then(response => response.text())
                                      .then(result => {
                                        if (result === "success") {
                                          toastr.success(
                                            "Success",
                                            "You are successfully add the education"
                                          )
                                          FetchEducationComponent()
                                          setEducation(false)
                                        } else {
                                          toastr.error(
                                            "Error",
                                            "Error while adding the education. Try Again"
                                          )
                                        }
                                      })
                                      .catch(error =>
                                        console.log("error", error)
                                      )
                                  }
                                }}
                              >
                                <Row>
                                  <Col md={12}>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                    <h5>Add Education</h5>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                  </Col>
                                  <Col md={12}>
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Level
                                          </Label>
                                          <select
                                            className="form-control"
                                            name="level"
                                          >
                                            <option value="null">
                                              --Select--
                                            </option>
                                            <option value="Bachelor's Degree">
                                              Bachelor's Degree
                                            </option>
                                            <option value="College Undergraduate">
                                              College Undergraduate
                                            </option>
                                            <option value="High School Diploma">
                                              High School Diploma
                                            </option>
                                            <option value="Master's Degree">
                                              Master's Degree
                                            </option>
                                          </select>
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Institute
                                          </Label>
                                          <Input
                                            type="text"
                                            name="institute"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Institute"
                                          />
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Major/Specialization
                                          </Label>
                                          <Input
                                            type="text"
                                            name="major"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Major/Specialization"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Start Date
                                          </Label>
                                          <Input
                                            type="date"
                                            name="start_date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Institute"
                                          />
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            End Date
                                          </Label>
                                          <Input
                                            type="date"
                                            name="end_date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Major/Specialization"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12} style={{ textAlign: "end" }}>
                                        <button
                                          type="submit"
                                          className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                          onClick={() => {
                                            setEducation(false)
                                          }}
                                        >
                                          Cancel
                                        </button>
                                        &nbsp; &nbsp;
                                        <button
                                          type="submit"
                                          className="btn-rounded btn btn-success"
                                        >
                                          Save Changes
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Form>
                            ) : null}
                            {editEducationView == true ? (
                              <Form
                                onSubmit={e => {
                                  e.preventDefault()
                                  UpdateEducation(
                                    e.target.elements.edit_education_id.value
                                  )
                                }}
                              >
                                <Row>
                                  <Col md={12}>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                    <h5>Add Education</h5>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                  </Col>
                                  <Col md={12}>
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Level
                                          </Label>
                                          <select
                                            className="form-control"
                                            value={educationLevel}
                                            onChange={e =>
                                              setEducationLevel(e.target.value)
                                            }
                                            name="level"
                                          >
                                            <option value="null">
                                              --Select--
                                            </option>
                                            <option value="Bachelor's Degree">
                                              Bachelor's Degree
                                            </option>
                                            <option value="College Undergraduate">
                                              College Undergraduate
                                            </option>
                                            <option value="High School Diploma">
                                              High School Diploma
                                            </option>
                                            <option value="Master's Degree">
                                              Master's Degree
                                            </option>
                                          </select>
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Institute
                                          </Label>
                                          <Input
                                            type="text"
                                            name="institute"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Institute"
                                            value={educationInstitute}
                                            onChange={e =>
                                              setEducationInstitute(
                                                e.target.value
                                              )
                                            }
                                          />
                                          <Input
                                            type="hidden"
                                            name="edit_education_id"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            value={educationeditid}
                                          />
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Major/Specialization
                                          </Label>
                                          <Input
                                            type="text"
                                            name="major"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            placeholder="Major/Specialization"
                                            value={educationMajor}
                                            onChange={e =>
                                              setEducationMajor(e.target.value)
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Start Date
                                          </Label>
                                          <Input
                                            type="date"
                                            name="start_date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            value={educationstartdate}
                                            onChange={e =>
                                              setStartdate(e.target.value)
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col md={4}>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-firstname-Input">
                                            End Date
                                          </Label>
                                          <Input
                                            type="date"
                                            name="end_date"
                                            className="form-control"
                                            id="formrow-firstname-Input"
                                            value={educationlastdate}
                                            onChange={e =>
                                              setEducationlastDate(
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12} style={{ textAlign: "end" }}>
                                        <button
                                          type="submit"
                                          className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                          onClick={() => {
                                            seteditEducationView(false)
                                          }}
                                        >
                                          Cancel
                                        </button>
                                        &nbsp; &nbsp;
                                        <button
                                          type="submit"
                                          className="btn-rounded btn btn-success"
                                        >
                                          Save Changes
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Form>
                            ) : null}
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                            <Row>
                              <Col md={3}>
                                <h6 className="mt-2">Skill</h6>
                              </Col>
                              <Col md={3}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setSkill(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                              </Col>
                            </Row>
                            {skilldata.length !== 0 ? (
                              <Row>
                                <Col md={12}>
                                  <table className="table table-hover">
                                    <thead>
                                      <tr>
                                        <th scope="col">Title</th>
                                        <th scope="col">Experience</th>

                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {skilldata.map((e, index) => {
                                        return (
                                          <tr key={index}>
                                            <th scope="">{e.title}</th>
                                            <td>{e.years_no}</td>

                                            <td>
                                              <i
                                                onClick={() =>
                                                  FetchSKillComponentSingle(
                                                    e.id
                                                  )
                                                }
                                                className="bx bxs-edit"
                                              ></i>{" "}
                                              &nbsp;{" "}
                                              <i
                                                onClick={() =>
                                                  DeleteSkillComponent(e.id)
                                                }
                                                className="bx bx-trash"
                                              ></i>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </Col>
                              </Row>
                            ) : null}
                            {skill == true ? (
                              <Form
                                onSubmit={e => {
                                  e.preventDefault()
                                  if (
                                    e.target.elements.skill_title.value == ""
                                  ) {
                                    toastr.error(
                                      "Error",
                                      "Skill title is required"
                                    )
                                    return false
                                  } else if (
                                    e.target.elements.year_of_exp.value == ""
                                  ) {
                                    toastr.error(
                                      "Error",
                                      "Years of Experience is required"
                                    )
                                    return false
                                  } else if (
                                    e.target.elements.comments.value == ""
                                  ) {
                                    toastr.error(
                                      "Error",
                                      "Comments is required"
                                    )
                                    return false
                                  } else {
                                    let form = new FormData()
                                    form.append(
                                      "skill_title",
                                      e.target.elements.skill_title.value
                                    )
                                    form.append(
                                      "experience",
                                      e.target.elements.year_of_exp.value
                                    )
                                    form.append(
                                      "comments",
                                      e.target.elements.comments.value
                                    )
                                    form.append("method", "add-skill")
                                    form.append("emp_id", id)
                                    var requestOptions = {
                                      method: "POST",
                                      body: form,
                                      redirect: "follow",
                                    }
                                    fetch(
                                      "https://api.frontforcecrm.com/login.php",
                                      requestOptions
                                    )
                                      .then(response => response.text())
                                      .then(result => {
                                        if (result === "success") {
                                          toastr.success(
                                            "Success",
                                            "You are successfully add the education"
                                          )
                                          FetchSkillComponent()
                                          setSkill(false)
                                        } else {
                                          toastr.error(
                                            "Error",
                                            "Error while adding the education. Try Again"
                                          )
                                        }
                                      })
                                      .catch(error =>
                                        console.log("error", error)
                                      )
                                  }
                                }}
                              >
                                <Row>
                                  <Col md={12}>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                    <h5>Add Skill</h5>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Skill Title
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Skill title"
                                        name="skill_title"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Years of Experience
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Year of experience"
                                        name="year_of_exp"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={8}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Comments
                                      </Label>
                                      <textarea
                                        name="comments"
                                        className="form-control"
                                      ></textarea>
                                    </div>
                                  </Col>
                                  <Col md={12} style={{ textAlign: "end" }}>
                                    <button
                                      type="submit"
                                      className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                      onClick={() => {
                                        setSkill(false)
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    &nbsp; &nbsp;
                                    <button
                                      type="submit"
                                      className="btn-rounded btn btn-success"
                                    >
                                      Save Changes
                                    </button>
                                  </Col>
                                </Row>
                              </Form>
                            ) : null}
                            {editSkillView == true ? (
                              <Form
                                onSubmit={e => {
                                  e.preventDefault()
                                  UpdateSkill(
                                    e.target.elements.edit_for_skill.value
                                  )
                                }}
                              >
                                <Row>
                                  <Col md={12}>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                    <h5>Add Skill</h5>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Skill Title
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Skill title"
                                        name="skill_title"
                                        value={editSkillTitle}
                                        onChange={e =>
                                          setEditSkillTitle(e.target.value)
                                        }
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Years of Experience
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Year of experience"
                                        name="year_of_exp"
                                        value={editSkillYears}
                                        onChange={e =>
                                          setEditSkillYears(e.target.value)
                                        }
                                      />
                                      <Input
                                        type="hidden"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        name="edit_for_skill"
                                        value={editSkillID}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={8}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Comments
                                      </Label>
                                      <textarea
                                        value={editSkillComments}
                                        onChange={e =>
                                          setEditSkillComments(e.target.value)
                                        }
                                        name="comments"
                                        className="form-control"
                                      ></textarea>
                                    </div>
                                  </Col>
                                  <Col md={12} style={{ textAlign: "end" }}>
                                    <button
                                      type="submit"
                                      className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                      onClick={() => {
                                        setEditSkillView(false)
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    &nbsp; &nbsp;
                                    <button
                                      type="submit"
                                      className="btn-rounded btn btn-success"
                                    >
                                      Save Changes
                                    </button>
                                  </Col>
                                </Row>
                              </Form>
                            ) : null}
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                            <Row>
                              <Col md={3}>
                                <h6 className="mt-2">Languages</h6>
                              </Col>
                              <Col md={3}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setLanguages(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                              </Col>
                            </Row>
                            {languageData.length !== 0 ? (
                              <Row>
                                <Col md={12}>
                                  <table className="table table-hover">
                                    <thead>
                                      <tr>
                                        <th scope="col">Language</th>
                                        <th scope="col">Fluency</th>
                                        <th scope="col">Competency</th>
                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {languageData.map((e, index) => {
                                        return (
                                          <tr key={index}>
                                            <th scope="">{e.title}</th>
                                            <td>{e.fluency}</td>
                                            <td>{e.competency}</td>
                                            <td>
                                              <i
                                                onClick={() =>
                                                  FetchLanguageComponentSingle(
                                                    e.id
                                                  )
                                                }
                                                className="bx bxs-edit"
                                              ></i>{" "}
                                              &nbsp;{" "}
                                              <i
                                                onClick={() =>
                                                  DeleteLanguageComponent(e.id)
                                                }
                                                className="bx bx-trash"
                                              ></i>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </Col>
                              </Row>
                            ) : null}
                            {languages == true ? (
                              <Form
                                onSubmit={e => {
                                  e.preventDefault()
                                  if (e.target.elements.language.value == "") {
                                    toastr.error("Language is requried")
                                    return false
                                  } else if (
                                    e.target.elements.fluency.value == "" ||
                                    e.target.elements.fluency.value == "null"
                                  ) {
                                    toastr.error("Fluency is requried")
                                    return false
                                  } else if (
                                    e.target.elements.competency.value == "" ||
                                    e.target.elements.competency.value == "null"
                                  ) {
                                    toastr.error("Competency is requried")
                                    return false
                                  } else if (
                                    e.target.elements.comments.value == ""
                                  ) {
                                    toastr.error("Comments is requried")
                                    return false
                                  } else {
                                    let form = new FormData()
                                    form.append("emp_id", id)
                                    form.append(
                                      "language",
                                      e.target.elements.language.value
                                    )
                                    form.append(
                                      "fluency",
                                      e.target.elements.fluency.value
                                    )
                                    form.append(
                                      "competency",
                                      e.target.elements.competency.value
                                    )
                                    form.append(
                                      "comments",
                                      e.target.elements.comments.value
                                    )
                                    form.append("method", "add-language")
                                    var requestOptions = {
                                      method: "POST",
                                      body: form,
                                      redirect: "follow",
                                    }
                                    fetch(
                                      "https://api.frontforcecrm.com/login.php",
                                      requestOptions
                                    )
                                      .then(response => response.text())
                                      .then(result => {
                                        if (result == "success") {
                                          toastr.success(
                                            "Success",
                                            "You are successfully add the language"
                                          )
                                          setLanguages(false)
                                        } else {
                                          toastr.error(
                                            "Error",
                                            "Error in adding the language. Try Again"
                                          )
                                        }
                                      })
                                      .catch(error =>
                                        console.log("error", error)
                                      )
                                  }
                                }}
                              >
                                <Row>
                                  <Col md={12}>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                    <h5>Add Language</h5>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Language
                                      </Label>
                                      <Input
                                        type="text"
                                        name="language"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Language"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Fluency
                                      </Label>
                                      <select
                                        className="form-control"
                                        name="fluency"
                                      >
                                        <option value="null">--Select--</option>
                                        <option value="Writing">Writing</option>
                                        <option value="Reading">Reading</option>
                                        <option value="Speaking">
                                          Speaking
                                        </option>
                                      </select>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Competency
                                      </Label>
                                      <select
                                        className="form-control"
                                        name="competency"
                                      >
                                        <option value="null">--Select--</option>
                                        <option value="Poor">Poor</option>
                                        <option value="Basic">Basic</option>
                                        <option value="Good">Good</option>
                                        <option value="Mother Tongue">
                                          Mother Tongue
                                        </option>
                                      </select>
                                    </div>
                                  </Col>
                                  <Col md={8}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Comments
                                      </Label>
                                      <textarea
                                        name="comments"
                                        className="form-control"
                                      ></textarea>
                                    </div>
                                  </Col>
                                  <Col md={12} style={{ textAlign: "end" }}>
                                    <button
                                      type="submit"
                                      className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                      onClick={() => {
                                        setLanguages(false)
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    &nbsp; &nbsp;
                                    <button
                                      type="submit"
                                      className="btn-rounded btn btn-success"
                                    >
                                      Save Changes
                                    </button>
                                  </Col>
                                </Row>
                              </Form>
                            ) : null}
                            {languageEditView == true ? (
                              <Form
                                onSubmit={e => {
                                  e.preventDefault()
                                  UpdateLanguage(
                                    e.target.elements.edit_id_for.value
                                  )
                                }}
                              >
                                <Row>
                                  <Col md={12}>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                    <h5>Edit Language</h5>
                                    <hr
                                      style={{
                                        borderTop: "1px solid #3c3c3c24",
                                      }}
                                    ></hr>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Language
                                      </Label>
                                      <Input
                                        type="text"
                                        name="language"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        placeholder="Language"
                                        value={languageEditTitle}
                                        onChange={e =>
                                          setlanguageEditTitle(e.target.value)
                                        }
                                      />
                                      <Input
                                        type="hidden"
                                        name="edit_id_for"
                                        className="form-control"
                                        id="formrow-firstname-Input"
                                        value={languageEditID}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Fluency
                                      </Label>
                                      <select
                                        className="form-control"
                                        value={languageEditFluency}
                                        onChange={e =>
                                          setlanguageEditFluency(e.target.value)
                                        }
                                        name="fluency"
                                      >
                                        <option value="null">--Select--</option>
                                        <option value="Writing">Writing</option>
                                        <option value="Reading">Reading</option>
                                        <option value="Speaking">
                                          Speaking
                                        </option>
                                      </select>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Competency
                                      </Label>
                                      <select
                                        className="form-control"
                                        value={languageEditCompetency}
                                        onChange={e =>
                                          setlanguageEditCompetency(
                                            e.target.value
                                          )
                                        }
                                        name="competency"
                                      >
                                        <option value="null">--Select--</option>
                                        <option value="Poor">Poor</option>
                                        <option value="Basic">Basic</option>
                                        <option value="Good">Good</option>
                                        <option value="Mother Tongue">
                                          Mother Tongue
                                        </option>
                                      </select>
                                    </div>
                                  </Col>
                                  <Col md={8}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Comments
                                      </Label>
                                      <textarea
                                        value={languageEditComments}
                                        onChange={e =>
                                          setlanguageEditComments(
                                            e.target.value
                                          )
                                        }
                                        name="comments"
                                        className="form-control"
                                      ></textarea>
                                    </div>
                                  </Col>
                                  <Col md={12} style={{ textAlign: "end" }}>
                                    <button
                                      type="submit"
                                      className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                      onClick={() => {
                                        setlanguageEditView(false)
                                      }}
                                    >
                                      Cancel
                                    </button>
                                    &nbsp; &nbsp;
                                    <button
                                      type="submit"
                                      className="btn-rounded btn btn-success"
                                    >
                                      Save Changes
                                    </button>
                                  </Col>
                                </Row>
                              </Form>
                            ) : null}
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                            <Row>
                              <Col md={3}>
                                <h6 className="mt-2">License</h6>
                              </Col>
                              <Col md={3}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setLicense(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                              </Col>
                            </Row>
                            {licenseData.length !== 0 ? (
                              <Row>
                                <Col md={12}>
                                  <table className="table table-hover">
                                    <thead>
                                      <tr>
                                        <th scope="col">License Type</th>
                                        <th scope="col">License Number</th>

                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {licenseData.map((e, index) => {
                                        return (
                                          <tr key={index}>
                                            <th scope="">{e.licence_type}</th>
                                            <td>{e.licence_no}</td>

                                            <td>
                                              <i
                                                onClick={() =>
                                                  FetchLicenseComponentSingle(
                                                    e.id
                                                  )
                                                }
                                                className="bx bxs-edit"
                                              ></i>{" "}
                                              &nbsp;{" "}
                                              <i
                                                onClick={() =>
                                                  DeleteLicenseComponent(
                                                    e.id
                                                  )
                                                }
                                                className="bx bx-trash"
                                              ></i>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </Col>
                              </Row>
                            ) : null}
                            {License == true ? (
                             <Form
                             onSubmit={(e)=>{
                              e.preventDefault();
                              if(e.target.elements.type_of_license.value == ""){
                                toastr.error('Error','License type is required');
                                return false;
                              }else if(e.target.elements.license_no.value == ""){
                                toastr.error('Error','License Number is required');
                                return false;
                              }else if(e.target.elements.issue_date.value == ""){
                                toastr.error('Error','Issue Date is required');
                                return false;
                              }else if(e.target.elements.expire_date.value == ""){
                                toastr.error('Error','Expire Date is required');
                                return false;
                              }else{
                                let form = new FormData();
                                form.append('emp_id',id);
                                form.append('type_of',e.target.elements.type_of_license.value)
                                form.append('license_no',e.target.elements.license_no.value)
                                form.append('issue_date',e.target.elements.issue_date.value)
                                form.append('expire_date',e.target.elements.expire_date.value)
                                form.append('method','add-license');
                                var requestOptions = {
                                  method: "POST",
                                  body: form,
                                  redirect: "follow",
                                }
                                fetch("https://api.frontforcecrm.com/login.php", requestOptions)
                                  .then(response => response.text())
                                  .then(result => {
                                    if (result == "success") {
                                      toastr.success(
                                        "Success",
                                        "You are successfully add the license"
                                      )
                                      FetchLicenseComponent()
                                      setLicense(false)
                                    } else {
                                      toastr.error("Error", "Error in adding the license")
                                    }
                                  })
                                  .catch(error => console.log("error", error))

                              }
                             }}
                             >
                               <Row>
                                <Col md={12}>
                                  <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                  ></hr>
                                  <h5>Add License</h5>
                                  <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                  ></hr>
                                </Col>
                                <Col md={5}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Type of License
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="Enter Type here"
                                      name="type_of_license"
                                    />
                                  </div>
                                </Col>
                                <Col md={5}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      License Number
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="License Number"
                                      name="license_no"
                                    />
                                  </div>
                                </Col>

                                <Col md={4}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Issued Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      name="issue_date"
                                    />
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Expiry Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      name="expire_date"
                                    />
                                  </div>
                                </Col>
                                <Col md={12} style={{ textAlign: "end" }}>
                                  <button
                                    type="submit"
                                    className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                    onClick={() => {
                                      setLicense(false)
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  &nbsp; &nbsp;
                                  <button
                                    type="submit"
                                    className="btn-rounded btn btn-success"
                                  >
                                    Save Changes
                                  </button>
                                </Col>
                              </Row>
                             </Form>
                            ) : null}
                            {licenseEditView == true ? (
                             <Form
                             onSubmit={(e)=>{
                              e.preventDefault();
                              UpdateLicense(e.target.elements.edit_id_for.value)
                             }}
                             >
                               <Row>
                                <Col md={12}>
                                  <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                  ></hr>
                                  <h5>Edit License</h5>
                                  <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                  ></hr>
                                </Col>
                                <Col md={5}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Type of License
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="Enter Type here"
                                      name="type_of_license"
                                      value={licenseEditTitle}
                                      onChange={(e)=>setlicneseEditTitle(e.target.value)}
                                    />
                                    <Input
                                      type="hidden"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="Enter Type here"
                                      name="edit_id_for"
                                      value={licenseEditID}
                                     
                                    />
                                  </div>
                                </Col>
                                <Col md={5}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      License Number
                                    </Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      placeholder="License Number"
                                      value={licenseEditNo}
                                      onChange={(e)=>setlicneseEditNo(e.target.value)}
                                    />
                                  </div>
                                </Col>

                                <Col md={4}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Issued Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      value={licenseEditIssueDate}
                                      onChange={(e)=>setlicneseEditIssueDate(e.target.value)}
                                    />
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Expiry Date
                                    </Label>
                                    <Input
                                      type="date"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      value={licenseEditExpireDate}
                                      onChange={(e)=>setlicneseExpireDate(e.target.value)}
                                    />
                                  </div>
                                </Col>
                                <Col md={12} style={{ textAlign: "end" }}>
                                  <button
                                    type="submit"
                                    className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                    onClick={() => {
                                      setlicneseView(false)
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  &nbsp; &nbsp;
                                  <button
                                    type="submit"
                                    className="btn-rounded btn btn-success"
                                  >
                                    Save Changes
                                  </button>
                                </Col>
                              </Row>
                             </Form>
                            ) : null}
                            <hr
                              style={{ borderTop: "1px solid #3c3c3c24" }}
                            ></hr>
                            <Row>
                              <Col md={3}>
                                <h6 className="mt-2">Attachments</h6>
                              </Col>
                              <Col md={3}>
                                <button
                                  type="submit"
                                  className="btn-rounded btn-soft-info waves-effect waves-light btn btn-secondary"
                                  onClick={() => {
                                    setqualificationAttachment(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus"></i>&nbsp;Add
                                </button>
                              </Col>
                            </Row>
                            {qualificationAttachmentData.length !== 0 ? (
                          <Row>
                            <Col md={12}>
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Size</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {qualificationAttachmentData.map((e, index) => {
                                    return (
                                      <tr key={index}>
                                        <th scope="">{e.name}</th>
                                        <td>{e.size}</td>
                                        <td>{e.type}</td>
                                        <td>
                                          <a
                                            href={e.url}
                                            rel="noreferrer"
                                            target="_blank"
                                          >
                                            <i className="bx bxs-download"></i>
                                          </a>{" "}
                                          &nbsp;{" "}
                                          <i
                                            onClick={() =>
                                              DeleteAttachmentComponent(e.id)
                                            }
                                            className="bx bx-trash"
                                          ></i>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                        ) : null}
                            {qualificationAttachment == true ? (
                              <Form 
                              onSubmit={(e)=>{
                                e.preventDefault();
                                if(e.target.elements.file_attach.value == ""){
                                  toastr.error('Error','Please select a file');
                                  return false;
                                }else if(e.target.elements.comments.value == ""){
                                  toastr.error('Error','Comments are required');
                                  return false;
                                }else{
                                  let name =
                                  e.target.elements.file_attach.files[0]
                                    .name
                                let type =
                                  e.target.elements.file_attach.files[0]
                                    .type
                                let size = formatBytes(
                                  e.target.elements.file_attach.files[0]
                                    .size
                                )
                                  let form = new FormData();
                                  form.append("emp_id", id)
                                form.append("method", "add-qualification-attachment")
                                form.append(
                                  "attachments",
                                  e.target.elements.file_attach.files[0]
                                )
                                form.append(
                                  "comments",
                                  e.target.elements.comments.value
                                )
                                form.append("type", type)
                                form.append("size", size)
                                form.append("name", name)
                                var requestOptions = {
                                  method: "POST",
                                  body: form,
                                  redirect: "follow",
                                }
                                fetch(
                                  "https://api.frontforcecrm.com/login.php",
                                  requestOptions
                                )
                                  .then(response => response.text())
                                  .then(result => {
                                    if (result == "success") {
                                      toastr.success(
                                        "Success",
                                        "You are successfully update the salary component"
                                      )
                                      FetchQualificationAttachment();
                                      setqualificationAttachment(false)
                                    } else {
                                      toastr.error(
                                        "Error",
                                        "Error in updating the salary component"
                                      )
                                    }
                                  })
                                  .catch(error => console.log("error", error))
                                  
                                }
                              }}
                              >
                                <Row>
                                <Col md={12}>
                                  <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                  ></hr>
                                  <h5>Add Attachment</h5>
                                  <hr
                                    style={{ borderTop: "1px solid #3c3c3c24" }}
                                  ></hr>
                                </Col>
                                <Col md={5}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Select File
                                    </Label>
                                    <Input
                                      type="file"
                                      className="form-control"
                                      id="formrow-firstname-Input"
                                      name="file_attach"
                                    />
                                  </div>
                                </Col>
                                <Col md={8}>
                                  <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Comments
                                    </Label>
                                    <textarea name="comments" className="form-control"></textarea>
                                  </div>
                                </Col>
                                <Col md={12} style={{ textAlign: "end" }}>
                                  <button
                                    type="submit"
                                    className="btn-rounded btn-soft-danger waves-effect waves-light btn btn-secondary"
                                    onClick={() => {
                                      setqualificationAttachment(false)
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  &nbsp; &nbsp;
                                  <button
                                    type="submit"
                                    className="btn-rounded btn btn-success"
                                  >
                                    Save Changes
                                  </button>
                                </Col>
                              </Row>
                              </Form>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(EditEmployee)
