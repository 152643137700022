import React, { useState, useEffect, useRef,Component,createRef } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  TabContent,
  TabPane,
  Form,
  NavLink,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import withRouter from "components/Common/withRouter";
import TableContainer from "components/Common/TableContainer";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Breadcrumbs from "components/Common/Breadcrumb";
class Location extends Component {
    constructor(props){
        super(props);
        this.state= {
            locationmap:'',
            companyData:[],
            currentRole:'',
        }
        this.autoCompleteRef = createRef();
         this.inputRef = createRef();
    }
    componentDidMount(){
            let auth = JSON.parse(localStorage.getItem("authUser"));
            auth.map((e) => {
              this.fetchCompany(e.role);
              this.setState({currentRole:e.role});
            });
            const autoComplete = new google.maps.places.Autocomplete(this.inputRef.current);
            this.autoCompleteRef.current = autoComplete;
        
            // Listen for the place_changed event when a user selects an address.
            autoComplete.addListener("place_changed", () => {
              const place = autoComplete.getPlace();
              const location = place.geometry.location;
        
              const lat = location.lat();
              const lng = location.lng();
              this.setState({locationmap: lat + "," + lng});
            
        
              // Now you can use lat and lng in your application as needed.
            });
          
    }
    fetchCompany = (id) => {
        var formdata = new FormData();
        formdata.append("method", "fetch_company");
        formdata.append("role", id);
    
        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
    
        fetch("https://api.frontforcecrm.com/company.php", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            this.setState({companyData:result});
          })
          .catch((error) => console.log("error", error));
      };
    render() {
        const DeleteSpecificLocation = (passid) => {
            var formdata = new FormData();
            formdata.append("method", "delete-company-by-id");
            formdata.append("delete_id", passid);
        
            var requestOptions = {
              method: "POST",
              body: formdata,
              redirect: "follow",
            };
        
            fetch("https://api.frontforcecrm.com/leave.php", requestOptions)
              .then((response) => response.text())
              .then((result) => {
                if (result == "success") {
                  toastr.success(`Successfully delete `);
                  this.fetchCompany(currentRole);
                } else {
                  toastr.error("Please do it again");
                }
              })
              .catch((error) => console.log("error", error));
          };
      const {companyData,currentRole} =this.state;
        return (
            <React.Fragment>
            <div className="page-content">
              <Container fluid={true}>
                <Breadcrumbs title="" breadcrumbItem="Company Locations" />
            <div style={{ padding: "15px" }}>
            <Row>
              <Col md={12}>
                <h4>Company</h4>
                <hr></hr>
              </Col>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  var formdata = new FormData();
                  formdata.append("method", "company-data-post");
                  formdata.append(
                    "company_name",
                    e.target.elements.company_title.value
                  );
                  formdata.append(
                    "company_location",
                    e.target.elements.company_location.value
                  );
                  formdata.append("location_map", locationmap);
                  var requestOptions = {
                    method: "POST",
                    body: formdata,
                    redirect: "follow",
                  };

                  fetch(
                    "https://api.frontforcecrm.com/company.php",
                    requestOptions
                  )
                    .then((response) => response.text())
                    .then((result) => {
                      if (result == "success") {
                        toastr.success(
                          "Success",
                          "You are done successfuly"
                        );
                        e.target.elements.company_title.value = "";
                        e.target.elements.company_location.value =
                          "";
                        this.fetchCompany(currentRole);
                      } else {
                        toastr.error("Error", "Error in values");
                      }
                    })
                    .catch((error) => console.log("error", error));
                }}
              >
                <Row>
                  <Col md={5}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-ip-Input">
                        Company Title
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-ip-Input"
                        placeholder=""
                        name="company_title"
                      />
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="mb-3">
                      <Label htmlFor="formrow-ip-Input">
                        Company Location
                      </Label>
                      <input
                        type="text"
                        className="form-control"
                        id="formrow-ip-Input"
                        placeholder="Enter Username"
                        ref={this.inputRef}
                        name="company_location"
                      />
                      {/* <AutoCompletes/> */}
                    </div>
                  </Col>

                  <Col md={12}>
                    <button
                      style={{ marginBottom: "15px" }}
                      type="submit"
                      className="btn-rounded btn btn-primary"
                    >
                      Add New
                    </button>
                  </Col>
                </Row>
              </Form>
              <Col xl={12}>
                <Row>
                  <Col md={12}>
                    <TableContainer
                      columns={[
                        {
                          Header: "Company Name",
                          accessor: "title",
                        },
                        {
                          Header: "Location",
                          accessor: "location",
                        },
                        {
                          Header: "Map View",
                          accessor: "location_map",
                          disableFilters: true,
                          Cell: (cellProps) => {
                          
                            return (
                              <ul className="list-unstyled hstack gap-1 mb-0">
                                <li
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="View"
                                >
                                  <Link
                                  target="_blank"
                                    to={`https://www.google.com/maps/search/?api=1&query=${cellProps.row.original.location_map}`}
                                    className="btn btn-sm btn-soft-primary"
                                  >
                                    <i
                                      className="mdi mdi-map-marker"
                                      id="viewtooltip"
                                    ></i>
                                  </Link>
                                </li>
                              </ul>
                            );
                          },
                        },
                        {
                          Header: "Actions",
                          accessor: "id",
                          disableFilters: true,
                          Cell: (cellProps) => {
                            return (
                              <ul className="list-unstyled hstack gap-1 mb-0">
                                <li>
                                  <span
                                    onClick={()=>DeleteSpecificLocation(cellProps.row.original.id)}
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                  >
                                    <i
                                      className="mdi mdi-delete-outline"
                                      id="deletetooltip"
                                    />
                                  </span>
                                </li>
                              </ul>
                            );
                          },
                        },
                      ]}
                      data={companyData}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                      className="custom-header-css"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          </Container>
          </div>
          </React.Fragment>
        );
    }
}

export default Location;