import React, { useCallback, useEffect, useState } from "react";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Modal,
  ModalBody,
} from "reactstrap";

//Import Breadcrumb

import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "components/Common/TableContainer";
import { Link } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const EmployeeLeave = (props) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [modal_xlarge, setmodal_xlarge] = useState(false);
  const [leaveStatus, showUpdateLeaveStatus] = useState(false);
  const [singleData, setSingleData] = useState([]);
  const [UserId, setuserID] = useState("");
  useEffect(() => {
    let auth = JSON.parse(localStorage.getItem("authUser"));
    auth.map((e) => {
      fetchLeaves(e.user_id);
      setuserID(e.user_id);
    });
  }, [count]);
  //meta title
  document.title = "Leave List | HR Dashboard";
  const columns = React.useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "fullname",
      },
      {
        Header: "Type",
        accessor: "leave_type",
      },
      {
        Header: "Apply Date",
        accessor: "apply_date",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Actions",
        accessor: "id",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <span
                  onClick={() => fetchSingleLeave(cellProps.row.original.id)}
                  className="btn btn-sm btn-success"
                >
                  <i className="mdi mdi-eye" id="viewtooltip">
                    View
                  </i>
                </span>
              </li>

              <li>
                <span
                  onClick={() => DeleteSingleLeave(cellProps.row.original.id)}
                  className="btn btn-sm btn-danger"
                >
                  <i className="mdi mdi-delete" id="edittooltip">
                    Delete
                  </i>
                </span>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const fetchLeaves = useCallback((id) => {
    var formdata = new FormData();
    formdata.append("method", "fetch-leaves");
    formdata.append("emp_id", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.frontforcecrm.com/leave.php", requestOptions)
      .then((response) => response.json())
      .then((result) => setData(result))
      .catch((error) => console.log("error", error));
  });
  const fetchSingleLeave = (passid) => {
    var formdata = new FormData();
    formdata.append("method", "fetch-single-leave");
    formdata.append("leave_id", passid);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.frontforcecrm.com/leave.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSingleData(result);
        setmodal_xlarge(true);
      })
      .catch((error) => console.log("error", error));
  };
  const DeleteSingleLeave = (passid) => {
    var formdata = new FormData();
    formdata.append("method", "delete-leave-by-id");
    formdata.append("delete_id", passid);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.frontforcecrm.com/leave.php", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result == "success") {
          toastr.success(`Successfully delete `);
          fetchLeaves(UserId);
        } else {
          toastr.error("Please do it again");
        }
      })
      .catch((error) => console.log("error", error));
  };
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const updateStatusNow = (id) => {
    let valfor = document.getElementById("status_for_leave").value;
    if (valfor == "null") {
      toastr.error("Error", "Please select the type first");
      return false;
    } else {
      var formdata = new FormData();
      formdata.append("method", "update_leave_status");
      formdata.append("leave_id", id);
      formdata.append("leave_status", valfor);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch("https://api.frontforcecrm.com/leave.php", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          if (result == "success") {
            toastr.success("Success", "Successfully update the leave status");
            setmodal_xlarge(false);
            fetchLeaves(UserId);
          } else {
            toastr.error("Error", "Please try again");
          }
        })
        .catch((error) => console.log("error", error));
    }

    setmodal_xlarge(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="" breadcrumbItem="Leave List" />
          <Row>
            {/* <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Leave List</CardTitle>

                  
                   

                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">From Date</Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Employee Name"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">To Date</Label>
                          <Input
                            type="date"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Employee ID"
                          />
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Show Leave with Status</Label>
                          <select className="form-control">
                            <option value="null">--Select--</option>
                            <option value="Rejected">Rejected</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Scheduled">Scheduled</option>
                            <option value="Taken">Taken</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Leave Type</Label>
                          <select className="form-control">
                            <option value="null">--Select--</option>
                            <option value="Personal">Personal</option>
                            <option value="Vacation">Vacation</option>
                            <option value="Matternity">Matternity</option>
                          </select>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Employee Name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-email-Input"
                            placeholder="Employee Name"
                          />
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-email-Input">Sub Unit</Label>
                          <select className="form-control">
                            <option value="null">--Select--</option>
                            <option value="Engineering">Engineering</option>
                            <option value="Developement">Developement</option>
                            <option value="Quality Insurance">Quality Insurance</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                   
                    <div style={{ textAlign:'end' }}>
                      <button type="submit" className="btn btn-info w-md">
                        Reset
                      </button>
                      &nbsp;
                      &nbsp;
                      <button type="submit" className="btn btn-success w-md">
                        Search
                      </button>
                    </div>
                  
                </CardBody>
              </Card>
            </Col> */}
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    <div style={{ textAlign: "start" }}>
                      <Link to="../assignLeave">
                        <button type="submit" className="btn btn-info w-md">
                          Assign Leave
                        </button>
                      </Link>
                      &nbsp;
                    </div>
                    <br></br>
                    Employee Leaves
                  </CardTitle>

                  <Row>
                    <Col md={12}>
                      <TableContainer
                        columns={columns}
                        data={data}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                        className="custom-header-css"
                      />
                    </Col>
                    <Modal
                      keyboard={false}
                      backdrop="static"
                      size="md"
                      isOpen={modal_xlarge}
                      toggle={() => {
                        tog_xlarge();
                      }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          Leave Details
                        </h5>
                        <button
                          onClick={() => {
                            setmodal_xlarge(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        {singleData.map((e, i) => {
                          return (
                            <Row key={i}>
                              <Col md={6}>
                                <h6>Employee Name</h6>
                              </Col>
                              <Col md={6}>
                                <h6>{e.fullname}</h6>
                              </Col>
                              <Col md={6}>
                                <h6>Leave Type</h6>
                              </Col>
                              <Col md={6}>
                                <h6>{e.leave_type}</h6>
                              </Col>
                              <Col md={6}>
                                <h6>Apple Date</h6>
                              </Col>
                              <Col md={6}>
                                <h6>{e.apply_date}</h6>
                              </Col>
                              <Col md={6}>
                                <h6>From Date</h6>
                              </Col>
                              <Col md={6}>
                                <h6>{e.from_date}</h6>
                              </Col>
                              <Col md={6}>
                                <h6>To Date</h6>
                              </Col>
                              <Col md={6}>
                                <h6>{e.to_date}</h6>
                              </Col>
                              <Col md={6}>
                                <h6>Leave Status</h6>
                              </Col>
                              <Col md={6}>
                                <h6>
                                  {e.status}{" "}
                                  <i
                                    onClick={() => showUpdateLeaveStatus(true)}
                                    className="bx bx-edit"
                                  ></i>
                                </h6>
                                {leaveStatus == true ? (
                                  <div>
                                    <select
                                      className="form-control"
                                      id="status_for_leave"
                                      name="status_for_leave"
                                    >
                                      <option value="null">--SELECT--</option>
                                      <option
                                        selected={
                                          e.status == "approved" ? true : false
                                        }
                                        value={"approved"}
                                      >
                                        Approved
                                      </option>
                                      <option
                                        selected={
                                          e.status == "rejected" ? true : false
                                        }
                                        value={"rejected"}
                                      >
                                        Rejected
                                      </option>
                                    </select>
                                    <br />
                                  </div>
                                ) : null}
                              </Col>
                              <Col md={12}>
                                <h6>Comment</h6>
                              </Col>
                              <Col md={12}>
                                <p>{e.comments}</p>
                              </Col>
                              <Col
                                md={12}
                                style={{ textAlign: "end", marginTop: 10 }}
                              >
                                <button
                                  onClick={() => setmodal_xlarge(false)}
                                  className="btn btn-danger"
                                >
                                  Cancel
                                </button>
                                &nbsp;&nbsp;
                                <button
                                  onClick={() => updateStatusNow(e.id)}
                                  className="btn btn-primary"
                                >
                                  Save Changes
                                </button>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    </Modal>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  );
};

export default EmployeeLeave;
