import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  TabContent,
  TabPane,
  Form,
  NavLink,
} from "reactstrap";
import Select from "react-select";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import classnames from "classnames";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

// Import Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { Countries, Currencies, Nationality } from "common/data/nationalities";
import withRouter from "components/Common/withRouter";
import TableContainer from "components/Common/TableContainer";
import AutoCompletes from "components/Common/Autocomplete";

const optionGroup2 = [
  {
    label: "Marital Status",
    options: [
      { label: "Single", value: "Single" },
      { label: "Married", value: "Married" },
      { label: "Other", value: "Other" },
    ],
  },
];
const optionGroup = [
  {
    label: "Blood Type",
    options: [
      { label: "A+", value: "A+" },
      { label: "A-", value: "A-" },
      { label: "B+", value: "B+" },
      { label: "B-", value: "B-" },
      { label: "AB+", value: "AB+" },
      { label: "AB-", value: "AB-" },
      { label: "O+", value: "O+" },
      { label: "O-", value: "O-" },
    ],
  },
];
const ProjectList = (props) => {
  const [UserID, SetUserID] = useState("");
  const [locationmap, setLocationmap] = useState("");
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    let auth = JSON.parse(localStorage.getItem("authUser"));
    auth.map((e) => {
      fetchCompany(e.role);
      SetUserID(e.role);
    });
    fetchMachine();
    const autoComplete = new google.maps.places.Autocomplete(inputRef.current);
    autoCompleteRef.current = autoComplete;

    // Listen for the place_changed event when a user selects an address.
    autoComplete.addListener("place_changed", () => {
      const place = autoComplete.getPlace();
      const location = place.geometry.location;

      const lat = location.lat();
      const lng = location.lng();
      setLocationmap(lat + "," + lng);
      console.log("Latitude:", lat);
      console.log("Longitude:", lng);

      // Now you can use lat and lng in your application as needed.
    });
  }, []);

  const Organizationcolumns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Actions",
        accessor: "id",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link to="#" className="btn btn-sm btn-soft-primary">
                  <i className="mdi mdi-eye-outline" id="viewtooltip"></i>
                </Link>
              </li>

              <li>
                <Link
                  to={`/edit-employee/${cellProps.row.original.id}`}
                  className="btn btn-sm btn-soft-info"
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                </Link>
              </li>

              <li>
                <Link to="#" className="btn btn-sm btn-soft-danger">
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const Organizationdata = [
    {
      id: 1,
      name: "Jennifer Chang",
    },
    {
      id: 2,
      name: "Gavin Joyce",
    },
    {
      id: 3,
      name: "Angelica Ramos",
    },
    {
      id: 4,
      name: "Doris Wilder",
    },
  ];

  document.title = "Setting | HR Dashboard";
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [companyMachineData, setCompanyMachineData] = React.useState([]);
  const [companyData, setCompanyData] = React.useState([]);
  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  const fetchMachine = () => {
    var formdata = new FormData();
    formdata.append("method", "fetch_machine");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.frontforcecrm.com/machine-config.php", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setCompanyMachineData(JSON.parse(result));
      })
      .catch((error) => console.log("error", error));
  };
  const fetchCompany = (id) => {
    var formdata = new FormData();
    formdata.append("method", "fetch_company");
    formdata.append("role", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.frontforcecrm.com/company.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCompanyData(result);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem="Setting" />

          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              <Card className="email-leftbar">
                <div className="mail-list mt-4">
                  <Nav pills className="flex-column">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleVertical("1");
                        }}
                      >
                        Organization
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleVertical("2");
                        }}
                      >
                        Company Name
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "3",
                        })}
                        onClick={() => {
                          toggleVertical("3");
                        }}
                      >
                        Departments
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "mb-2": true,
                          active: verticalActiveTab === "4",
                        })}
                        onClick={() => {
                          toggleVertical("4");
                        }}
                      >
                        ZK TECH Configuration
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Card>

              <div className="email-rightbar mb-3">
                <Card>
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    <TabPane tabId="1">
                      <div style={{ padding: "15px" }}>
                        <Row>
                          <Col md={12}>
                            <h4>Organizations</h4>
                            <hr></hr>
                          </Col>
                          <Col md={5}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Organization Title
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Organization Title"
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <button
                              style={{ marginTop: "25px" }}
                              type="submit"
                              className="btn-rounded btn btn-success"
                            >
                              Save Changes
                            </button>
                          </Col>
                          <Col xl={12}>
                            <Row>
                              <Col md={12}>
                                <TableContainer
                                  columns={Organizationcolumns}
                                  data={Organizationdata}
                                  isGlobalFilter={true}
                                  isAddOptions={false}
                                  customPageSize={10}
                                  className="custom-header-css"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div style={{ padding: "15px" }}>
                        <Row>
                          <Col md={12}>
                            <h4>Company</h4>
                            <hr></hr>
                          </Col>
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              var formdata = new FormData();
                              formdata.append("method", "company-data-post");
                              formdata.append(
                                "company_name",
                                e.target.elements.company_title.value
                              );
                              formdata.append(
                                "company_location",
                                e.target.elements.company_location.value
                              );
                              formdata.append("location_map", locationmap);
                              var requestOptions = {
                                method: "POST",
                                body: formdata,
                                redirect: "follow",
                              };

                              fetch(
                                "https://api.frontforcecrm.com/company.php",
                                requestOptions
                              )
                                .then((response) => response.text())
                                .then((result) => {
                                  if (result == "success") {
                                    toastr.success(
                                      "Success",
                                      "You are done successfuly"
                                    );
                                    e.target.elements.company_title.value = "";
                                    e.target.elements.company_location.value =
                                      "";
                                    fetchCompany(UserID);
                                  } else {
                                    toastr.error("Error", "Error in values");
                                  }
                                })
                                .catch((error) => console.log("error", error));
                            }}
                          >
                            <Row>
                              <Col md={5}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-ip-Input">
                                    Company Title
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-ip-Input"
                                    placeholder=""
                                    name="company_title"
                                  />
                                </div>
                              </Col>
                              <Col md={8}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-ip-Input">
                                    Company Location
                                  </Label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="formrow-ip-Input"
                                    placeholder="Enter Username"
                                    ref={inputRef}
                                    name="company_location"
                                  />
                                  {/* <AutoCompletes/> */}
                                </div>
                              </Col>

                              <Col md={12}>
                                <button
                                  style={{ marginBottom: "15px" }}
                                  type="submit"
                                  className="btn-rounded btn btn-primary"
                                >
                                  Add New
                                </button>
                              </Col>
                            </Row>
                          </Form>
                          <Col xl={12}>
                            <Row>
                              <Col md={12}>
                                <TableContainer
                                  columns={[
                                    {
                                      Header: "Company Name",
                                      accessor: "title",
                                    },
                                    {
                                      Header: "Location",
                                      accessor: "location",
                                    },
                                    {
                                      Header: "Map View",
                                      accessor: "location_map",
                                      disableFilters: true,
                                      Cell: (cellProps) => {
                                        let value =
                                          cellProps.row.original.location_map.split(
                                            ","
                                          );
                                        return (
                                          <ul className="list-unstyled hstack gap-1 mb-0">
                                            <li
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              title="View"
                                            >
                                              <Link
                                              target="_blank"
                                                to={`https://www.google.com/maps/search/?api=1&query=${cellProps.row.original.location_map}`}
                                                className="btn btn-sm btn-soft-primary"
                                              >
                                                <i
                                                  className="mdi mdi-map-marker"
                                                  id="viewtooltip"
                                                ></i>
                                              </Link>
                                            </li>
                                          </ul>
                                        );
                                      },
                                    },
                                    {
                                      Header: "Actions",
                                      accessor: "id",
                                      disableFilters: true,
                                      Cell: (cellProps) => {
                                        return (
                                          <ul className="list-unstyled hstack gap-1 mb-0">
                                            <li>
                                              <Link
                                                to={`/edit-employee/${cellProps.row.original.id}`}
                                                className="btn btn-sm btn-soft-info"
                                              >
                                                <i
                                                  className="mdi mdi-pencil-outline"
                                                  id="edittooltip"
                                                />
                                              </Link>
                                            </li>

                                            <li>
                                              <Link
                                                to="#"
                                                className="btn btn-sm btn-soft-danger"
                                              >
                                                <i
                                                  className="mdi mdi-delete-outline"
                                                  id="deletetooltip"
                                                />
                                              </Link>
                                            </li>
                                          </ul>
                                        );
                                      },
                                    },
                                  ]}
                                  data={companyData}
                                  isGlobalFilter={true}
                                  isAddOptions={false}
                                  customPageSize={10}
                                  className="custom-header-css"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div style={{ padding: "15px" }}>
                        <Row>
                          <Col md={12}>
                            <h4>Departments</h4>
                            <hr></hr>
                          </Col>
                          <Col md={5}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Departments Title
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-firstname-Input"
                                placeholder="Department Title"
                              />
                            </div>
                          </Col>
                          <Col md={3}>
                            <button
                              style={{ marginTop: "25px" }}
                              type="submit"
                              className="btn-rounded btn btn-success"
                            >
                              Save Changes
                            </button>
                          </Col>
                          <Col xl={12}>
                            <Row>
                              <Col md={12}>
                                <TableContainer
                                  columns={Organizationcolumns}
                                  data={Organizationdata}
                                  isGlobalFilter={true}
                                  isAddOptions={false}
                                  customPageSize={10}
                                  className="custom-header-css"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div style={{ padding: "15px" }}>
                        <Row>
                          <Col md={12}>
                            <h4>Attendance Machine Configuration</h4>
                            <hr></hr>
                          </Col>
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();

                              var formdata = new FormData();
                              formdata.append(
                                "method",
                                "machine-configuration-post"
                              );
                              formdata.append(
                                "ip_address",
                                e.target.elements.ip_address_machine.value
                              );
                              formdata.append(
                                "username",
                                e.target.elements.device_username.value
                              );
                              formdata.append(
                                "password",
                                e.target.elements.device_password.value
                              );
                              formdata.append(
                                "company_name",
                                e.target.elements.company_name_for_machine.value
                              );

                              var requestOptions = {
                                method: "POST",
                                body: formdata,
                                redirect: "follow",
                              };

                              fetch(
                                "https://api.frontforcecrm.com/machine-config.php",
                                requestOptions
                              )
                                .then((response) => response.text())
                                .then((result) => {
                                  if (result == "success") {
                                    toastr.success(
                                      "Success",
                                      "You are done successfuly"
                                    );
                                    e.target.elements.ip_address_machine.value =
                                      "";
                                    e.target.elements.device_username.value =
                                      "";
                                    e.target.elements.device_password.value =
                                      "";
                                    e.target.elements.company_name_for_machine.value =
                                      "";
                                    fetchMachine();
                                  } else {
                                    toastr.error("Error", "Error in values");
                                  }
                                })
                                .catch((error) => console.log("error", error));
                            }}
                          >
                            <Row>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-ip-Input">
                                    IP Address &nbsp;{" "}
                                    <i
                                      className="fas fa-info-circle"
                                      title="IP that you are using to access the dashboard of the attendance machine"
                                    ></i>
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-ip-Input"
                                    placeholder=""
                                    name="ip_address_machine"
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-ip-Input">
                                    Device's Username
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-ip-Input"
                                    placeholder="Enter Username"
                                    name="device_username"
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-ip-Input">
                                    Device's Password
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="formrow-ip-Input"
                                    placeholder="Enter Password"
                                    name="device_password"
                                  />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className="mb-3">
                                  <Label htmlFor="formrow-ip-Input">
                                    Company Name
                                  </Label>

                                  <select
                                    name="company_for_machine"
                                    className="form-control"
                                  >
                                    <option>--Select--</option>
                                    {companyData.map((e, i) => (
                                      <option value={e.title} key={i}>
                                        {e.title}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>

                              <Col md={12}>
                                <button
                                  style={{ marginBottom: "15px" }}
                                  type="submit"
                                  className="btn-rounded btn btn-primary"
                                >
                                  Add New
                                </button>
                              </Col>
                            </Row>
                          </Form>
                          <Col xl={12}>
                            <Row>
                              <Col md={12}>
                                <TableContainer
                                  columns={[
                                    {
                                      Header: "IP Address",
                                      accessor: "ip_address",
                                    },
                                    {
                                      Header: "Company Name",
                                      accessor: "for_company_name",
                                    },
                                    {
                                      Header: "Actions",
                                      accessor: "id",
                                      disableFilters: true,
                                      Cell: (cellProps) => {
                                        return (
                                          <ul className="list-unstyled hstack gap-1 mb-0">
                                            <li
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              title="View"
                                            >
                                              <Link
                                                to="#"
                                                className="btn btn-sm btn-soft-primary"
                                              >
                                                <i
                                                  className="mdi mdi-eye-outline"
                                                  id="viewtooltip"
                                                ></i>
                                              </Link>
                                            </li>

                                            <li>
                                              <Link
                                                to={`/edit-employee/${cellProps.row.original.id}`}
                                                className="btn btn-sm btn-soft-info"
                                              >
                                                <i
                                                  className="mdi mdi-pencil-outline"
                                                  id="edittooltip"
                                                />
                                              </Link>
                                            </li>

                                            <li>
                                              <Link
                                                to="#"
                                                className="btn btn-sm btn-soft-danger"
                                              >
                                                <i
                                                  className="mdi mdi-delete-outline"
                                                  id="deletetooltip"
                                                />
                                              </Link>
                                            </li>
                                          </ul>
                                        );
                                      },
                                    },
                                  ]}
                                  data={companyMachineData}
                                  isGlobalFilter={true}
                                  isAddOptions={false}
                                  customPageSize={10}
                                  className="custom-header-css"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProjectList);
