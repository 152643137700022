import React,{useState,useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, Col ,Row,Form,Modal,ModalBody} from 'reactstrap';

//import images
import wechat from "../../assets/images/companies/wechat.svg";

const DetailsSection = () => {
    
    const {id} = useParams();
    const [count,setCount] = useState(0);
    const [first_name,setFirstName] = useState('');
    const [jobData,setJobData] = useState([]);
    const [loanData,setLoanData] = useState([]);
    const [advSalary,setAdvSalary] = useState([]);
    const [modal_backdrop,setmodal_backdrop] = useState(false);
    const [showMonths,setShowMonths] = useState(false);
  const [showDatetoDate,setShowDatetoDate] = useState(false);
  const [SalaryAttachmentsData,setSalaryAttachmentsData] = useState([]);
  const [salaryAmount,setSalaryAmount] = useState('');
  
    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
        removeBodyCss();
      }
    
      function removeBodyCss() {
        document.body.classList.add("no_padding");
      }
      const changeReportType = (val) =>{
        if(val == 'monthly'){
                setShowMonths(true);
                setShowDatetoDate(false)
        }else {
                  setShowMonths(false);
                setShowDatetoDate(true)
        }
 }
    useEffect(()=>{
        FetchAttachmentsComponent();
        getPayRoll();
        setmodal_backdrop(true);
    },[count]);
    const getPayRoll = () =>{
        var formdata = new FormData();
formdata.append("method", "get-payroll");
formdata.append("post_id", id);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("https://api.frontforcecrm.com/pay-roll.php", requestOptions)
  .then(response => response.json())
  .then((result) => {
    if(result){
        console.log(result)
        result.map((e)=>{
            setFirstName(e.first_name)
            setJobData(e.job_info)
            setLoanData(e.loan_info)
            setAdvSalary(e.advance_info)
        })
    }
  })
  .catch(error => console.log('error', error));
    }
    const FetchAttachmentsComponent = () => {
        let form = new FormData()
        form.append("emp_id", id)
        form.append("method", "fetch-salary-components-by-emp-id")
        var requestOptions = {
          method: "POST",
          body: form,
          redirect: "follow",
        }
        fetch("https://api.frontforcecrm.com/login.php", requestOptions)
          .then(response => response.json())
          .then(result => {
            // setSalaryAttachmentsData(result)
            console.log(result);
            result.map((e)=>{
                setSalaryAmount(e.salary_amount)
            })
            
          })
          .catch(error => console.log("error", error))
      }
    console.log(id);
    
    return (
        <React.Fragment>
            <Col xl={8}>
                <Card id="myDiv">
                    <CardBody className="border-bottom">
                        <div className="d-flex">
                            <img src={wechat} alt="" height="50" />
                            <div className="flex-grow-1 ms-3">
                                <h5 className="fw-semibold">{first_name}</h5>
                                <ul className="list-unstyled hstack gap-2 mb-0">
                                    <li>
                                        <i className="bx bx-building-house"></i> <span className="text-muted">{jobData.map((e)=>e.job_category)}</span>
                                    </li>
                                    <li>
                                        <i className="bx bx-map"></i> <span className="text-muted">{jobData.map((e)=>e.job_location)}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </CardBody>
                   
                    <CardBody>
                        {
                            loanData.length !== 0 ? 
                            loanData.map((e,i)=>
                                    <div key={i}>
                                        <h5 className="fw-semibold mb-3">Loan</h5>
                        <table className='table'>
                            <thead style={{ border:'1px solid black',}}>
                                <th >Total Loan Amount</th>
                                <th>Loan Date</th>
                                <th>Loan Amount</th>
                                <th>No Cycle</th>
                                <th>Remaining Amount</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {e.loan_amount}
                                    </td>
                                    <td>
                                        {e.loan_date}
                                    </td>
                                    <td>
                                        {e.refund_amount_per_cycle}
                                    </td>
                                    <td>
                                        {e.refund_cycle}
                                    </td>
                                    <td>
                                        {e.loan_amount - e.refund_amount_per_cycle}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                                    </div>
                            ):null
                        }
                        {
                            advSalary.length !== 0 ?
                            advSalary.map((e,i)=>
                            <div key={i}>
                                <h5 className="fw-semibold mb-3">Advance Salary</h5>
                        <table className='table'>
                            <thead style={{ border:'1px solid black',}}>
                                <th >Amount</th>
                                <th>Issue Date</th>
                               
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                       {e.adv_amount}
                                    </td>
                                    <td>
                                       {e.issue_date}
                                    </td>
                                    
                                </tr>

                            </tbody>
                        </table>
                            </div>
                            ):null
                        }
                        <h5 className="fw-semibold mb-3">Attendance</h5>
                        <table className='table' id="custom_result_from_api">
                            <thead style={{ border:'1px solid black',}}>
                                <th>No of Working Days</th>
                                <th >Presents</th>
                                <th>Absents</th>
                                <th>Leave</th>
                               
                            </thead>
                            <tbody id="attendance_result">
                                <tr>
                                    <td>
                                        10
                                    </td>
                                    <td>
                                        10
                                    </td>
                                    <td>
                                        0
                                    </td>
                                    <td>
                                        0
                                    </td>
                                    
                                </tr>

                            </tbody>
                        </table>
                        
                        <Row>
                            <Col md={12} style={{ textAlign:'end' }}>
                         {loanData.length === 0 ? null : <h6>Loan Amount : <span>10,000</span></h6>}
                               {advSalary.length === 0 ? null : <h6>Advance Salary : <span>10,000</span></h6>} 
                                <h6>Total Salary : <span>{salaryAmount}</span></h6>
                                <h5>Total</h5>
                                <h4> <span>${salaryAmount}</span></h4>
                            </Col>
                            <Modal
                      isOpen={modal_backdrop}
                      toggle={() => {
                        tog_backdrop();
                      }}
                      backdrop={'static'}
                      id="staticBackdrop"
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Select Date</h5>
                        <button type="button" className="btn-close"
                          onClick={() => {
                            setmodal_backdrop(false);
                          }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        <input onChange={(e)=>changeReportType(e.target.value)} className="form-check-input" value="monthly" name="select_type" type="radio"/>&nbsp;Monthly &nbsp;&nbsp;
                        <input onChange={(e)=>changeReportType(e.target.value)} className="form-check-input" value="date_to_date" name="select_type" type="radio"/>&nbsp;Date to Date
                        
                        {
                            showMonths  === true ? 
                            <Form
                            onSubmit={(e)=>{
                              e.preventDefault();
                              if(e.target.elements.month_name.value === ""){
                                toastr.error('Error','Select a month');
                                return false;
                              }else{
                                let formdata = new FormData();
                                formdata.append('method','fetch-attendance');
                                formdata.append('other_method','monthly-wise');
                                formdata.append('month',e.target.elements.month_name.value);
                                formdata.append('id',id);
                                var requestOptions = {
                                  method: 'POST',
                                  body: formdata,
                                  redirect: 'follow'
                                };
                                
                                fetch("https://api.frontforcecrm.com/report.php", requestOptions)
                                  .then(response => response.text())
                                  .then(result => {
                                    getPayRoll();
                                    document.getElementById('custom_result_from_api').innerHTML = result;
                                    setmodal_backdrop(false)

                                  })
                                  .catch(error => console.log('error', error));
                              }
                            }}
                            >
                            <Row style={{ paddingTop:20 }}>
                                <Col md={6}>
                                    <label>
                                        Select Month
                                    </label>
                                    <select name="month_name" className="form-control">
                                    <option value="1">January</option>
                                    <option value="2">Feburay</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">Decemeber</option>
                                    </select>
                                </Col>
                                <Col md={6} >
                                    <button style={{ marginTop:26 }} type="submit" className="btn btn-info" >Submit</button>
                                </Col>
                            </Row>
                            </Form>
                            :null
                        }{
                            showDatetoDate === true ?
                            <Form
                            onSubmit={(e)=>{
                              e.preventDefault();
                              if(e.target.elements.start_date.value === ""){
                                toastr.error('Error','Select Start Date');
                                return false;
                              }else if(e.target.elements.end_date.value === ""){
                                toastr.error('Error','Select End Date');
                                return false;
                              }else{
                                let form = new FormData();
                                form.append('start_date',e.target.elements.start_date.value);
                                form.append('end_date',e.target.elements.end_date.value);
                                form.append('method','fetch-attendance');
                                form.append('other_method','else');
                                form.append('id',id);
                                
                                var requestOptions = {
                                  method: 'POST',
                                  body: form,
                                  redirect: 'follow'
                                };
                                
                                fetch("https://api.frontforcecrm.com/report.php", requestOptions)
                                  .then(response => response.text())
                                  .then(result => {
                                    getPayRoll();
                                    document.getElementById('custom_result_from_api').innerHTML = result;
                                    setmodal_backdrop(false)
                                  })
                                  .catch(error => console.log('error', error));

                              }
                            }}
                            >
                            <Row style={{ paddingTop:20 }}>
                                <Col md={4}>
                                    <label>Start Date</label>
                                    <input type="date" className="form-control" name="start_date"/>
                                </Col>
                                <Col md={4}>
                                    <label>End Date</label>
                                    <input type="date" className="form-control" name="end_date"/>
                                </Col>
                                <Col md={4}>
                                <button style={{ marginTop:26 }} type="submit" className="btn btn-info" >Submit</button>
                                </Col>
                            </Row>
                            </Form>
                            :null
                        }
                     
                      </div>
                 
                    </Modal>
                        
                        </Row> 
                        
                        
                     
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default DetailsSection;