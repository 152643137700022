import React, { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  Modal,
  InputGroup,
} from "reactstrap";

//Import Breadcrumb

import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from "components/Common/TableContainer";
import { Link, useParams } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const ViewReport = (props) => {
  //meta title
  const { id } = useParams()
  document.title = "Employee List | HR Dashboard";
  const [data, setData] = React.useState([]);
  const [modal_backdrop, setmodal_backdrop] = React.useState(false);
  const [location, setLocation] = React.useState('');
  const [total, setTotal] = React.useState(0);
  const [absent, setAbsent] = React.useState(0);
  const [present, setPresent] = React.useState(0);
  const [presentData, setPresentData] = React.useState([]);
  const [absentData, setAbsentData] = React.useState([]);
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
 
  
  const Absentcolumns = React.useMemo(
    () => [
      {
        Header: "Profile Image",
        accessor: "profile_image",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
              <div className="avatar-group-item">
                <a className="d-inline-block" >
                <img src={cellProps.row.original.profile_image} alt="" className="rounded-circle avatar-xs" />
                    </a>
            </div>
                 {/* <img src={cellProps.row.original.profile_image}  width={80} height={80}/> */}
                
              </li>

            </ul>
          );
        },
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Punch in Time",
        accessor: "punch_time_in",
      },
      {
        Header: "Punch in Location",
        accessor: "check_in_location",
      },
      {
        Header: "Punch out Time",
        accessor: "punch_time_out",
      },
      {
        Header: "Punch out Location",
        accessor: "check_out_location",
      },
    
    ],
    []
  );
  const Presentcolumns = React.useMemo(
    () => [
      {
        Header: "Profile Image",
        accessor: "profile_image",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
              <div className="avatar-group-item">
                <a className="d-inline-block" >
                <img src={cellProps.row.original.profile_image} alt="" className="rounded-circle avatar-xs" />
                    </a>
            </div>
                 {/* <img src={cellProps.row.original.profile_image}  width={80} height={80}/> */}
                
              </li>

            </ul>
          );
        },
      },
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Punch in Time",
        accessor: "punch_time_in",
      },
      {
        Header: "Punch in Location",
        accessor: "check_in_location",
      },
      {
        Header: "Punch out Time",
        accessor: "punch_time_out",
      },
      {
        Header: "Punch out Location",
        accessor: "check_out_location",
      },
     
    ],
    []
  );


  React.useEffect(() => {
    
    fetch_data(id)
  }, []);

  const fetch_data = (id) => {
    var formdata = new FormData();
    formdata.append("method", "fetch_attendance_by_location_id");
    formdata.append("company_id", id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://api.frontforcecrm.com/report.php", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // setData(JSON.parse(result));
        console.log(result);
        result.map((e)=>{
            setTotal(e.total);
            setPresent(e.present);
            setAbsent(e.absent);
            setPresentData(e.present_data);
            setAbsentData(e.absent_data);
            setLocation(e.location)
        })
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="" breadcrumbItem="Employee List" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{location ? location : '{{LOCATION_VALUES}}'} Information</CardTitle>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">
                          Total Employees
                        </Label>
                        <h4>{total ? total : 0}</h4>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">
                          Present Employees
                        </Label>
                        <h4>{present ? present : 0}</h4>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="formrow-email-Input">
                          Absent Employees
                        </Label>
                        <h4>{absent ? absent :0}</h4>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          {
            presentData.length !== 0 ? 
            <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  <Row>
                    <Col sm={4}>Present Employees</Col>
                    <Col className="offset-sm-3" sm="4"></Col>
                  </Row>
                </CardTitle>

                <Row>
                  <Col md={12}>
                    <TableContainer
                      columns={Presentcolumns}
                      data={presentData}
                      isGlobalFilter={true}
                      isAddOptions={true}
                      customPageSize={10}
                      className="custom-header-css"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>:null 
          }
            {
                absentData.length !== 0 ? 
                <Col xl={12} id="absent_employees">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    <Row>
                      <Col sm={4}>Absent Employees</Col>
                      <Col className="offset-sm-3" sm="4"></Col>
                    </Row>
                  </CardTitle>

                  <Row>
                    <Col md={12}>
                      <TableContainer
                        columns={Absentcolumns}
                        data={absentData}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        customPageSize={10}
                        className="custom-header-css"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>:null
            }
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  );
};

export default ViewReport;
