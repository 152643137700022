import React, { useCallback, useEffect } from "react"
import { Pie } from "react-chartjs-2"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";




const PieChart = ({dataColors}) => {
  const [count, setCounter]= React.useState(1);
  const [present, setPresent]= React.useState('');
  const [absent, setAbsent]= React.useState('');
  const [total, setTotal]= React.useState('');
  
useEffect(()=>{
    fetchAttendance();
},[count])

const fetchAttendance = useCallback(()=>{
  var formdata = new FormData();
  formdata.append("method", "fetch-attendance-for-graph");

  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  fetch("http://127.0.0.1/hrmapi/attendance.php", requestOptions)
    .then(response => response.json())
    .then(result => {
      setAbsent(result.absent)
      setPresent(result.present)
      setTotal(result.total)

    })
    .catch(error => console.log('error', error));

})
console.log(dataColors);
  var pieChartColors =  getChartColorsArray(dataColors); 
  const data = {
    labels: ["Present", "Absent"],
    datasets: [
      {
        data: [present, absent],
        backgroundColor: pieChartColors,
        hoverBackgroundColor: pieChartColors,
        hoverBorderColor: "#fff",
      },
    ],
  }

  return (
    <div>
      <h6>Total Employee {total}</h6>
      <h6>Present Employee {present}</h6>
      <h6>Absent Employee {absent}</h6>
      <Pie width={751} height={260} data={data} />
    </div>
  );
}

export default PieChart;
