export const Nationality = [
  
            {value:"Peruvian",label:"Peruvian"},
            {value:"Brazilian",label:"Brazilian"},
            {value:"Libyan",label:"Libyan"},
            {value:"Sammarinese",label:"Sammarinese"},
            {value:"Thai",label:"Thai"},
            {value:"Malaysian",label:"Malaysian"},
            {value:"Paraguayan",label:"Paraguayan"},
            {value:"Namibian",label:"Namibian"},
            {value:"Slovak",label:"Slovak"},
            {value:"Chinese",label:"Chinese"},
            {value:"Honduran",label:"Honduran"},
            {value:"Ugandan",label:"Ugandan"},
            {value:"Indonesian",label:"Indonesian"},
            {value:"South Georgia and the South Sandwich Islander",label:"South Georgia and the South Sandwich Islander"},
            {value:"Yemeni",label:"Yemeni"},
            {value:"Azerbaijani",label:"Azerbaijani"},
            {value:"Israeli",label:"Israeli"},
            {value:"Singaporean",label:"Singaporean"},
            {value:"Emirati",label:"Emirati"},
            {value:"Bolivian",label:"Bolivian"},
            {value:"Irish",label:"Irish"},
            {value:"Central African",label:"Central African"},
            {value:"French Polynesian",label:"French Polynesian"},
            {value:"Grenadian",label:"Grenadian"},
            {value:"Guinea-Bissauan",label:"Guinea-Bissauan"},
            {value:"Nepalese",label:"Nepalese"},
            {value:"Panamanian",label:"Panamanian"},
            {value:"Burkinabe",label:"Burkinabe"},
            {value:"Cambodian",label:"Cambodian"},
            {value:"Uzbekistani",label:"Uzbekistani"},
            {value:"Anguillian",label:"Anguillian"},
            {value:"South Korean",label:"South Korean"},
            {value:"Curaçaoan",label:"Curaçaoan"},
            {value:"Wallis and Futuna Islander",label:"Wallis and Futuna Islander"},
            {value:"Burmese",label:"Burmese"},
            {value:"Egyptian",label:"Egyptian"},
            {value:"Gambian",label:"Gambian"},
            {value:"Saudi Arabian",label:"Saudi Arabian"},
            {value:"Argentinean",label:"Argentinean"},
            {value:"Canadian",label:"Canadian"},
            {value:"Montenegrin",label:"Montenegrin"},
            {value:"American",label:"American"},
            {value:"Cook Islander",label:"Cook Islander"},
            {value:"Macanese",label:"Macanese"},
            {value:"Cocos Islander",label:"Cocos Islander"},
            {value:"Saint Lucian",label:"Saint Lucian"},
            {value:"Cypriot",label:"Cypriot"},
            {value:"Icelander",label:"Icelander"},
            {value:"Ivorian",label:"Ivorian"},
            {value:"Bangladeshi",label:"Bangladeshi"},
            {value:"Bhutanese",label:"Bhutanese"},
            {value:"Kittian and Nevisian",label:"Kittian and Nevisian"},
            {value:"Finnish",label:"Finnish"},
            {value:"Macedonian",label:"Macedonian"},
            {value:"Qatari",label:"Qatari"},
            {value:"Sudanese",label:"Sudanese"},
            {value:"Manx",label:"Manx"},
            {value:"Latvian",label:"Latvian"},
            {value:"East Timorese",label:"East Timorese"},
            {value:"Sao Tomean",label:"Sao Tomean"},
            {value:"Swedish",label:"Swedish"},
            {value:"Martinican",label:"Martinican"},
            {value:"Sierra Leonean",label:"Sierra Leonean"},
            {value:"South African",label:"South African"},
            {value:"Tanzanian",label:"Tanzanian"},
            {value:"Costa Rican",label:"Costa Rican"},
            {value:"Iranian",label:"Iranian"},
            {value:"Dominican",label:"Dominican"},
            {value:"Nigerian",label:"Nigerian"},
            {value:"Palestinian",label:"Palestinian"},
            {value:"Bosnian, Herzegovinian",label:"Bosnian, Herzegovinian"},
            {value:"Caymanian",label:"Caymanian"},
            {value:"Maldivan",label:"Maldivan"},
            {value:"Japanese",label:"Japanese"},
            {value:"Chadian",label:"Chadian"},
            {value:"Guinean",label:"Guinean"},
            {value:"Malagasy",label:"Malagasy"},
            {value:"Italian",label:"Italian"},
            {value:"Jordanian",label:"Jordanian"},
            {value:"Liechtensteiner",label:"Liechtensteiner"},
            {value:"Mongolian",label:"Mongolian"},
            {value:"Tongan",label:"Tongan"},
            {value:"Ecuadorean",label:"Ecuadorean"},
            {value:"Guamanian",label:"Guamanian"},
            {value:"Kuwaiti",label:"Kuwaiti"},
            {value:"Kirghiz",label:"Kirghiz"},
            {value:"Mauritian",label:"Mauritian"},
            {value:"Ålandish",label:"Ålandish"},
            {value:"Bahraini",label:"Bahraini"},
            {value:"Mexican",label:"Mexican"},
            {value:"Polish",label:"Polish"},
            {value:"Chilean",label:"Chilean"},
            {value:"Djibouti",label:"Djibouti"},
            {value:"Ethiopian",label:"Ethiopian"},
            {value:"Mauritanian",label:"Mauritanian"},
            {value:"North Korean",label:"North Korean"},
            {value:"Swazi",label:"Swazi"},
            {value:"Aruban",label:"Aruban"},
            {value:"Austrian",label:"Austrian"},
            {value:"Haitian",label:"Haitian"},
            {value:"Portuguese",label:"Portuguese"},
            {value:"Ni-Vanuatu",label:"Ni-Vanuatu"},
            {value:"Christmas Island",label:"Christmas Island"},
            {value:"Colombian",label:"Colombian"},
            {value:"Cuban",label:"Cuban"},
            {value:"French Guianese",label:"French Guianese"},
            {value:"Afghan",label:"Afghan"},
            {value:"Bruneian",label:"Bruneian"},
            {value:"Jamaican",label:"Jamaican"},
            {value:"Moldovan",label:"Moldovan"},
            {value:"French",label:"French"},
            {value:"Kenyan",label:"Kenyan"},
            {value:"Omani",label:"Omani"},
            {value:"Albanian",label:"Albanian"},
            {value:"Cape Verdian",label:"Cape Verdian"},
            {value:"Turkish",label:"Turkish"},
            {value:"Zimbabwean",label:"Zimbabwean"},
            {value:"Algerian",label:"Algerian"},
            {value:"Nigerien",label:"Nigerien"},
            {value:"Hungarian",label:"Hungarian"},
            {value:"Spanish",label:"Spanish"},
            {value:"German",label:"German"},
            {value:"Guatemalan",label:"Guatemalan"},
            {value:"Mozambican",label:"Mozambican"},
            {value:"Palauan",label:"Palauan"},
            {value:"Mosotho",label:"Mosotho"},
            {value:"Marshallese",label:"Marshallese"},
            {value:"Surinamer",label:"Surinamer"},
            {value:"Guyanese",label:"Guyanese"},
            {value:"Sri Lankan",label:"Sri Lankan"},
            {value:"Lebanese",label:"Lebanese"},
            {value:"Mahoran",label:"Mahoran"},
            {value:"Samoan",label:"Samoan"},
            {value:"Solomon Islander",label:"Solomon Islander"},
            {value:"Danish",label:"Danish"},
            {value:"I-Kiribati",label:"I-Kiribati"},
            {value:"Swiss",label:"Swiss"},
            {value:"Lithuanian",label:"Lithuanian"},
            {value:"Somali",label:"Somali"},
            {value:"Norfolk Islander",label:"Norfolk Islander"},
            {value:"Taiwanese",label:"Taiwanese"},
            {value:"Ghanaian",label:"Ghanaian"},
            {value:"New Zealander",label:"New Zealander"},
            {value:"Senegalese",label:"Senegalese"},
            {value:"Togolese",label:"Togolese"},
            {value:"Zambian",label:"Zambian"},
            {value:"Belarusian",label:"Belarusian"},
            {value:"Saint Vincentian",label:"Saint Vincentian"},
            {value:"Salvadoran",label:"Salvadoran"},
            {value:"Norwegian",label:"Norwegian"},
            {value:"Maltese",label:"Maltese"},
            {value:"Nicaraguan",label:"Nicaraguan"},
            {value:"Australian",label:"Australian"},
            {value:"Luxembourger",label:"Luxembourger"},
            {value:"Pakistani",label:"Pakistani"},
            {value:"Saint Helenian",label:"Saint Helenian"},
            {value:"Turks and Caicos Islander",label:"Turks and Caicos Islander"},
            {value:"Sahrawi",label:"Sahrawi"},
            {value:"Belgian",label:"Belgian"},
            {value:"Eritrean",label:"Eritrean"},
            {value:"Seychellois",label:"Seychellois"},
            {value:"Antiguan, Barbudan",label:"Antiguan, Barbudan"},
            {value:"Liberian",label:"Liberian"},
            {value:"Tuvaluan",label:"Tuvaluan"},
            {value:"Bermudian",label:"Bermudian"},
            {value:"Cameroonian",label:"Cameroonian"},
            {value:"Saint-Pierrais",label:"Saint-Pierrais"},
            {value:"Ukrainian",label:"Ukrainian"},
            {value:"Georgian",label:"Georgian"},
            {value:"Dutch",label:"Dutch"},
            {value:"Malian",label:"Malian"},
            {value:"Niuean",label:"Niuean"},
            {value:"Puerto Rican",label:"Puerto Rican"},
            {value:"British",label:"British"},
            {value:"Armenian",label:"Armenian"},
            {value:"Greenlandic",label:"Greenlandic"},
            {value:"Kazakhstani",label:"Kazakhstani"},
            {value:"Réunionese",label:"Réunionese"},
            {value:"Saint Martin Islander",label:"Saint Martin Islander"},
            {value:"Belizean",label:"Belizean"},
            {value:"Greek",label:"Greek"},
            {value:"Trinidadian",label:"Trinidadian"},
            {value:"Beninese",label:"Beninese"},
            {value:"Tadzhik",label:"Tadzhik"},
            {value:"Czech",label:"Czech"},
            {value:"Indian",label:"Indian"},
            {value:"Laotian",label:"Laotian"},
            {value:"Syrian",label:"Syrian"},
            {value:"Turkmen",label:"Turkmen"},
            {value:"Motswana",label:"Motswana"},
            {value:"Virgin Islander (British)",label:"Virgin Islander (British)"},
            {value:"American Samoan",label:"American Samoan"},
            {value:"Guadeloupian",label:"Guadeloupian"},
            {value:"New Caledonian",label:"New Caledonian"},
            {value:"Vietnamese",label:"Vietnamese"},
            {value:"Comoran",label:"Comoran"},
            {value:"Micronesian",label:"Micronesian"},
            {value:"Burundian",label:"Burundian"},
            {value:"Moroccan",label:"Moroccan"},
            {value:"Saint Barthélemy Islander",label:"Saint Barthélemy Islander"},
            {value:"Barbadian",label:"Barbadian"},
            {value:"Virgin Islander (U.S.)",label:"Virgin Islander (U.S.)"},
            {value:"Iraqi",label:"Iraqi"},
            {value:"Papua New Guinean",label:"Papua New Guinean"},
            {value:"Equatorial Guinean",label:"Equatorial Guinean"},
            {value:"Gabonese",label:"Gabonese"},
            {value:"Uruguayan",label:"Uruguayan"},
            {value:"Monegasque",label:"Monegasque"},
            {value:"Tunisian",label:"Tunisian"},
            {value:"Fijian",label:"Fijian"},
            {value:"Rwandan",label:"Rwandan"},
            {value:"Angolan",label:"Angolan"},
            {value:"Falkland Islander",label:"Falkland Islander"},
            {value:"Serbian",label:"Serbian"},
            {value:"Slovenian",label:"Slovenian"},
            {value:"Congolese (Democratic Republic)",label:"Congolese (Democratic Republic)"},
            {value:"Malawian",label:"Malawian"},
            {value:"Venezuelan",label:"Venezuelan"},
            {value:"Bulgarian",label:"Bulgarian"},
            {value:"Hong Kongese",label:"Hong Kongese"},
            {value:"Gibraltar",label:"Gibraltar"},
            {value:"South Sudanese",label:"South Sudanese"},
            {value:"Nauruan",label:"Nauruan"},
            {value:"Pitcairn Islander",label:"Pitcairn Islander"},
            {value:"Russian",label:"Russian"},
            {value:"Andorran",label:"Andorran"},
            {value:"Faroese",label:"Faroese"},
            {value:"Congolese",label:"Congolese"},
            {value:"Croatian",label:"Croatian"},
            {value:"Montserratian",label:"Montserratian"},
            {value:"Filipino",label:"Filipino"},
            {value:"Kosovar",label:"Kosovar"},
            {value:"Romanian",label:"Romanian"},
            {value:"Tokelauan",label:"Tokelauan"},
            {value:"Bahamian",label:"Bahamian"},
            {value:"Estonian",label:"Estonian"}
     
   
  ]
  export const Countries = [
          
                    {value:"Peru",label:"Peru"},
                    {value:"Brazil",label:"Brazil"},
                    {value:"Libya",label:"Libya"},
                    {value:"San Marino",label:"San Marino"},
                    {value:"Thailand",label:"Thailand"},
                    {value:"Malaysia",label:"Malaysia"},
                    {value:"Paraguay",label:"Paraguay"},
                    {value:"Namibia",label:"Namibia"},
                    {value:"Slovakia",label:"Slovakia"},
                    {value:"China",label:"China"},
                    {value:"Honduras",label:"Honduras"},
                    {value:"Uganda",label:"Uganda"},
                    {value:"Indonesia",label:"Indonesia"},
                    {value:"South Georgia and the South Sandwich Islands",label:"South Georgia and the South Sandwich Islands"},
                    {value:"Yemen",label:"Yemen"},
                    {value:"Azerbaijan",label:"Azerbaijan"},
                    {value:"Israel",label:"Israel"},
                    {value:"Singapore",label:"Singapore"},
                    {value:"United Arab Emirates",label:"United Arab Emirates"},
                    {value:"Bolivia (Plurinational State of)",label:"Bolivia (Plurinational State of)"},
                    {value:"Ireland",label:"Ireland"},
                    {value:"Central African Republic",label:"Central African Republic"},
                    {value:"French Polynesia",label:"French Polynesia"},
                    {value:"Grenada",label:"Grenada"},
                    {value:"Guinea-Bissau",label:"Guinea-Bissau"},
                    {value:"Nepal",label:"Nepal"},
                    {value:"Panama",label:"Panama"},
                    {value:"Burkina Faso",label:"Burkina Faso"},
                    {value:"Cambodia",label:"Cambodia"},
                    {value:"Uzbekistan",label:"Uzbekistan"},
                    {value:"Anguilla",label:"Anguilla"},
                    {value:"Korea (Republic of)",label:"Korea (Republic of)"},
                    {value:"Curaçao",label:"Curaçao"},
                    {value:"Wallis and Futuna",label:"Wallis and Futuna"},
                    {value:"Myanmar",label:"Myanmar"},
                    {value:"Egypt",label:"Egypt"},
                    {value:"Gambia",label:"Gambia"},
                    {value:"Saudi Arabia",label:"Saudi Arabia"},
                    {value:"Argentina",label:"Argentina"},
                    {value:"Canada",label:"Canada"},
                    {value:"Montenegro",label:"Montenegro"},
                    {value:"United States of America",label:"United States of America"},
                    {value:"Cook Islands",label:"Cook Islands"},
                    {value:"Macao",label:"Macao"},
                    {value:"Cocos (Keeling) Islands",label:"Cocos (Keeling) Islands"},
                    {value:"Saint Lucia",label:"Saint Lucia"},
                    {value:"Cyprus",label:"Cyprus"},
                    {value:"Iceland",label:"Iceland"},
                    {value:"Côte d'Ivoire",label:"Côte d'Ivoire"},
                    {value:"Bangladesh",label:"Bangladesh"},
                    {value:"Bhutan",label:"Bhutan"},
                    {value:"Saint Kitts and Nevis",label:"Saint Kitts and Nevis"},
                    {value:"Finland",label:"Finland"},
                    {value:"Macedonia (the former Yugoslav Republic of)",label:"Macedonia (the former Yugoslav Republic of)"},
                    {value:"Qatar",label:"Qatar"},
                    {value:"Sudan",label:"Sudan"},
                    {value:"Isle of Man",label:"Isle of Man"},
                    {value:"Latvia",label:"Latvia"},
                    {value:"Timor-Leste",label:"Timor-Leste"},
                    {value:"Sao Tome and Principe",label:"Sao Tome and Principe"},
                    {value:"Sweden",label:"Sweden"},
                    {value:"Martinique",label:"Martinique"},
                    {value:"Sierra Leone",label:"Sierra Leone"},
                    {value:"South Africa",label:"South Africa"},
                    {value:"Tanzania, United Republic of",label:"Tanzania, United Republic of"},
                    {value:"Costa Rica",label:"Costa Rica"},
                    {value:"Iran (Islamic Republic of)",label:"Iran (Islamic Republic of)"},
                    {value:"Dominican Republic",label:"Dominican Republic"},
                    {value:"Nigeria",label:"Nigeria"},
                    {value:"Palestine, State of",label:"Palestine, State of"},
                    {value:"Bosnia and Herzegovina",label:"Bosnia and Herzegovina"},
                    {value:"Cayman Islands",label:"Cayman Islands"},
                    {value:"Maldives",label:"Maldives"},
                    {value:"Japan",label:"Japan"},
                    {value:"Chad",label:"Chad"},
                    {value:"Guinea",label:"Guinea"},
                    {value:"Madagascar",label:"Madagascar"},
                    {value:"Italy",label:"Italy"},
                    {value:"Jordan",label:"Jordan"},
                    {value:"Liechtenstein",label:"Liechtenstein"},
                    {value:"Mongolia",label:"Mongolia"},
                    {value:"Tonga",label:"Tonga"},
                    {value:"Ecuador",label:"Ecuador"},
                    {value:"Guam",label:"Guam"},
                    {value:"Kuwait",label:"Kuwait"},
                    {value:"Kyrgyzstan",label:"Kyrgyzstan"},
                    {value:"Mauritius",label:"Mauritius"},
                    {value:"Åland Islands",label:"Åland Islands"},
                    {value:"Bahrain",label:"Bahrain"},
                    {value:"Mexico",label:"Mexico"},
                    {value:"Poland",label:"Poland"},
                    {value:"Chile",label:"Chile"},
                    {value:"Djibouti",label:"Djibouti"},
                    {value:"Ethiopia",label:"Ethiopia"},
                    {value:"Mauritania",label:"Mauritania"},
                    {value:"Korea (Democratic People's Republic of)",label:"Korea (Democratic People's Republic of)"},
                    {value:"Swaziland",label:"Swaziland"},
                    {value:"Aruba",label:"Aruba"},
                    {value:"Austria",label:"Austria"},
                    {value:"Haiti",label:"Haiti"},
                    {value:"Portugal",label:"Portugal"},
                    {value:"Vanuatu",label:"Vanuatu"},
                    {value:"Christmas Island",label:"Christmas Island"},
                    {value:"Colombia",label:"Colombia"},
                    {value:"Cuba",label:"Cuba"},
                    {value:"French Guiana",label:"French Guiana"},
                    {value:"Afghanistan",label:"Afghanistan"},
                    {value:"Brunei Darussalam",label:"Brunei Darussalam"},
                    {value:"Jamaica",label:"Jamaica"},
                    {value:"Moldova (Republic of)",label:"Moldova (Republic of)"},
                    {value:"France",label:"France"},
                    {value:"Kenya",label:"Kenya"},
                    {value:"Oman",label:"Oman"},
                    {value:"Albania",label:"Albania"},
                    {value:"Cabo Verde",label:"Cabo Verde"},
                    {value:"Turkey",label:"Turkey"},
                    {value:"Zimbabwe",label:"Zimbabwe"},
                    {value:"Algeria",label:"Algeria"},
                    {value:"Niger",label:"Niger"},
                    {value:"Hungary",label:"Hungary"},
                    {value:"Spain",label:"Spain"},
                    {value:"Germany",label:"Germany"},
                    {value:"Guatemala",label:"Guatemala"},
                    {value:"Mozambique",label:"Mozambique"},
                    {value:"Palau",label:"Palau"},
                    {value:"Lesotho",label:"Lesotho"},
                    {value:"Marshall Islands",label:"Marshall Islands"},
                    {value:"Suriname",label:"Suriname"},
                    {value:"Guyana",label:"Guyana"},
                    {value:"Sri Lanka",label:"Sri Lanka"},
                    {value:"Lebanon",label:"Lebanon"},
                    {value:"Mayotte",label:"Mayotte"},
                    {value:"Samoa",label:"Samoa"},
                    {value:"Solomon Islands",label:"Solomon Islands"},
                    {value:"Denmark",label:"Denmark"},
                    {value:"Kiribati",label:"Kiribati"},
                    {value:"Switzerland",label:"Switzerland"},
                    {value:"Lithuania",label:"Lithuania"},
                    {value:"Somalia",label:"Somalia"},
                    {value:"Norfolk Island",label:"Norfolk Island"},
                    {value:"Taiwan",label:"Taiwan"},
                    {value:"Ghana",label:"Ghana"},
                    {value:"New Zealand",label:"New Zealand"},
                    {value:"Senegal",label:"Senegal"},
                    {value:"Togo",label:"Togo"},
                    {value:"Zambia",label:"Zambia"},
                    {value:"Belarus",label:"Belarus"},
                    {value:"Saint Vincent and the Grenadines",label:"Saint Vincent and the Grenadines"},
                    {value:"El Salvador",label:"El Salvador"},
                    {value:"Norway",label:"Norway"},
                    {value:"Malta",label:"Malta"},
                    {value:"Nicaragua",label:"Nicaragua"},
                    {value:"Australia",label:"Australia"},
                    {value:"Luxembourg",label:"Luxembourg"},
                    {value:"Pakistan",label:"Pakistan"},
                    {value:"Saint Helena, Ascension and Tristan da Cunha",label:"Saint Helena, Ascension and Tristan da Cunha"},
                    {value:"Turks and Caicos Islands",label:"Turks and Caicos Islands"},
                    {value:"Western Sahara",label:"Western Sahara"},
                    {value:"Belgium",label:"Belgium"},
                    {value:"Eritrea",label:"Eritrea"},
                    {value:"Seychelles",label:"Seychelles"},
                    {value:"Antigua and Barbuda",label:"Antigua and Barbuda"},
                    {value:"Liberia",label:"Liberia"},
                    {value:"Tuvalu",label:"Tuvalu"},
                    {value:"Bermuda",label:"Bermuda"},
                    {value:"Cameroon",label:"Cameroon"},
                    {value:"Saint Pierre and Miquelon",label:"Saint Pierre and Miquelon"},
                    {value:"Ukraine",label:"Ukraine"},
                    {value:"Georgia",label:"Georgia"},
                    {value:"Netherlands",label:"Netherlands"},
                    {value:"Mali",label:"Mali"},
                    {value:"Niue",label:"Niue"},
                    {value:"Puerto Rico",label:"Puerto Rico"},
                    {value:"United Kingdom of Great Britain and Northern Ireland",label:"United Kingdom of Great Britain and Northern Ireland"},
                    {value:"Armenia",label:"Armenia"},
                    {value:"Greenland",label:"Greenland"},
                    {value:"Kazakhstan",label:"Kazakhstan"},
                    {value:"Réunion",label:"Réunion"},
                    {value:"Saint Martin (French part)",label:"Saint Martin (French part)"},
                    {value:"Belize",label:"Belize"},
                    {value:"Greece",label:"Greece"},
                    {value:"Trinidad and Tobago",label:"Trinidad and Tobago"},
                    {value:"Benin",label:"Benin"},
                    {value:"Tajikistan",label:"Tajikistan"},
                    {value:"Czech Republic",label:"Czech Republic"},
                    {value:"India",label:"India"},
                    {value:"Lao People's Democratic Republic",label:"Lao People's Democratic Republic"},
                    {value:"Syrian Arab Republic",label:"Syrian Arab Republic"},
                    {value:"Turkmenistan",label:"Turkmenistan"},
                    {value:"Botswana",label:"Botswana"},
                    {value:"Virgin Islands (British)",label:"Virgin Islands (British)"},
                    {value:"American Samoa",label:"American Samoa"},
                    {value:"Guadeloupe",label:"Guadeloupe"},
                    {value:"New Caledonia",label:"New Caledonia"},
                    {value:"Viet Nam",label:"Viet Nam"},
                    {value:"Comoros",label:"Comoros"},
                    {value:"Micronesia (Federated States of)",label:"Micronesia (Federated States of)"},
                    {value:"Burundi",label:"Burundi"},
                    {value:"Morocco",label:"Morocco"},
                    {value:"Saint Barthélemy",label:"Saint Barthélemy"},
                    {value:"Barbados",label:"Barbados"},
                    {value:"Virgin Islands (U.S.)",label:"Virgin Islands (U.S.)"},
                    {value:"Iraq",label:"Iraq"},
                    {value:"Papua New Guinea",label:"Papua New Guinea"},
                    {value:"Equatorial Guinea",label:"Equatorial Guinea"},
                    {value:"Gabon",label:"Gabon"},
                    {value:"Uruguay",label:"Uruguay"},
                    {value:"Monaco",label:"Monaco"},
                    {value:"Tunisia",label:"Tunisia"},
                    {value:"Fiji",label:"Fiji"},
                    {value:"Rwanda",label:"Rwanda"},
                    {value:"Angola",label:"Angola"},
                    {value:"Falkland Islands (Malvinas)",label:"Falkland Islands (Malvinas)"},
                    {value:"Serbia",label:"Serbia"},
                    {value:"Slovenia",label:"Slovenia"},
                    {value:"Congo (Democratic Republic of the)",label:"Congo (Democratic Republic of the)"},
                    {value:"Malawi",label:"Malawi"},
                    {value:"Venezuela (Bolivarian Republic of)",label:"Venezuela (Bolivarian Republic of)"},
                    {value:"Bulgaria",label:"Bulgaria"},
                    {value:"Hong Kong",label:"Hong Kong"},
                    {value:"Gibraltar",label:"Gibraltar"},
                    {value:"South Sudan",label:"South Sudan"},
                    {value:"Nauru",label:"Nauru"},
                    {value:"Pitcairn",label:"Pitcairn"},
                    {value:"Russian Federation",label:"Russian Federation"},
                    {value:"Andorra",label:"Andorra"},
                    {value:"Faroe Islands",label:"Faroe Islands"},
                    {value:"Congo",label:"Congo"},
                    {value:"Croatia",label:"Croatia"},
                    {value:"Montserrat",label:"Montserrat"},
                    {value:"Philippines",label:"Philippines"},
                    {value:"Republic of Kosovo",label:"Republic of Kosovo"},
                    {value:"Romania",label:"Romania"},
                    {value:"Tokelau",label:"Tokelau"},
                    {value:"Bahamas",label:"Bahamas"},
                    {value:"Estonia",label:"Estonia"}
        
  ]
  export const Currencies = [

            {value:"AED",label: "United Arab Emirates Dirham"},
            {value:"AFN",label: "Afghan Afghani"},
            {value:"ALL",label: "Albanian Lek"},
            {value:"AMD",label: "Armenian Dram"},
            {value:"ANG",label: "Netherlands Antillean Guilder"},
            {value:"AOA",label: "Angolan Kwanza"},
            {value:"ARS",label: "Argentine Peso"},
            {value:"AUD",label: "Australian Dollar"},
            {value:"AWG",label: "Aruban Florin"},
            {value:"AZN",label: "Azerbaijani Manat"},
            {value:"BAM",label: "Bosnia-Herzegovina Convertible Mark"},
            {value:"BBD",label: "Barbadian Dollar"},
            {value:"BDT",label: "Bangladeshi Taka"},
            {value:"BGN",label: "Bulgarian Lev"},
            {value:"BHD",label: "Bahraini Dinar"},
            {value:"BIF",label: "Burundian Franc"},
            {value:"BMD",label: "Bermudan Dollar"},
            {value:"BND",label: "Brunei Dollar"},
            {value:"BOB",label: "Bolivian Boliviano"},
            {value:"BRL",label: "Brazilian Real"},
            {value:"BSD",label: "Bahamian Dollar"},
            {value:"BTC",label: "Bitcoin"},
            {value:"BTN",label: "Bhutanese Ngultrum"},
            {value:"BWP",label: "Botswanan Pula"},
            {value:"BYN",label: "Belarusian Ruble"},
            {value:"BZD",label: "Belize Dollar"},
            {value:"CAD",label: "Canadian Dollar"},
            {value:"CDF",label: "Congolese Franc"},
            {value:"CHF",label: "Swiss Franc"},
            {value:"CLF",label: "Chilean Unit of Account (UF)"},
            {value:"CLP",label: "Chilean Peso"},
            {value:"CNH",label: "Chinese Yuan (Offshore)"},
            {value:"CNY",label: "Chinese Yuan"},
            {value:"COP",label: "Colombian Peso"},
            {value:"CRC",label: "Costa Rican Colón"},
            {value:"CUC",label: "Cuban Convertible Peso"},
            {value:"CUP",label: "Cuban Peso"},
            {value:"CVE",label: "Cape Verdean Escudo"},
            {value:"CZK",label: "Czech Republic Koruna"},
            {value:"DJF",label: "Djiboutian Franc"},
            {value:"DKK",label: "Danish Krone"},
            {value:"DOP",label: "Dominican Peso"},
            {value:"DZD",label: "Algerian Dinar"},
            {value:"EGP",label: "Egyptian Pound"},
            {value:"ERN",label: "Eritrean Nakfa"},
            {value:"ETB",label: "Ethiopian Birr"},
            {value:"EUR",label: "Euro"},
            {value:"FJD",label: "Fijian Dollar"},
            {value:"FKP",label: "Falkland Islands Pound"},
            {value:"GBP",label: "British Pound Sterling"},
            {value:"GEL",label: "Georgian Lari"},
            {value:"GGP",label: "Guernsey Pound"},
            {value:"GHS",label: "Ghanaian Cedi"},
            {value:"GIP",label: "Gibraltar Pound"},
            {value:"GMD",label: "Gambian Dalasi"},
            {value:"GNF",label: "Guinean Franc"},
            {value:"GTQ",label: "Guatemalan Quetzal"},
            {value:"GYD",label: "Guyanaese Dollar"},
            {value:"HKD",label: "Hong Kong Dollar"},
            {value:"HNL",label: "Honduran Lempira"},
            {value:"HRK",label: "Croatian Kuna"},
            {value:"HTG",label: "Haitian Gourde"},
            {value:"HUF",label: "Hungarian Forint"},
            {value:"IDR",label: "Indonesian Rupiah"},
            {value:"ILS",label: "Israeli New Sheqel"},
            {value:"IMP",label: "Manx pound"},
            {value:"INR",label: "Indian Rupee"},
            {value:"IQD",label: "Iraqi Dinar"},
            {value:"IRR",label: "Iranian Rial"},
            {value:"ISK",label: "Icelandic Króna"},
            {value:"JEP",label: "Jersey Pound"},
            {value:"JMD",label: "Jamaican Dollar"},
            {value:"JOD",label: "Jordanian Dinar"},
            {value:"JPY",label: "Japanese Yen"},
            {value:"KES",label: "Kenyan Shilling"},
            {value:"KGS",label: "Kyrgystani Som"},
            {value:"KHR",label: "Cambodian Riel"},
            {value:"KMF",label: "Comorian Franc"},
            {value:"KPW",label: "North Korean Won"},
            {value:"KRW",label: "South Korean Won"},
            {value:"KWD",label: "Kuwaiti Dinar"},
            {value:"KYD",label: "Cayman Islands Dollar"},
            {value:"KZT",label: "Kazakhstani Tenge"},
            {value:"LAK",label: "Laotian Kip"},
            {value:"LBP",label: "Lebanese Pound"},
            {value:"LKR",label: "Sri Lankan Rupee"},
            {value:"LRD",label: "Liberian Dollar"},
            {value:"LSL",label: "Lesotho Loti"},
            {value:"LYD",label: "Libyan Dinar"},
            {value:"MAD",label: "Moroccan Dirham"},
            {value:"MDL",label: "Moldovan Leu"},
            {value:"MGA",label: "Malagasy Ariary"},
            {value:"MKD",label: "Macedonian Denar"},
            {value:"MMK",label: "Myanma Kyat"},
            {value:"MNT",label: "Mongolian Tugrik"},
            {value:"MOP",label: "Macanese Pataca"},
            {value:"MRU",label: "Mauritanian Ouguiya"},
            {value:"MUR",label: "Mauritian Rupee"},
            {value:"MVR",label: "Maldivian Rufiyaa"},
            {value:"MWK",label: "Malawian Kwacha"},
            {value:"MXN",label: "Mexican Peso"},
            {value:"MYR",label: "Malaysian Ringgit"},
            {value:"MZN",label: "Mozambican Metical"},
            {value:"NAD",label: "Namibian Dollar"},
            {value:"NGN",label: "Nigerian Naira"},
            {value:"NIO",label: "Nicaraguan Córdoba"},
            {value:"NOK",label: "Norwegian Krone"},
            {value:"NPR",label: "Nepalese Rupee"},
            {value:"NZD",label: "New Zealand Dollar"},
            {value:"OMR",label: "Omani Rial"},
            {value:"PAB",label: "Panamanian Balboa"},
            {value:"PEN",label: "Peruvian Nuevo Sol"},
            {value:"PGK",label: "Papua New Guinean Kina"},
            {value:"PHP",label: "Philippine Peso"},
            {value:"PKR",label: "Pakistani Rupee"},
            {value:"PLN",label: "Polish Zloty"},
            {value:"PYG",label: "Paraguayan Guarani"},
            {value:"QAR",label: "Qatari Rial"},
            {value:"RON",label: "Romanian Leu"},
            {value:"RSD",label: "Serbian Dinar"},
            {value:"RUB",label: "Russian Ruble"},
            {value:"RWF",label: "Rwandan Franc"},
            {value:"SAR",label: "Saudi Riyal"},
            {value:"SBD",label: "Solomon Islands Dollar"},
            {value:"SCR",label: "Seychellois Rupee"},
            {value:"SDG",label: "Sudanese Pound"},
            {value:"SEK",label: "Swedish Krona"},
            {value:"SGD",label: "Singapore Dollar"},
            {value:"SHP",label: "Saint Helena Pound"},
            {value:"SLL",label: "Sierra Leonean Leone"},
            {value:"SOS",label: "Somali Shilling"},
            {value:"SRD",label: "Surinamese Dollar"},
            {value:"SSP",label: "South Sudanese Pound"},
            {value:"STD",label: "São Tomé and Príncipe Dobra (pre-2018)"},
            {value:"STN",label: "São Tomé and Príncipe Dobra"},
            {value:"SVC",label: "Salvadoran Colón"},
            {value:"SYP",label: "Syrian Pound"},
            {value:"SZL",label: "Swazi Lilangeni"},
            {value:"THB",label: "Thai Baht"},
            {value:"TJS",label: "Tajikistani Somoni"},
            {value:"TMT",label: "Turkmenistani Manat"},
            {value:"TND",label: "Tunisian Dinar"},
            {value:"TOP",label: "Tongan Pa'anga"},
            {value:"TRY",label: "Turkish Lira"},
            {value:"TTD",label: "Trinidad and Tobago Dollar"},
            {value:"TWD",label: "New Taiwan Dollar"},
            {value:"TZS",label: "Tanzanian Shilling"},
            {value:"UAH",label: "Ukrainian Hryvnia"},
            {value:"UGX",label: "Ugandan Shilling"},
            {value:"USD",label: "United States Dollar"},
            {value:"UYU",label: "Uruguayan Peso"},
            {value:"UZS",label: "Uzbekistan Som"},
            {value:"VEF",label: "Venezuelan Bolívar Fuerte (Old)"},
            {value:"VES",label: "Venezuelan Bolívar Soberano"},
            {value:"VND",label: "Vietnamese Dong"},
            {value:"VUV",label: "Vanuatu Vatu"},
            {value:"WST",label: "Samoan Tala"},
            {value:"XAF",label: "CFA Franc BEAC"},
            {value:"XAG",label: "Silver Ounce"},
            {value:"XAU",label: "Gold Ounce"},
            {value:"XCD",label: "East Caribbean Dollar"},
            {value:"XDR",label: "Special Drawing Rights"},
            {value:"XOF",label: "CFA Franc BCEAO"},
            {value:"XPD",label: "Palladium Ounce"},
            {value:"XPF",label: "CFP Franc"},
            {value:"XPT",label: "Platinum Ounce"},
            {value:"YER",label: "Yemeni Rial"},
            {value:"ZAR",label: "South African Rand"},
            {value:"ZMW",label: "Zambian Kwacha"},
            {value:"ZWL",label: "Zimbabwean Dollar"}
 
  ]

