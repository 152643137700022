import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
import Select from 'react-select'
//Import Breadcrumb
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import avatar1 from "../../assets/images/users/avatar-1.jpg"
const NewEmployeeLayout = props => {

  //meta title
  document.title = "Add Employee | HR Dashboard";
  const [selectedImage, setSelectedImage] = React.useState();
  // const [profileImage, setProfileImage] = React.useState();
  const [toggleSwitchSize, settoggleSwitchSize] = React.useState(false)
  const [firstname,setFirstName] = React.useState('');
  const [lastname,setLastName] = React.useState('');
  const [employeeID,setemployeeID] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password,setPassword] = React.useState('');
  const [confirmPassword,setConfirmPassword]= React.useState('');
  const [role, setRole] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [shiftStart, setShiftStart] = React.useState('');
  const [shiftEnd, setShiftEnd] = React.useState('');
  const [extraHours, setExtraHours] = React.useState('');
  const [leaveRight, setLeaveRight] = React.useState('false');
  const [addEmployee, setAddEmployee] = React.useState('false');
  const [markAttendance, setMarkAttendance] = React.useState('false');
  const [byManual, setByManual] = React.useState('false');
  const [byFace, setByFace] = React.useState('false');
  const [area, setArea] = React.useState('');
  const [department, setDepartment] = React.useState('');
  const [fetchLocation, setFetchLocation] = React.useState([]);
  const [extraHoursStatus, setExtraHoursStatus] = React.useState('false');
  
  
  // This function will be triggered when the file field change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
      
      
    }
  };
  useEffect(()=>{
    let auth = JSON.parse(localStorage.getItem('authUser'));
    auth.map((e)=>{
      fetchEmployees(e.role);
    })
    
  },[])
  const fetchEmployees = (role)=>{
      var formdata = new FormData();
      formdata.append("method", "fetch_company");
      formdata.append('role',role);
    
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };
    
      fetch("https://api.frontforcecrm.com/company.php", requestOptions)
        .then(response =>response.json())
        .then((result) => {
          result.map((e)=>{
            let array = {
              label:e.location ,
              value:e.location
            }
            fetchLocation.push(array)
          })
  
        })
        .catch(error => console.log('error', error));
    
  
}
  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedImage();
  };
  const handleOptionChange = (event) => {
    setStatus(event.target.value);
  };
  const handleOptionExtraStatus = (event) => {
    setExtraHoursStatus(event.target.value);
  };
  const handleOptionChanges = (event) => {
    setAddEmployee(event.target.value);
  };
  const handleOptionMark = (event) => {
    setMarkAttendance(event.target.value);
  };
  const handleOptionLeave = (event) => {
    setLeaveRight(event.target.value);
  };
  const handleOptionManual = (event) => {
    setByManual(event.target.value);
  };
  const handleOptionFace = (event) => {
    setByFace(event.target.value);
  };
  const handleAreaChange = (event) => {
    setArea(event.value);
  };
  const handleDepChange = (event) => {
    setDepartment(event.value);
  };
  const SubmitButton = () =>{
   
   if(firstname == ""){
    toastr.error('Error','First name is required');
    return false;
   }else if(lastname== ""){
    toastr.error('Error','Last name is required');
    return false;
   }else if(employeeID == ""){
    toastr.error('Error','Hiring Date is required');
    return false;
   }else{
    if(toggleSwitchSize === true){
      if(username == ""){
        toastr.error('Error','Username is required');
        return false;
      }else if(password == ""){
        toastr.error('Error','Password is required');
        return false;
      }else if(password != confirmPassword){
        toastr.error('Error','Password are not matched');
        return false;
      }else if(role == ""){
        toastr.error('Error','Select the user role');
        return false;
      }else if(status == ""){
        toastr.error('Error','Please check the status');
        return false;
      }
      else{
        
        
         let form = new FormData();
         form.append('first_name',firstname);
         form.append('last_name',lastname);
         form.append('username',username);
         form.append('password',password);
         form.append('role',role);
         form.append('status',status);
         form.append('area',area);
         form.append('department',department);
         form.append('hire_date',employeeID);
         form.append('method','register_user');
         form.append('profileimage',selectedImage);
         form.append('other_method','isLogin');
         form.append('shift_Start',shiftStart);
         form.append('shift_End',shiftEnd);
         form.append('hours_status',extraHoursStatus);
         form.append('no_of_hours',extraHours);
         form.append('leave_right',leaveRight);
         form.append('addEmployeeRight',addEmployee);
         form.append('markAttendanceRight',markAttendance);
         form.append('byManual',byManual);
         form.append('byFaceRecong',byFace);

         var requestOptions = {
          method: 'POST',
          body: form,
          redirect: 'follow',
          header:{
            "Content-Type": "multipart/form-data",
          }
        };
        
        fetch("https://api.frontforcecrm.com/login.php", requestOptions)
          .then(response => response.text())
          .then((result) => {
            if(result == 'success'){
              toastr.success('Success','Employee is added');
              setFirstName('')
              setLastName('')
              setemployeeID('')
              setUsername('')
              setPassword('')
              setConfirmPassword('')
              setRole('')
              setStatus('')
            }else{
              toastr.error('Error',`${result}`);
            }
          })
          .catch(error => console.log('error', error));

       }
     }else if(toggleSwitchSize == false){
     
      let form = new FormData();
         form.append('first_name',firstname);
         form.append('last_name',lastname);
         form.append('method','register_user');
         form.append('area',area);
         form.append('department',department);
         form.append('hire_date',employeeID);
         form.append('profileimage',selectedImage);
         form.append('other_method','isNotLogin');

         var requestOptions = {
          method: 'POST',
          body: form,
          redirect: 'follow',
          header:{
            "Content-Type": "multipart/form-data",
          }
        };
        
        fetch("https://api.frontforcecrm.com/login.php", requestOptions)
          .then(response => response.text())
          .then((result) =>{
            if(result == 'success'){
              toastr.success('Success','Employee is added');
              setFirstName('')
              setLastName('')
              setemployeeID('')
              setUsername('')
              setPassword('')
              setConfirmPassword('')
              setRole('')
              setStatus('')
            }else{
              toastr.error('Error',`${result}`);
            }
          })
          .catch(error => console.log('error', error));
     }
      
    }
    
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="" breadcrumbItem="Add Employee" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Add Employee
                  <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                  </CardTitle>
                    <Row>
                      <Col md={4} style={{textAlign:'center'}}>
                      {selectedImage ? (
                        <div  style={{ margin:0 }}>
                            <img
                            src={URL.createObjectURL(selectedImage)}
                            className="img-thumbnail rounded-circle"
                            alt="Thumb"
                            width={150}
                            />
                            
                            <br></br>
                            
                            <Label onClick={removeSelectedImage} style={{ paddingTop:10,textDecoration:'underline' }}  >Remove Image</Label>
                        </div>
                        ):
                        <div className="" style={{ margin:0 }}>
                     
                                <img
                                src={avatar1}
                                alt=""
                                width={150}
                                className="img-thumbnail rounded-circle"
                                /> 
                          <br></br>
                            <input type="file" id="file_for_image" onChange={imageChange} style={{ display:'none' }}  />
                             <Label style={{ paddingTop:10,textDecoration:'underline' }}  for="file_for_image">Change Image</Label>
                             </div>
                    }
                        
                      </Col>
                      <Col md={8}>
                        <Row>
                            <Col md={5}>
                            <div className="mb-3">
                            <Label htmlFor="formrow-first-Input">First Name</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="formrow-first-Input"
                                placeholder="First Name"
                                value={firstname}
                                onChange={(e)=> setFirstName(e.target.value) }
                            />
                            </div>
                            </Col>
                            <Col md={5}>
                            <div className="mb-3">
                            <Label htmlFor="formrow-last-Input">Last Name</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="formrow-last-Input"
                                placeholder="Last Name"
                                value={lastname}
                                onChange={(e)=> setLastName(e.target.value) }
                            />
                            </div>
                            </Col>
                            <Col md={5}>
                            <div className="mb-3">
                            <Label htmlFor="formrow-dep-Input">Department</Label>
                            <Select
                             onChange={handleDepChange}
                            name="Depertment"
                            options={
                              [
                                {
                                  label:'IT',
                                  value:'IT'
                                },
                                {
                                  label:'Sales',
                                  value:'Sales'
                                },
                                {
                                  label:'Tech',
                                  value:'Tech'
                                },
                                {
                                  label:'Office Worker',
                                  value:'Office Worker'
                                },
                                {
                                  label:'Admin',
                                  value:'Admin'
                                }
                              ]
                            }
                            />
                            </div>
                            </Col>
                            <Col md={7}>
                            <div className="mb-3">
                            <Label htmlFor="formrow-dep-Input">Area</Label>
                            <Select
                            onChange={handleAreaChange}
                            name="Area"
                            options={
                              fetchLocation
                            }
                            />
                            </div>
                            </Col>
                            <Col md={5}>
                            <div className="mb-3">
                            <Label htmlFor="formrow-id-Input">Hiring Date</Label>
                            <Input
                                type="date"
                                className="form-control"
                                id="formrow-id-Input"
                                placeholder="Employee Id"
                                value={employeeID}
                                onChange={(e)=> setemployeeID(e.target.value) }
                            />
                            </div>
                            </Col>
                            <Col md={12}>
                            <div className="form-check form-switch mb-3">
                              <label
                                className="form-check-label"
                                htmlFor="customSwitchsizesm"
                              >
                               Create Login Details
                              </label>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customSwitchsizesm"
                                // onClick={() => {
                                //   this.setState({
                                //     toggleSwitchSize: !this.state
                                //       .toggleSwitchSize,
                                //   })
                                // }}
                                onClick={() => {
                                  if(toggleSwitchSize == true){
                                    settoggleSwitchSize(false)
                                    setUsername('');
                                    setPassword('');
                                    setConfirmPassword('');
                                    setRole('');
                                    setStatus('')
                                  }else{
                                    settoggleSwitchSize(true)
                                  }
                                  
                                }}
                              />
                            </div>
                            </Col>
                            
                           {
                            toggleSwitchSize ==true ? 
                            
                            <Col md={12}>
                                <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                            <Row>
                                <Col md={7}>
                                <div className="mb-3">
                                <Label htmlFor="formrow-email-Input">Username</Label>
                                <Input
                                    type="text"
                                    value={username}
                                    className="form-control"
                                    id="formrow-email-Input"
                                    placeholder="Username"
                                    onChange={(e)=> setUsername(e.target.value) }
                                />
                                </div>
                                </Col>
                               
                                <Col md={6}>
                                <div className="mb-3">
                                <Label htmlFor="formrow-password-Input">Password</Label>
                                <Input
                                    type="password"
                                    className="form-control"
                                    value={password}
                                    id="formrow-password-Input"
                                    placeholder="Password"
                                    onChange={(e)=> setPassword(e.target.value) }
                                />
                                <small>For a strong password, please use a hard to guess combination of text with upper and lower case characters, symbols and numbers</small>
                                </div>
                                </Col>
                                <Col md={5}>
                                <div className="mb-3">
                                <Label htmlFor="formrow-confirm-Input">Confirm Password</Label>
                                <Input
                                    type="password"
                                    className="form-control"
                                    value={confirmPassword}
                                    id="formrow-confirm-Input"
                                    placeholder="Confirm Password"
                                    onChange={(e)=> setConfirmPassword(e.target.value) }
                                />
                                </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-4">
                                    <h5 className="font-size-14 mb-4">Role</h5>
                                    <select className="form-control"
                                    value={role}
                                    onChange={(e)=> {
                                      setRole(e.target.value)
                                      if(e.target.value === 'supervisor' || e.target.value === 'admin'){
                                        document.getElementById('checkbox_Section_visible').style.display = 'block'
                                      }else{
                                        setMarkAttendance('false')
                                        document.getElementById('checkbox_Section_visible').style.display = 'none'
                                      }
                                    } }
                                    >
                                      <option>--Select--</option>
                                      <option value="admin">Admin</option>
                                      <option value="supervisor">Supervisor</option>
                                      <option value="user">User</option>
                                    </select>
                                    </div>
                                </Col>
                                <Col md={3}>
                                  <h5 className="font-size-14 mb-4">Shift Time</h5>
                                  <input className="form-control" type="time" value={shiftStart} onChange={(e)=>setShiftStart(e.target.value)}/>  
                                  </Col>
                                <Col md={3}>
                                  <h5 className="font-size-14 mb-4">End Time</h5>
                                  <input className="form-control" value={shiftEnd} type="time" onChange={(e)=>setShiftEnd(e.target.value)}/>  
                                  </Col>
                                  <Col md={3}>
                                    <h5 className="font-size-14 mb-4">Extra Hours</h5>
                                    <div className="mb-4" style={{ display:'flex' }}>
                                    
                                    <div className="form-check mb-3">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        name="nameExtraHourss"
                                        id="exampslRadios1"
                                        value="true"
                                        checked={extraHoursStatus === 'true'}
                                        onChange={handleOptionExtraStatus}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="exampslRadios1"
                                        >
                                        Yes
                                        </label>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className="form-check">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        name="nameExtraHourss"
                                        id="exampleRad"
                                        value="false"
                                        checked={extraHoursStatus === 'false'}
                                        onChange={handleOptionExtraStatus}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="exampleRad"
                                        >
                                         No
                                        </label>
                                    </div>
                                    </div>
                                </Col>
                                {
                                  extraHoursStatus === "true"?
                                  <Col md={4}>
                                  <label>No of Hours</label >
                                  <input type="number" className="form-control" min={1} value={extraHours} onChange={(e)=>setExtraHours(e.target.value)}/>
                                  </Col>:null
                                }
                                <Col id="checkbox_Section_visible" style={{display:'none'}} md={12}>
                                 <Row>
                                  
                                  <Col md={4}>
                                  <h5 className="font-size-14 mb-4">Add Employee?</h5>
                                    <div className="mb-4" style={{ display:'flex' }}>
                                    
                                    <div className="form-check mb-3">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        name="exampleRadioEmployee"
                                        id="exampleRadio"
                                        value="true"
                                        checked={addEmployee === 'true'}
                                        onChange={handleOptionChanges}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="exampleRadio"
                                        >
                                        Yes
                                        </label>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className="form-check">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        
                                        name="exampleRadioEmployee"
                                        id="exampleRadios2"
                                        value="false"
                                        checked={addEmployee === 'false'}
                                        onChange={handleOptionChanges}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios2"
                                        >
                                        No
                                        </label>
                                    </div>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                  <h5 className="font-size-14 mb-4">Review Leaves</h5>
                                    <div className="mb-4" style={{ display:'flex' }}>
                                    
                                    <div className="form-check mb-3">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        name="exampleRadioLeave"
                                        id="exampleRadios1"
                                        value="true"
                                        checked={leaveRight === 'true'}
                                        onChange={handleOptionLeave}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios1"
                                        >
                                        Yes
                                        </label>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className="form-check">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        
                                        name="exampleRadioLeave"
                                        id="exampleRadios2"
                                        value="false"
                                        checked={leaveRight === 'false'}
                                        onChange={handleOptionLeave}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios2"
                                        >
                                        No
                                        </label>
                                    </div>
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                  <h5 className="font-size-14 mb-4">Mark Attendance ?</h5>
                                    <div className="mb-4" style={{ display:'flex' }}>
                                    
                                    <div className="form-check mb-3">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        name="exampleRadioMark"
                                        id="exampleRadi"
                                        value="true"
                                        checked={markAttendance === 'true'}
                                        onChange={handleOptionMark}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="exampleRadi"
                                        >
                                        Yes
                                        </label>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className="form-check">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        
                                        name="exampleRadioMark"
                                        id="exampleRadios2"
                                        value="false"
                                        checked={markAttendance === 'false'}
                                        onChange={handleOptionMark}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios2"
                                        >
                                        No
                                        </label>
                                    </div>
                                    </div>
                                  </Col>
                                 </Row>
                                </Col>
                               
                                {
                                  markAttendance === "true"  ?
                                  <Row>
                                  <Col md={4}>
                                    <h5 className="font-size-14 mb-4">By Manual</h5>
                                    <div className="mb-4" style={{ display:'flex' }}>
                                    
                                    <div className="form-check mb-3">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        name="exampleRadiosManual"
                                        id="exampleRadios1"
                                        value="true"
                                        checked={byManual === 'false'}
                                        onChange={handleOptionManual}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios1"
                                        >
                                        Yes
                                        </label>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className="form-check">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        
                                        name="exampleRadiosManual"
                                        id="exampleRadios2"
                                        value="Disabled"
                                        checked={byManual === 'false'}
                                        onChange={handleOptionManual}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="exampleRadios2"
                                        >
                                        No
                                        </label>
                                    </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                <h5 className="font-size-14 mb-4">By Face Recongization</h5>
                                <div className="mb-4" style={{ display:'flex' }}>
                                
                                <div className="form-check mb-3">
                                    <input
                                    className="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="true"
                                    checked={byFace === 'true'}
                                    onChange={handleOptionFace}
                                    />
                                    <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios1"
                                    >
                                    Yes
                                    </label>
                                </div>
                                &nbsp;&nbsp;&nbsp;
                                <div className="form-check">
                                    <input
                                    className="form-check-input"
                                    type="radio"
                                    
                                    name="exampleRadios"
                                    id="exampleRadios2"
                                    value="Disabled"
                                    checked={byFace === 'false'}
                                    onChange={handleOptionFace}
                                    />
                                    <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios2"
                                    >
                                    No
                                    </label>
                                </div>
                                </div>
                            </Col>
                                  </Row>
                                :null
                                }
                                 <Col md={5}>
                                    <h5 className="font-size-14 mb-4">Status</h5>
                                    <div className="mb-4" style={{ display:'flex' }}>
                                    
                                    <div className="form-check mb-3">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        name="exampleRadios"
                                        id="examples1"
                                        value="Enabled"
                                        checked={status === 'Enabled'}
                                        onChange={handleOptionChange}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="examples1"
                                        >
                                        Enabled
                                        </label>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className="form-check">
                                        <input
                                        className="form-check-input"
                                        type="radio"
                                        
                                        name="exampleRadios"
                                        id="example2"
                                        value="Disabled"
                                        checked={status === 'Disabled'}
                                        onChange={handleOptionChange}
                                        />
                                        <label
                                        className="form-check-label"
                                        htmlFor="example2"
                                        >
                                        Disabled
                                        </label>
                                    </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>:null
                           }
                        </Row>
                      </Col>
                      
                    </Row>
                    <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                    <div style={{ textAlign:'end' }}>
                      <Link to={'../employee'}>
                      <button type="submit" className="btn btn-info w-md">
                        Cancel
                      </button>
                      </Link>
                      &nbsp;
                      &nbsp;
                      <button onClick={SubmitButton} type="submit"  className="btn btn-success w-md">
                        Save 
                      </button>
                    </div>
                  
                </CardBody>
              </Card>
            </Col>
          
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  );
};


export default NewEmployeeLayout;
