// src/components/filter.
import React, { useMemo } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { Link } from "react-router-dom"
function DatatableTables() {
    const [departments, setDepartments] = React.useState([]);
    const [placename, setplaceName] = React.useState('');
    const [data,setData] = React.useState([]);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "first_name",
      },
      {
        Header: "Job Category",
        accessor: "job_category",
      },
      {
        Header: "Hire Date",
        accessor: "joing_date",
      },
      {
        Header: 'Actions',
        accessor: 'emp_id',
        disableFilters: true,
        Cell: (cellProps) => {
            return (
                <ul className="list-unstyled hstack gap-1 mb-0">
                    <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                        <Link to={`/view/payroll/${cellProps.row.original.emp_id}`} className="btn btn-sm btn-primary">
                            <i className="mdi mdi-eye-outline" id="viewtooltip">View</i></Link>
                    </li>
                    

                    <li>
                        <Link
                            to={`/edit-employee/${cellProps.row.original.emp_id}`}
                            className="btn btn-sm btn-info"
                          
                        >
                            <i className="mdi mdi-pencil-outline" id="edittooltip" >Info</i>
                            
                        </Link>
                    </li>

                    <li>
                        <Link
                            to="#"
                            className="btn btn-sm btn-danger"
                            onClick={() => {
                                const jobData = cellProps.row.original;
                                onClickDelete(jobData);
                            }}
                        >
                            <i className="mdi mdi-delete-outline" id="deletetooltip" >Delete</i>
                            
                        </Link>
                    </li>
                </ul>
            );
        }
        
    },
    ],
    []
  )



  React.useEffect(()=>{
    fetchTopDepartments()
  },[])
  document.title = "Pay Roll - HR Dashboard"
  const fetchTopDepartments = ()=>{
    var formdata = new FormData();
    setplaceName('departments')
formdata.append("method", "filter-all-jobs-cat");
formdata.append("method-inside", "dept");

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch("https://api.frontforcecrm.com/login.php", requestOptions)
  .then(response => response.json())
  .then(result => setDepartments(result))
  .catch(error => console.log('error', error));
  }
  const callSubElements =(val)=>{
    setplaceName(val)
    var formdata = new FormData();
    formdata.append("method", "filter-all-jobs-cat");
    formdata.append("method-inside", val);
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => setDepartments(result))
      .catch(error => console.log('error', error));
  }
  const sendMe = (val)=>{
 var checkboxes = document.querySelectorAll(`input[name="${val}"]:checked`);
  var checkedValues = [];
  
  checkboxes.forEach(function(checkbox) {
    checkedValues.push(checkbox.value);
  });
  
  var formdata = new FormData();
    formdata.append("method", "filter-job-by-selected-checkbox");
    formdata.append("method-inside", val);
    formdata.append("filter_value", checkedValues.toString());
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("https://api.frontforcecrm.com/login.php", requestOptions)
      .then(response => response.json())
      .then(result => setData(result))
      .catch(error => console.log('error', error));
  
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem="Pay Roll" />

        <Container>
          <Card>
            <CardBody>
              <Row>
                <Col md={3}>
                    <Row>
                       <Col md={12}>
                       <select
                        className="form-control"
                        onChange={(e)=>{callSubElements(e.target.value)}}
                        >
                         <option value="departments">Departments</option>
                         <option value={"area"}>Location</option>

                        </select>
                       </Col>
                       <Col md={12} style={{paddingTop:20}}>
                        
                         {departments.map((e,i)=>
                         <div key={i}>
                             <input name={`${placename}`}  onClick={(e)=>{sendMe(e.target.name)}} value={`${e}`} type="checkbox" />&nbsp;{e}
                         </div>
                           
                         )}
                       </Col>
                    </Row>
                </Col>
                <Col md={9}>
                  <TableContainer
                    columns={columns}
                    data={data}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </div>
  )
}
DatatableTables.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DatatableTables
