import React, { useEffect, useState,useCallback } from "react";

import { Link,useNavigate} from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
  Modal,
  ModalBody
} from "reactstrap";
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import TableContainer from "components/Common/TableContainer";
import toastr from "toastr";
import 'toastr/build/toastr.min.css';
const LoanEmployee = props => {
    const [selectedGroupOther,setselectedGroupOther] = useState([]);
    const [optionGroup,setoptionGroup] = useState([])
    const [count,setCount] = useState(1)
    const [data,setData] = useState([])
    const [modal_xlarge,setmodal_xlarge] = useState(false);
  const [leaveStatus,showUpdateLeaveStatus] = useState(false);
  const [singleData,setSingleData] = useState([]);
    useEffect(()=>{
            fetchUser();
            fetchLeaves();
    },[count])
    const fetchUser = ()=>{
        let form = new FormData();
        form.append('method','fetch-user-for-select');
        var requestOptions = {
            method: "POST",
            body: form,
            redirect: "follow",
          }
          fetch("https://api.frontforcecrm.com/login.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                setoptionGroup(result);
            })
            .catch(error => console.log("error", error))
    }
   
  //meta title
  document.title = "Assign Loan | HR Dashboard";
  const navigate = useNavigate();
  function handleSelectGroup2(selectedGroupOther) {
    setselectedGroupOther(selectedGroupOther.value)
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const columns = React.useMemo(
    () => [
        {
            Header: 'Name',
            accessor: 'fullname',
        },
        {
            Header: 'Loan Date',
            accessor: 'loan_date'
        },
        {
            Header: 'Amount',
            accessor: 'loan_amount'
        },
        {
            Header: 'Status',
            accessor: 'loan_status'
        },
        {
            Header: 'Actions',
            accessor: 'id',
            disableFilters: true,
            Cell: (cellProps) => {
                return (
                    <ul className="list-unstyled hstack gap-1 mb-0">
                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                            <span onClick={()=>fetchSingleLeave(cellProps.row.original.id)}  className="btn btn-sm btn-success">
                                <i className="mdi mdi-eye" id="viewtooltip">View</i></span>
                        </li>
                        
  
                        <li>
                            <Link
                                to={`/edit-employee/${cellProps.row.original.id}`}
                                className="btn btn-sm btn-danger"
                              
                            >
                                <i className="mdi mdi-delete" id="edittooltip" >Delete</i>
                              
                            </Link>
                        </li>
  
                        
                    </ul>
                );
            }
            
        },
    ],
    []
);
const fetchSingleLeave =(passid)=>{
  var formdata = new FormData();
  formdata.append("method", "fetch-single-loan");
  formdata.append("loan_id", passid);
  
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  
  fetch("https://api.frontforcecrm.com/loan.php", requestOptions)
    .then(response => response.json())
    .then(result => {
      setSingleData(result)
      setmodal_xlarge(true)
    })
    .catch(error => console.log('error', error));
}
const fetchLeaves = useCallback(()=>{
    var formdata = new FormData();
    formdata.append("method", "fetch-loan");
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch("https://api.frontforcecrm.com/loan.php", requestOptions)
      .then(response => response.json())
      .then(result => setData(result))
      .catch(error => console.log('error', error));
  });
  const refundCycleChange = (val)=>{
        let amount = document.getElementById('amount').value;
        if(amount == ""){
            toastr.error('Error','Please add the amount');
            return false;
        }else{
            let res =amount / val;
            
            document.getElementById('refund_per_cycle').value=res;
        }
  }
  function division (a,b){
    return a/b;
  }
  const updateStatusNow =(id)=>{
  
    let valfor =document.getElementById('status_for_leave').value;
    if(valfor == 'null'){
      toastr.error('Error','Please select the type first');
      return false;
    }else{
      var formdata = new FormData();
      formdata.append("method", "update_loan_status");
      formdata.append("loan_id", id);
      formdata.append("loan_status", valfor);
      
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };
      
      fetch("https://api.frontforcecrm.com/loan.php", requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result == 'success'){
            toastr.success('Success','Successfully update the loan status');
            setmodal_xlarge(false)
            fetchLeaves()
          }else{
            toastr.error('Error','Please try again');
          }
        })
        .catch(error => console.log('error', error));
    }
    
    setmodal_xlarge(false)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="" breadcrumbItem="Assign Loan" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Assign Loan
                  <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                  </CardTitle>
                  <Form
                  onSubmit={(e)=>{
                    e.preventDefault();
                    let select_employee = [];
                    for(var i = 0; i < e.target.elements.select_value.length; i++){
                       select_employee.push(e.target.elements.select_value[i].value)
                    }
                    if(select_employee.length === 0){
                      toastr.error('Error','Please employees ID');
                      return false;
                    }
                    else if(e.target.elements.amount.value === ''){
                      toastr.error('Error','Add loan amount');
                      return false;
                    }
                    else if(e.target.elements.issue_date.value === ''){
                      toastr.error('Error','Issue date is required');
                      return false;
                    }else if(e.target.elements.refund_per_cycle.value === ''){
                      toastr.error('Error','Refund amount is not empty');
                      return false;
                    }else if(e.target.elements.remarks.value === ''){
                        toastr.error('Error','Comments are required');
                        return false;
                      }else{
                        let form = new FormData();
                        form.append('emp_ids',select_employee);
                        form.append('loan_date',e.target.elements.issue_date.value);
                        form.append('loan_amount',e.target.elements.amount.value);
                        form.append('amount_per_cycle',e.target.elements.refund_per_cycle.value)
                        form.append('refund_cycle',e.target.elements.refund_cycle.value);
                        form.append('comments',e.target.elements.remarks.value);
                        form.append('method','add-loan');
                        var requestOptions = {
                            method: "POST", 
                            body: form,
                            redirect: "follow",
                          }
                          fetch("https://api.frontforcecrm.com/loan.php", requestOptions)
                            .then(response => response.text())
                            .then(result => {
                              if(result == 'success'){
                                toastr.success('Success','Loan is successfully assigned to the selected employee');
                                e.target.reset();
                                setselectedGroupOther([]);
                                fetchLeaves();
                              }else{
                                toastr.error('Error','Not assigned yet, Please try again');
                              }
                            })
                            .catch(error => console.log("error", error))

                      }
                    
                  }}
                  >
                    <Row>
                      <Col md={7}>
                        <Row>
                            <Col md={10}>
                            <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">Employee Name</Label>
                            <Select
                             onChange={handleSelectGroup2}
                             options={optionGroup}
                             value={optionGroup.find(
                               option =>
                                 option.value === selectedGroupOther
                             )}
                             isMulti
                             name="select_value"
                             className="select2-selection"
                            
                            />
                           
                            </div>
                            </Col>
                            <Col md={5}>
                            <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">Enter Loan Amount</Label>
                            <Input
                            className="form-control"
                            type="text"
                            name="amount"
                            id="amount"
                            placeholder="0.00"

                            />
                            </div>
                            </Col>
                  
                            <Col md={5}>
                            <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">Issue Date</Label>
                            <Input
                                type="date"
                                className="form-control"
                                id="formrow-email-Input"
                                name="issue_date"
                                
                            />
                            </div>
                            </Col>
                            <Col md={5}>
                            <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">Refund Cycle</Label>
                            <select name="refund_cycle" onChange={(e)=>refundCycleChange(e.target.value)} className="form-control">
                                <option value="1">1 Period</option>
                                <option value="2">2 Periods</option>
                                <option value="3">3 Periods</option>
                                <option value="4">4 Periods</option>
                                <option value="5">5 Periods</option>
                                <option value="6">6 Periods</option>
                                <option value="7">7 Periods</option>
                                <option value="8">8 Periods</option>
                                <option value="9">9 Periods</option>
                                <option value="10">10 Periods</option>
                                <option value="11">11 Periods</option>
                                <option value="12">12 Periods</option>
                            </select>
                            </div>
                            </Col>
                            <Col md={5}>
                            <div className="mb-3">
                            <Label htmlFor="refund_per_cycle">Refund Amount per Cycle</Label>
                            <Input
                            id="refund_per_cycle"
                            name="refund_per_cycle"
                            type='text'
                            className="form-control"
                            
                            />
                            </div>
                            </Col>
                            <Col md={10}>
                            <div className="mb-3">
                            <Label htmlFor="formrow-email-Input">Comments</Label>
                            <textarea name="remarks" className="form-control"></textarea>
                            </div>
                            </Col>
                        </Row>
                      </Col>
                      
                    </Row>
                    <hr style={{ borderTop: "1px solid #3c3c3c24" }}></hr>
                    <div style={{ textAlign:'end' }}>
                      
                      <button onClick={() => navigate(-1)} type="submit" className="btn btn-info w-md">
                        Cancel
                      </button>
                      
                      &nbsp;
                      &nbsp;
                      <button type="submit" className="btn btn-success w-md">
                        Assign Loan
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          
          </Row>
          <Row>
            <Card>
                <CardBody>
                <Col md={12}>
                <TableContainer
                                columns={columns}
                                data={data}
                                isGlobalFilter={true}
                                isAddOptions={false}
                                customPageSize={10}
                                className="custom-header-css"
                            />
            </Col>
            <Modal
                      keyboard={false}
                      backdrop="static"
                      size="md"
                      isOpen={modal_xlarge}
                      toggle={() => {
                        tog_xlarge();
                      }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="myExtraLargeModalLabel"
                        >
                          Leave Details
                        </h5>
                        <button
                          onClick={() => {
                            setmodal_xlarge(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                     
                        
                          {
                            singleData.map((e,i)=>{
                             return(
                              <Row key={i}>
                              <Col md={6}>
                      <h6>Employee Name</h6>
                  </Col>
                  <Col md={6}>
                      <h6>{e.fullname}</h6>
                  </Col>
                  <Col md={6}>
                      <h6>Loan Amount</h6>
                  </Col>
                  <Col md={6}>
                      <h6>{e.loan_amount}</h6>
                  </Col>
                  <Col md={6}>
                      <h6>Loan Date</h6>
                  </Col>
                  <Col md={6}>
                      <h6>{e.loan_date}</h6>
                  </Col>
                  <Col md={6}>
                      <h6>Recyle Period</h6>
                  </Col>
                  <Col md={6}>
                      <h6>{e.no_of_cycle}</h6>
                  </Col>
                  <Col md={6}>
                      <h6>Per Cycle Amount</h6>
                  </Col>
                  <Col md={6}>
                      <h6>{division(e.loan_amount,e.no_of_cycle)}</h6>
                  </Col>
                  <Col md={6}>
                      <h6>Leave Status</h6>
                  </Col>
                  <Col md={6}>
                      <h6>{e.loan_status} <i onClick={()=>showUpdateLeaveStatus(true)} className="bx bx-edit"></i></h6>
                      {
                        leaveStatus == true ? 
                        <div >
                          <select className="form-control" id="status_for_leave" name="status_for_leave">
                            <option value="null">--SELECT--</option>
                            <option selected={e.loan_status == 'assigned' ? true:false} value={"assigned"}>Assigned</option>
                            <option selected={e.loan_status == 'approved' ? true:false} value={"approved"}>Approved</option>
                            <option selected={e.loan_status == 'rejected' ? true:false} value={"rejected"}>Rejected</option>
                            
                            </select>
                             <br/>
                             
                        </div>:null
                      }
                  </Col>
                  <Col md={12}><h6>Comment</h6></Col>
                  <Col md={12}><p>{e.comments}</p></Col>
                  <Col md={12} style={{ textAlign:'end',marginTop:10 }}>
                    <button onClick={()=>setmodal_xlarge(false)} className="btn btn-danger">Cancel</button>&nbsp;&nbsp;
                    <button onClick={()=>updateStatusNow(e.id)} className="btn btn-primary">Save Changes</button>

                  </Col>
                  </Row>
                             );
                            })
                          }
                      
                      
                      </div>
                    </Modal>
                </CardBody>
            </Card>
          </Row>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  );
};


export default LoanEmployee;
